import * as React from "react";

function SvgAdultSmallMaskBg(props) {
  return (
    <svg
      viewBox="0 0 374 375"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M186.919 98.4878C186.785 98.5146 186.384 97.7923 186.277 97.4712C185.128 94.475 184.006 91.8533 183.498 88.9106C183.258 87.4927 183.819 86.2086 184.888 85.5666C186.09 84.764 187.801 84.6035 189.137 85.4328C190.179 86.0749 190.714 87.1182 190.633 88.3756C190.526 89.9539 190.072 91.452 189.511 92.8966C188.87 94.5018 188.148 96.0534 187.453 97.6318C187.346 97.8458 187.052 98.4343 186.919 98.4878Z"
        fill="#B494F2"
      />
      <path
        d="M179.436 99.6114C179.329 99.6381 179.009 99.1566 178.902 98.9693C177.886 96.9629 176.897 95.224 176.336 93.2176C176.069 92.2545 176.363 91.3182 177.058 90.7832C177.833 90.1411 179.009 89.9004 179.997 90.3819C180.772 90.7564 181.227 91.4252 181.253 92.3348C181.307 93.4316 181.093 94.5017 180.799 95.5718C180.478 96.7221 180.078 97.8725 179.73 99.0228C179.703 99.1566 179.543 99.5846 179.436 99.6114Z"
        fill="#8963C6"
      />
      <path
        d="M174.278 53.3838C174.198 53.4373 173.824 52.9825 173.717 52.7685C172.568 50.8423 171.446 49.1837 170.751 47.2041C170.404 46.241 170.644 45.3047 171.285 44.7161C172.007 44.0206 173.183 43.6995 174.198 44.1008C175 44.4218 175.481 45.0639 175.588 45.9467C175.722 47.0435 175.588 48.1404 175.374 49.2105C175.134 50.3875 174.84 51.5646 174.546 52.715C174.492 52.9022 174.359 53.3303 174.278 53.3838Z"
        fill="#91E7F2"
      />
      <path
        d="M182.59 9.48367C182.483 9.51043 182.162 9.02889 182.055 8.84162C181.04 6.83522 180.051 5.09634 179.49 3.08993C179.222 2.12686 179.516 1.19054 180.211 0.655498C180.986 0.0134485 182.162 -0.22732 183.151 0.254217C183.926 0.628746 184.38 1.29755 184.407 2.20712C184.46 3.30395 184.246 4.37403 183.952 5.44411C183.632 6.59445 183.231 7.74479 182.883 8.89513C182.857 9.00214 182.696 9.45692 182.59 9.48367Z"
        fill="#91E7F2"
      />
      <path
        d="M186.171 25.9361C186.064 25.9629 185.796 25.4546 185.716 25.2406C184.915 23.1539 184.113 21.308 183.765 19.2481C183.605 18.2583 183.979 17.3487 184.727 16.8939C185.556 16.3321 186.758 16.2251 187.694 16.7869C188.415 17.2417 188.789 17.964 188.736 18.8468C188.656 19.9437 188.362 20.987 187.961 22.0036C187.533 23.1272 187.026 24.224 186.545 25.3208C186.491 25.4813 186.277 25.9094 186.171 25.9361Z"
        fill="#B494F2"
      />
      <path
        d="M171.419 19.0074C171.312 19.0341 170.991 18.5526 170.885 18.3653C169.869 16.3589 168.88 14.62 168.319 12.6136C168.052 11.6505 168.346 10.7142 169.041 10.1792C169.816 9.53713 170.991 9.29636 171.98 9.7779C172.755 10.1524 173.209 10.8212 173.236 11.7308C173.29 12.8276 173.076 13.8977 172.782 14.9678C172.461 16.1181 172.06 17.2685 171.713 18.4188C171.686 18.5258 171.526 18.9806 171.419 19.0074Z"
        fill="#6A449D"
      />
      <path
        d="M179.436 68.8999C179.329 68.9267 179.009 68.4451 178.902 68.2579C177.886 66.2515 176.897 64.5126 176.336 62.5062C176.069 61.5431 176.363 60.6068 177.058 60.0718C177.833 59.4297 179.009 59.1889 179.997 59.6705C180.772 60.045 181.227 60.7138 181.253 61.6234C181.307 62.7202 181.093 63.817 180.799 64.8604C180.478 66.0107 180.078 67.161 179.73 68.3114C179.703 68.4184 179.543 68.8732 179.436 68.8999Z"
        fill="#B494F2"
      />
      <path
        d="M176.924 35.032C176.817 35.0588 176.497 34.5772 176.39 34.39C175.187 31.9823 174.305 30.4574 173.824 28.6383C173.557 27.6752 173.851 26.7389 174.546 26.2038C175.321 25.5618 176.497 25.321 177.485 25.8026C178.26 26.1771 178.715 26.8459 178.741 27.7555C178.795 28.8523 178.581 29.9491 178.287 30.9925C177.966 32.1428 177.565 33.2931 177.218 34.4435C177.191 34.5505 177.031 35.0053 176.924 35.032Z"
        fill="#91E7F2"
      />
      <path
        d="M171.473 99.8522C171.339 99.9057 170.831 99.2636 170.671 98.9694C169.041 96.2139 167.437 93.833 166.475 91.024C165.994 89.6596 166.315 88.2953 167.25 87.4927C168.292 86.4761 169.949 86.0214 171.419 86.6099C172.568 87.0647 173.263 88.001 173.397 89.2584C173.557 90.8367 173.397 92.3884 173.076 93.9132C172.729 95.5986 172.274 97.2572 171.873 98.9426C171.793 99.1566 171.606 99.7987 171.473 99.8522Z"
        fill="#91E7F2"
      />
      <path
        d="M166.956 68.9C166.823 68.9535 166.315 68.3115 166.128 68.0172C164.498 65.2617 162.894 62.8808 161.932 60.0718C161.451 58.7075 161.772 57.3431 162.707 56.5406C163.749 55.524 165.406 55.0692 166.876 55.6578C168.025 56.1125 168.72 57.0489 168.854 58.3062C169.014 59.8846 168.854 61.4362 168.533 62.9611C168.186 64.6464 167.731 66.3051 167.33 67.9904C167.277 68.2045 167.09 68.8465 166.956 68.9Z"
        fill="#6A449D"
      />
      <path
        d="M164.31 102.287C164.23 102.34 163.803 101.912 163.669 101.725C162.333 99.9324 161.05 98.3807 160.168 96.5081C159.741 95.5985 159.848 94.6355 160.436 93.9934C161.077 93.2176 162.199 92.7896 163.268 93.0838C164.097 93.3246 164.658 93.9132 164.845 94.7692C165.085 95.8393 165.059 96.9361 164.979 98.033C164.872 99.2368 164.685 100.414 164.524 101.618C164.471 101.778 164.391 102.233 164.31 102.287Z"
        fill="#6A449D"
      />
      <path
        d="M148.971 29.2267C148.891 29.2802 148.463 28.8522 148.33 28.6649C146.994 26.8725 145.711 25.3209 144.829 23.4483C144.401 22.5387 144.508 21.5756 145.096 20.9336C145.738 20.1578 146.86 19.7298 147.929 20.024C148.757 20.2648 149.319 20.8533 149.506 21.7094C149.746 22.7795 149.72 23.8763 149.639 24.9732C149.532 26.177 149.345 27.3541 149.185 28.5579C149.132 28.7184 149.051 29.1732 148.971 29.2267Z"
        fill="#91E7F2"
      />
      <path
        d="M156.507 103.891C156.374 103.972 155.759 103.41 155.545 103.169C153.461 100.735 151.483 98.6748 150.013 96.0799C149.318 94.8225 149.372 93.4314 150.174 92.4416C151.029 91.2645 152.579 90.5422 154.129 90.8632C155.331 91.104 156.187 91.9066 156.534 93.1104C156.988 94.6353 157.068 96.1869 157.015 97.7385C156.961 99.4506 156.828 101.163 156.694 102.902C156.694 103.142 156.614 103.811 156.507 103.891Z"
        fill="#8963C6"
      />
      <path
        d="M128.047 25.1067C127.94 25.187 127.432 24.7054 127.245 24.4914C125.481 22.4583 123.824 20.6926 122.595 18.5257C122.007 17.4824 122.06 16.3053 122.729 15.476C123.45 14.4862 124.76 13.8709 126.042 14.1384C127.058 14.3524 127.779 15.0212 128.073 16.0378C128.447 17.3219 128.528 18.6327 128.501 19.9436C128.474 21.3882 128.341 22.8328 128.234 24.2774C128.207 24.4914 128.127 25.0532 128.047 25.1067Z"
        fill="#6A449D"
      />
      <path
        d="M149.88 107.53C149.8 107.61 149.319 107.262 149.158 107.102C147.528 105.577 146.005 104.266 144.802 102.581C144.214 101.751 144.161 100.788 144.642 100.039C145.15 99.1564 146.192 98.5411 147.261 98.6481C148.116 98.7283 148.757 99.2099 149.105 100.039C149.532 101.056 149.693 102.153 149.8 103.223C149.906 104.427 149.906 105.63 149.96 106.834C149.933 106.995 149.933 107.449 149.88 107.53Z"
        fill="#B494F2"
      />
      <path
        d="M142.451 110.473C142.344 110.58 141.649 110.125 141.382 109.938C138.896 107.905 136.598 106.219 134.701 103.918C133.792 102.795 133.632 101.404 134.22 100.334C134.861 99.0229 136.277 98.033 137.827 98.0865C139.057 98.1133 140.019 98.7553 140.607 99.9057C141.302 101.324 141.676 102.848 141.889 104.4C142.13 106.112 142.29 107.824 142.477 109.536C142.504 109.697 142.557 110.366 142.451 110.473Z"
        fill="#91E7F2"
      />
      <path
        d="M110.863 49.2909C110.756 49.3979 110.062 48.9431 109.794 48.7558C107.309 46.7227 105.011 45.0373 103.113 42.7366C102.205 41.613 102.045 40.2219 102.632 39.1518C103.274 37.841 104.69 36.8512 106.24 36.9047C107.469 36.9314 108.431 37.5735 109.019 38.7238C109.714 40.1417 110.088 41.6665 110.302 43.2181C110.543 44.9303 110.703 46.6424 110.89 48.3545C110.917 48.5418 110.97 49.1838 110.863 49.2909Z"
        fill="#8963C6"
      />
      <path
        d="M136.545 115.208C136.491 115.288 135.957 115.047 135.77 114.914C133.899 113.683 132.162 112.666 130.692 111.222C129.971 110.526 129.757 109.563 130.104 108.761C130.452 107.797 131.36 107.022 132.456 106.941C133.311 106.888 134.033 107.236 134.514 107.985C135.102 108.921 135.476 109.938 135.743 111.008C136.037 112.185 136.277 113.362 136.518 114.539C136.545 114.646 136.625 115.128 136.545 115.208Z"
        fill="#8963C6"
      />
      <path
        d="M92.4773 52.822C92.4239 52.9023 91.8894 52.6615 91.7024 52.5278C89.8317 51.2972 88.0947 50.2806 86.6249 48.836C85.9033 48.1404 85.6895 47.1773 86.0369 46.3748C86.3843 45.4117 87.293 44.6359 88.3886 44.5556C89.2438 44.5021 89.9653 44.8499 90.4463 45.599C91.0343 46.5353 91.4084 47.5519 91.6756 48.622C91.9696 49.7991 92.2101 50.9761 92.4506 52.1532C92.4506 52.287 92.5308 52.7418 92.4773 52.822Z"
        fill="#B494F2"
      />
      <path
        d="M129.757 119.381C129.677 119.515 128.902 119.194 128.608 119.033C125.802 117.455 123.236 116.198 120.991 114.245C119.896 113.308 119.495 111.971 119.896 110.794C120.297 109.402 121.526 108.172 123.049 107.958C124.278 107.771 125.347 108.225 126.096 109.269C127.031 110.553 127.646 111.971 128.153 113.469C128.688 115.101 129.142 116.759 129.623 118.418C129.703 118.605 129.864 119.274 129.757 119.381Z"
        fill="#6A449D"
      />
      <path
        d="M124.786 125.079C124.733 125.16 124.172 124.999 123.958 124.919C121.9 124.036 120.029 123.341 118.319 122.163C117.491 121.602 117.117 120.692 117.304 119.836C117.491 118.846 118.239 117.883 119.281 117.616C120.11 117.402 120.885 117.642 121.499 118.284C122.247 119.087 122.782 120.05 123.236 121.04C123.744 122.137 124.172 123.26 124.626 124.384C124.679 124.544 124.84 124.972 124.786 125.079Z"
        fill="#91E7F2"
      />
      <path
        d="M104.904 105.176C104.85 105.256 104.289 105.096 104.075 105.015C102.018 104.132 100.147 103.437 98.4367 102.26C97.6082 101.698 97.2341 100.788 97.4212 99.9324C97.6082 98.9426 98.3565 97.9795 99.3987 97.712C100.227 97.498 101.002 97.7387 101.617 98.3808C102.365 99.1833 102.9 100.146 103.354 101.136C103.862 102.233 104.289 103.357 104.743 104.48C104.797 104.641 104.957 105.096 104.904 105.176Z"
        fill="#B494F2"
      />
      <path
        d="M118.827 130.376C118.773 130.51 117.945 130.349 117.651 130.216C114.631 129.172 111.879 128.343 109.313 126.845C108.084 126.123 107.443 124.865 107.63 123.635C107.79 122.19 108.779 120.772 110.249 120.291C111.424 119.916 112.547 120.157 113.455 121.04C114.605 122.136 115.46 123.447 116.208 124.812C117.036 126.337 117.758 127.888 118.506 129.44C118.613 129.627 118.88 130.242 118.827 130.376Z"
        fill="#B494F2"
      />
      <path
        d="M114.899 136.85C114.872 136.957 114.284 136.877 114.07 136.824C111.879 136.315 109.928 135.941 108.031 135.085C107.122 134.657 106.588 133.854 106.614 132.971C106.614 131.955 107.202 130.885 108.191 130.457C108.966 130.109 109.794 130.189 110.489 130.751C111.371 131.42 112.066 132.276 112.681 133.158C113.349 134.148 113.99 135.192 114.631 136.208C114.712 136.342 114.925 136.743 114.899 136.85Z"
        fill="#91E7F2"
      />
      <path
        d="M90.9273 119.515C90.9006 119.622 90.3126 119.542 90.0988 119.488C87.9075 118.98 85.9567 118.605 84.0593 117.749C83.1507 117.321 82.6162 116.519 82.6429 115.636C82.6429 114.619 83.2309 113.549 84.2196 113.121C84.9946 112.773 85.823 112.854 86.5179 113.415C87.3997 114.084 88.0946 114.94 88.7092 115.823C89.3773 116.813 90.0187 117.856 90.66 118.873C90.7135 119.007 90.954 119.408 90.9273 119.515Z"
        fill="#6A449D"
      />
      <path
        d="M77.9932 114.058C77.9665 114.165 77.3785 114.084 77.1647 114.031C74.9734 113.522 73.0226 113.148 71.1252 112.292C70.2166 111.864 69.6821 111.061 69.7088 110.178C69.7088 109.162 70.2968 108.092 71.2855 107.664C72.0605 107.316 72.889 107.396 73.5838 107.958C74.4657 108.627 75.1605 109.483 75.7751 110.366C76.4432 111.356 77.0846 112.399 77.7259 113.415C77.8061 113.576 78.0199 113.977 77.9932 114.058Z"
        fill="#8963C6"
      />
      <path
        d="M109.955 143.083C109.928 143.217 109.073 143.19 108.752 143.137C105.599 142.629 102.739 142.308 99.96 141.264C98.6238 140.756 97.7687 139.632 97.742 138.402C97.6351 136.957 98.3566 135.379 99.7462 134.656C100.842 134.068 101.991 134.121 103.033 134.844C104.343 135.727 105.412 136.85 106.4 138.081C107.469 139.418 108.458 140.836 109.474 142.227C109.607 142.388 109.981 142.95 109.955 143.083Z"
        fill="#B494F2"
      />
      <path
        d="M82.643 128.905C82.6163 129.039 81.7612 129.012 81.4405 128.958C78.2871 128.45 75.4276 128.129 72.6484 127.086C71.3122 126.577 70.457 125.454 70.4303 124.223C70.3234 122.779 71.045 121.2 72.4346 120.478C73.5303 119.889 74.6794 119.943 75.7216 120.665C77.0311 121.548 78.1 122.672 79.0888 123.902C80.1577 125.24 81.1465 126.658 82.162 128.049C82.2956 128.209 82.6698 128.771 82.643 128.905Z"
        fill="#6A449D"
      />
      <path
        d="M107.202 150.146C107.175 150.253 106.614 150.28 106.374 150.28C104.129 150.146 102.125 150.146 100.12 149.611C99.1583 149.37 98.4902 148.648 98.3566 147.765C98.1695 146.775 98.5704 145.625 99.479 145.01C100.201 144.528 101.002 144.475 101.777 144.903C102.766 145.411 103.594 146.133 104.343 146.909C105.198 147.765 105.973 148.675 106.775 149.557C106.908 149.691 107.229 150.039 107.202 150.146Z"
        fill="#6A449D"
      />
      <path
        d="M75.2673 137.038C75.2406 137.145 74.6794 137.171 74.4389 137.171C72.1941 137.038 70.1898 137.038 68.1856 136.502C67.2235 136.262 66.5554 135.539 66.4218 134.657C66.2347 133.667 66.6356 132.516 67.5442 131.901C68.2657 131.42 69.0674 131.366 69.8424 131.794C70.8312 132.302 71.6596 133.025 72.4079 133.801C73.2631 134.657 74.038 135.566 74.8398 136.449C74.9734 136.556 75.2673 136.931 75.2673 137.038Z"
        fill="#91E7F2"
      />
      <path
        d="M59.8744 140.408C59.8477 140.515 59.2865 140.542 59.0459 140.542C56.8012 140.408 54.7969 140.408 52.7926 139.873C51.8306 139.632 51.1625 138.91 51.0289 138.027C50.8418 137.037 51.2426 135.887 52.1512 135.272C52.8728 134.79 53.6745 134.737 54.4495 135.165C55.4383 135.673 56.2667 136.395 57.0149 137.171C57.8701 138.027 58.6451 138.937 59.4468 139.82C59.5804 139.953 59.8744 140.301 59.8744 140.408Z"
        fill="#8963C6"
      />
      <path
        d="M28.4474 124.009C28.4207 124.116 27.8595 124.143 27.619 124.143C25.3742 124.009 23.3699 124.009 21.3656 123.474C20.4036 123.233 19.7355 122.511 19.6019 121.628C19.4148 120.638 19.8156 119.488 20.7242 118.873C21.4458 118.391 22.2475 118.338 23.0225 118.766C24.0113 119.274 24.8397 119.996 25.588 120.772C26.4431 121.628 27.2181 122.538 28.0198 123.42C28.1534 123.554 28.4741 123.902 28.4474 124.009Z"
        fill="#8963C6"
      />
      <path
        d="M103.407 157.182C103.407 157.315 102.579 157.449 102.231 157.449C99.0246 157.503 96.1651 157.663 93.2522 157.128C91.8359 156.861 90.8204 155.898 90.5799 154.694C90.2325 153.276 90.6601 151.617 91.8893 150.654C92.8514 149.878 94.0005 149.745 95.1764 150.253C96.6194 150.895 97.8754 151.831 99.0513 152.848C100.334 153.998 101.563 155.202 102.793 156.406C102.98 156.54 103.434 157.021 103.407 157.182Z"
        fill="#91E7F2"
      />
      <path
        d="M101.938 164.592C101.938 164.699 101.376 164.833 101.136 164.86C98.9178 165.127 96.9403 165.448 94.8558 165.288C93.8403 165.207 93.0653 164.619 92.8248 163.79C92.4774 162.826 92.6645 161.649 93.4394 160.874C94.0541 160.258 94.8558 160.098 95.6842 160.365C96.7265 160.713 97.6885 161.248 98.5704 161.89C99.5592 162.586 100.495 163.335 101.43 164.084C101.59 164.191 101.938 164.485 101.938 164.592Z"
        fill="#6A449D"
      />
      <path
        d="M36.3576 142.843C36.3576 142.95 35.7964 143.084 35.5559 143.11C33.3378 143.378 31.3603 143.699 29.2758 143.538C28.2603 143.458 27.4854 142.87 27.2448 142.04C26.8974 141.077 27.0845 139.9 27.8595 139.124C28.4741 138.509 29.2758 138.349 30.1043 138.616C31.1465 138.964 32.1085 139.499 32.9904 140.141C33.9792 140.836 34.9145 141.586 35.8499 142.335C35.9835 142.442 36.3576 142.736 36.3576 142.843Z"
        fill="#B494F2"
      />
      <path
        d="M70.0026 163.495C70.0026 163.602 69.4414 163.736 69.2009 163.763C66.9829 164.03 65.0053 164.351 62.9209 164.191C61.9054 164.111 61.1304 163.522 60.8899 162.693C60.5425 161.73 60.7295 160.552 61.5045 159.777C62.1191 159.161 62.9209 159.001 63.7493 159.268C64.7915 159.616 65.7536 160.151 66.6354 160.793C67.6242 161.489 68.5595 162.238 69.4949 162.987C69.6552 163.094 70.0026 163.388 70.0026 163.495Z"
        fill="#8963C6"
      />
      <path
        d="M72.4346 150.975C72.4346 151.082 71.8734 151.216 71.6329 151.243C69.4149 151.51 67.4373 151.831 65.3529 151.671C64.3374 151.591 63.5624 151.002 63.3219 150.173C62.9745 149.21 63.1615 148.032 63.9365 147.257C64.5512 146.641 65.3529 146.481 66.1813 146.748C67.2235 147.096 68.1856 147.631 69.0675 148.273C70.0562 148.969 70.9916 149.718 71.9269 150.467C72.0605 150.574 72.4346 150.895 72.4346 150.975Z"
        fill="#6A449D"
      />
      <path
        d="M99.4254 172.163C99.4254 172.297 98.6504 172.564 98.3298 172.618C95.1764 173.206 92.3971 173.875 89.4308 173.848C87.9877 173.822 86.8119 173.073 86.3576 171.895C85.7696 170.558 85.9032 168.846 86.9455 167.695C87.7739 166.786 88.8696 166.438 90.0989 166.732C91.6221 167.107 93.0385 167.802 94.3479 168.632C95.8177 169.541 97.2341 170.531 98.6504 171.467C98.8642 171.628 99.3987 172.029 99.4254 172.163Z"
        fill="#91E7F2"
      />
      <path
        d="M65.2191 174.624C65.2191 174.758 64.4441 175.025 64.1235 175.079C60.9701 175.667 58.1908 176.336 55.2245 176.31C53.7814 176.283 52.6056 175.534 52.1513 174.357C51.5633 173.019 51.697 171.307 52.7392 170.157C53.5676 169.247 54.6633 168.899 55.8926 169.193C57.4158 169.568 58.8322 170.264 60.1416 171.093C61.6114 172.002 63.0278 172.992 64.4441 173.929C64.6579 174.089 65.1924 174.49 65.2191 174.624Z"
        fill="#B494F2"
      />
      <path
        d="M41.6755 179.038C41.6755 179.172 40.9006 179.439 40.5799 179.493C37.4265 180.081 34.6472 180.75 31.6809 180.724C30.2378 180.697 29.062 179.948 28.6077 178.771C28.0198 177.433 28.1534 175.721 29.1956 174.571C30.024 173.661 31.1197 173.313 32.349 173.608C33.8722 173.982 35.2886 174.678 36.5981 175.507C38.0679 176.416 39.4842 177.406 40.9006 178.343C41.1411 178.476 41.6755 178.878 41.6755 179.038Z"
        fill="#6A449D"
      />
      <path
        d="M99.2652 179.734C99.2919 179.841 98.7574 180.081 98.5437 180.135C96.4058 180.777 94.5084 181.446 92.4507 181.66C91.4352 181.767 90.58 181.312 90.1791 180.536C89.6714 179.653 89.6447 178.45 90.286 177.567C90.7938 176.871 91.5421 176.55 92.4239 176.657C93.5196 176.791 94.5351 177.192 95.5239 177.647C96.6196 178.155 97.6618 178.744 98.7307 179.306C98.8376 179.386 99.2385 179.627 99.2652 179.734Z"
        fill="#6A449D"
      />
      <path
        d="M62.5201 184.763C62.5468 184.87 62.0123 185.111 61.7985 185.164C59.6607 185.807 57.7633 186.475 55.7055 186.689C54.69 186.796 53.8349 186.342 53.434 185.566C52.9263 184.683 52.8996 183.479 53.5409 182.596C54.0487 181.901 54.7969 181.58 55.6788 181.687C56.7745 181.82 57.79 182.222 58.7788 182.677C59.8744 183.185 60.9167 183.773 61.9856 184.335C62.0925 184.442 62.4934 184.656 62.5201 184.763Z"
        fill="#8963C6"
      />
      <path
        d="M17.2769 175.828C17.3036 175.935 16.7692 176.176 16.5554 176.229C14.4175 176.871 12.5201 177.54 10.4624 177.754C9.44688 177.861 8.59172 177.406 8.19086 176.63C7.68311 175.748 7.65639 174.544 8.29776 173.661C8.80551 172.965 9.55377 172.644 10.4356 172.751C11.5313 172.885 12.5468 173.286 13.5356 173.741C14.6313 174.249 15.6735 174.838 16.7424 175.4C16.8493 175.48 17.2502 175.721 17.2769 175.828Z"
        fill="#8963C6"
      />
      <path
        d="M178.047 87.2785C177.913 87.3321 177.512 86.69 177.378 86.4225C176.122 83.8008 174.866 81.5269 174.198 78.9052C173.878 77.6478 174.279 76.444 175.187 75.7752C176.203 74.9458 177.753 74.6783 179.009 75.3204C179.997 75.8287 180.559 76.7115 180.585 77.8618C180.612 79.2797 180.345 80.6708 179.917 82.0351C179.463 83.5333 178.928 85.0046 178.447 86.476C178.394 86.6632 178.18 87.225 178.047 87.2785Z"
        fill="#91E7F2"
      />
      <path
        d="M185.128 50.0932C184.995 50.1467 184.594 49.5047 184.46 49.2372C183.204 46.6155 181.948 44.3415 181.28 41.7198C180.959 40.4625 181.36 39.2587 182.269 38.5899C183.284 37.7605 184.834 37.493 186.09 38.1351C187.079 38.6434 187.64 39.5262 187.667 40.6765C187.694 42.0944 187.426 43.4855 186.999 44.8498C186.545 46.3479 186.01 47.8193 185.529 49.2907C185.476 49.4779 185.262 50.0665 185.128 50.0932Z"
        fill="#B494F2"
      />
      <path
        d="M168.613 81.741C168.533 81.7945 168.185 81.3932 168.079 81.206C166.956 79.5206 165.861 78.0492 165.166 76.3103C164.818 75.4543 164.979 74.5982 165.54 74.0364C166.181 73.3676 167.197 73.0198 168.132 73.3409C168.88 73.5816 169.335 74.1434 169.468 74.946C169.629 75.9358 169.575 76.9256 169.415 77.9155C169.254 78.9855 169.014 80.0556 168.827 81.1257C168.8 81.2862 168.693 81.6875 168.613 81.741Z"
        fill="#6A449D"
      />
      <path
        d="M163.054 31.3401C162.974 31.3936 162.627 30.9923 162.52 30.8051C161.398 29.1197 160.302 27.6483 159.607 25.9095C159.26 25.0534 159.42 24.1973 159.981 23.6355C160.596 22.9667 161.638 22.619 162.573 22.94C163.322 23.1808 163.776 23.7425 163.91 24.5451C164.07 25.5349 164.017 26.5248 163.856 27.5146C163.696 28.5847 163.455 29.6547 163.268 30.7248C163.242 30.8853 163.135 31.3134 163.054 31.3401Z"
        fill="#B494F2"
      />
      <path
        d="M164.498 47.3111C164.417 47.3646 164.07 46.9633 163.963 46.776C162.841 45.0907 161.745 43.6193 161.05 41.8804C160.703 41.0243 160.863 40.1683 161.424 39.6065C162.039 38.9377 163.081 38.5899 164.017 38.9109C164.765 39.1517 165.219 39.7135 165.353 40.5161C165.513 41.5059 165.46 42.4957 165.299 43.4855C165.139 44.5556 164.898 45.6257 164.711 46.6958C164.685 46.8563 164.578 47.2843 164.498 47.3111Z"
        fill="#8963C6"
      />
      <path
        d="M155.812 66.4119C155.732 66.4654 155.385 66.0641 155.278 65.8769C154.156 64.1915 153.06 62.7201 152.365 60.9812C152.018 60.1252 152.178 59.2691 152.739 58.7073C153.354 58.0385 154.396 57.6907 155.331 58.0118C156.08 58.2525 156.534 58.8143 156.668 59.6169C156.828 60.6067 156.774 61.5965 156.614 62.5864C156.454 63.6564 156.213 64.7265 156.026 65.7966C155.999 65.9571 155.893 66.3584 155.812 66.4119Z"
        fill="#8963C6"
      />
      <path
        d="M154.53 9.00224C154.45 9.05574 154.102 8.65446 153.995 8.4672C152.873 6.78182 151.777 5.31046 151.082 3.57157C150.735 2.71551 150.895 1.85944 151.456 1.29765C152.071 0.628846 153.113 0.281069 154.049 0.602094C154.797 0.842862 155.251 1.40466 155.385 2.20722C155.545 3.19704 155.492 4.18687 155.331 5.1767C155.171 6.24678 154.931 7.31686 154.743 8.38694C154.717 8.54745 154.61 8.94874 154.53 9.00224Z"
        fill="#8963C6"
      />
      <path
        d="M160.008 86.8772C159.901 86.9307 159.367 86.3957 159.206 86.1549C157.496 83.8008 155.893 81.7676 154.77 79.3064C154.236 78.1293 154.423 76.872 155.198 76.0427C156.053 75.0528 157.523 74.5178 158.886 74.9191C159.955 75.2401 160.649 76.0159 160.89 77.1395C161.157 78.5306 161.13 79.9752 160.97 81.3663C160.783 82.9179 160.516 84.4428 160.275 85.9944C160.249 86.2084 160.115 86.797 160.008 86.8772Z"
        fill="#91E7F2"
      />
      <path
        d="M152.926 50.3877C152.819 50.4412 152.285 49.9062 152.125 49.6654C150.414 47.3113 148.811 45.2781 147.688 42.8169C147.154 41.6398 147.341 40.3825 148.116 39.5532C148.971 38.5633 150.441 38.0283 151.804 38.4296C152.873 38.7506 153.568 39.5264 153.808 40.65C154.075 42.0411 154.049 43.4857 153.888 44.8768C153.701 46.4284 153.434 47.9533 153.193 49.5049C153.14 49.7457 153.033 50.3342 152.926 50.3877Z"
        fill="#B494F2"
      />
      <path
        d="M145.764 73.4744C145.657 73.5279 145.123 72.9929 144.963 72.7521C143.252 70.3979 141.649 68.3648 140.526 65.9036C139.992 64.7265 140.179 63.4691 140.954 62.6398C141.809 61.65 143.279 61.115 144.642 61.5162C145.711 61.8373 146.406 62.6131 146.646 63.7367C146.913 65.1278 146.887 66.5724 146.726 67.9635C146.539 69.5151 146.272 71.04 146.031 72.5916C146.005 72.8324 145.898 73.4209 145.764 73.4744Z"
        fill="#91E7F2"
      />
      <path
        d="M150.548 88.001C150.468 88.0545 150.067 87.7335 149.907 87.573C148.49 86.1016 147.181 84.8443 146.165 83.2659C145.684 82.4901 145.684 81.6073 146.138 80.9652C146.646 80.1894 147.608 79.6811 148.57 79.8416C149.345 79.9486 149.907 80.4302 150.174 81.206C150.494 82.1423 150.601 83.1321 150.628 84.122C150.655 85.2188 150.628 86.3156 150.601 87.3857C150.655 87.5195 150.628 87.9475 150.548 88.001Z"
        fill="#B494F2"
      />
      <path
        d="M138.87 36.1555C138.789 36.2091 138.389 35.888 138.228 35.7275C136.812 34.2562 135.502 32.9988 134.487 31.4204C134.006 30.6446 134.006 29.7618 134.46 29.1198C134.968 28.344 135.93 27.8357 136.892 27.9962C137.667 28.1032 138.228 28.5847 138.495 29.3605C138.816 30.2969 138.923 31.2867 138.95 32.2765C138.976 33.3733 138.95 34.4702 138.923 35.5402C138.976 35.674 138.95 36.102 138.87 36.1555Z"
        fill="#6A449D"
      />
      <path
        d="M140.446 52.2601C140.366 52.3136 139.965 51.9925 139.805 51.832C138.389 50.3607 137.079 49.1033 136.064 47.5249C135.583 46.7491 135.583 45.8663 136.037 45.2243C136.545 44.4485 137.507 43.9402 138.469 44.1007C139.244 44.2077 139.805 44.6892 140.072 45.465C140.393 46.4014 140.5 47.3912 140.527 48.381C140.553 49.4778 140.526 50.5747 140.5 51.6448C140.553 51.7785 140.526 52.2065 140.446 52.2601Z"
        fill="#B494F2"
      />
      <path
        d="M144.001 93.9131C143.92 93.9666 143.519 93.6456 143.359 93.4851C141.943 92.0137 140.633 90.7564 139.618 89.178C139.137 88.4022 139.137 87.5194 139.591 86.8773C140.099 86.1015 141.061 85.5932 142.023 85.7537C142.798 85.8608 143.359 86.3423 143.626 87.1181C143.947 88.0544 144.054 89.0443 144.081 90.0341C144.107 91.1309 144.081 92.2277 144.054 93.2978C144.107 93.4316 144.081 93.8596 144.001 93.9131Z"
        fill="#6A449D"
      />
      <path
        d="M130.852 67.8833C130.772 67.9368 130.371 67.6158 130.211 67.4553C128.795 65.9839 127.485 64.7266 126.47 63.1482C125.989 62.3724 125.989 61.4896 126.443 60.8475C126.951 60.0717 127.913 59.5634 128.875 59.724C129.65 59.831 130.211 60.3125 130.478 61.0883C130.799 62.0246 130.906 63.0145 130.933 64.0043C130.959 65.1011 130.933 66.198 130.906 67.268C130.933 67.4018 130.906 67.8298 130.852 67.8833Z"
        fill="#B494F2"
      />
      <path
        d="M138.202 83.0786C138.095 83.1588 137.507 82.704 137.293 82.49C135.208 80.4569 133.258 78.7447 131.734 76.5243C130.986 75.4542 130.959 74.1701 131.574 73.2338C132.242 72.1102 133.605 71.3077 135.021 71.4949C136.117 71.6287 136.972 72.2708 137.373 73.3408C137.881 74.6784 138.095 76.0695 138.175 77.4874C138.255 79.039 138.255 80.6174 138.308 82.169C138.335 82.383 138.308 82.9983 138.202 83.0786Z"
        fill="#8963C6"
      />
      <path
        d="M126.764 50.3073C126.657 50.3876 126.069 49.9328 125.855 49.7188C123.771 47.6856 121.82 45.9735 120.297 43.7531C119.548 42.683 119.522 41.3989 120.136 40.4626C120.804 39.339 122.167 38.5364 123.584 38.7237C124.679 38.8575 125.535 39.4995 125.935 40.5696C126.443 41.9072 126.657 43.2983 126.737 44.7162C126.817 46.2678 126.817 47.8461 126.871 49.3978C126.897 49.6118 126.871 50.2271 126.764 50.3073Z"
        fill="#91E7F2"
      />
      <path
        d="M135.048 94.2876C134.995 94.3679 134.514 94.1003 134.353 93.9666C132.723 92.7627 131.2 91.7729 129.944 90.3818C129.329 89.713 129.169 88.8302 129.516 88.1079C129.864 87.2518 130.746 86.583 131.734 86.5563C132.509 86.5295 133.151 86.904 133.552 87.5996C134.033 88.4824 134.327 89.4187 134.514 90.4086C134.727 91.4787 134.888 92.5487 135.048 93.6456C135.048 93.8061 135.102 94.2074 135.048 94.2876Z"
        fill="#91E7F2"
      />
      <path
        d="M126.149 82.49C126.096 82.5703 125.615 82.3027 125.454 82.169C123.824 80.9651 122.301 79.9753 121.045 78.5842C120.43 77.9154 120.27 77.0326 120.617 76.3103C120.965 75.4542 121.847 74.7854 122.835 74.7587C123.61 74.7319 124.252 75.1064 124.653 75.802C125.134 76.6848 125.427 77.6211 125.615 78.611C125.828 79.681 125.989 80.7511 126.149 81.848C126.149 81.9817 126.202 82.4098 126.149 82.49Z"
        fill="#B494F2"
      />
      <path
        d="M116.235 67.4822C116.181 67.5624 115.7 67.2949 115.54 67.1612C113.91 65.9573 112.387 64.9675 111.131 63.5764C110.516 62.9076 110.356 62.0248 110.703 61.3025C111.05 60.4464 111.932 59.7776 112.921 59.7509C113.696 59.7241 114.337 60.0986 114.738 60.7942C115.219 61.677 115.513 62.6133 115.7 63.6031C115.914 64.6732 116.074 65.7433 116.235 66.8401C116.261 67.0007 116.315 67.4019 116.235 67.4822Z"
        fill="#6A449D"
      />
      <path
        d="M101.43 27.8889C101.376 27.9692 100.895 27.7017 100.735 27.5679C99.1049 26.3641 97.5816 25.3742 96.3256 23.9831C95.7109 23.3143 95.5506 22.4315 95.898 21.7092C96.2454 20.8531 97.1273 20.1843 98.1161 20.1576C98.8911 20.1308 99.5324 20.5054 99.9333 21.2009C100.414 22.0837 100.708 23.0201 100.895 24.0099C101.109 25.08 101.269 26.1501 101.43 27.2469C101.457 27.4074 101.51 27.8354 101.43 27.8889Z"
        fill="#91E7F2"
      />
      <path
        d="M129.356 102.875C129.276 102.982 128.608 102.634 128.34 102.474C125.935 100.842 123.717 99.4776 121.847 97.5515C120.938 96.6151 120.671 95.3845 121.125 94.3412C121.606 93.1106 122.782 92.094 124.198 92.0138C125.321 91.9603 126.229 92.4418 126.844 93.4316C127.592 94.6622 128.046 95.9998 128.367 97.391C128.741 98.9158 129.009 100.441 129.302 101.966C129.356 102.18 129.463 102.795 129.356 102.875Z"
        fill="#6A449D"
      />
      <path
        d="M117.544 87.0377C117.464 87.1447 116.796 86.7969 116.529 86.6364C114.124 85.0046 111.905 83.6402 110.035 81.7141C109.126 80.7777 108.859 79.5471 109.313 78.5038C109.794 77.2732 110.97 76.2566 112.387 76.1764C113.509 76.1229 114.418 76.6044 115.032 77.5942C115.78 78.8248 116.235 80.1624 116.555 81.5535C116.93 83.0784 117.197 84.6033 117.491 86.1281C117.544 86.3154 117.624 86.9307 117.544 87.0377Z"
        fill="#8963C6"
      />
      <path
        d="M102.098 84.8173C102.018 84.9243 101.323 84.6836 101.056 84.5498C98.4367 83.2925 96.0583 82.2759 93.9204 80.644C92.8782 79.8682 92.4506 78.6644 92.7446 77.5675C93.0385 76.2834 94.054 75.1063 95.4436 74.8121C96.5393 74.5981 97.5281 74.9458 98.2496 75.8286C99.1582 76.9255 99.7996 78.2096 100.334 79.5204C100.922 80.965 101.403 82.4631 101.938 83.9078C101.991 84.1218 102.151 84.7103 102.098 84.8173Z"
        fill="#91E7F2"
      />
      <path
        d="M119.575 105.095C119.522 105.176 119.014 105.015 118.827 104.908C117.01 103.999 115.353 103.276 113.856 102.126C113.135 101.564 112.841 100.735 113.054 99.9858C113.268 99.0763 113.99 98.2737 114.952 98.0865C115.727 97.9259 116.422 98.1935 116.929 98.8088C117.571 99.5846 117.998 100.467 118.373 101.404C118.773 102.42 119.094 103.464 119.468 104.48C119.495 104.614 119.602 105.015 119.575 105.095Z"
        fill="#91E7F2"
      />
      <path
        d="M110.89 95.5452C110.836 95.6255 110.329 95.4649 110.142 95.3579C108.324 94.4484 106.668 93.7261 105.171 92.5757C104.449 92.0139 104.156 91.1846 104.369 90.4356C104.583 89.526 105.305 88.7234 106.267 88.5362C107.042 88.3757 107.736 88.6432 108.244 89.2585C108.886 90.0343 109.313 90.9171 109.687 91.8534C110.088 92.87 110.409 93.9133 110.783 94.9299C110.81 95.0637 110.943 95.4649 110.89 95.5452Z"
        fill="#8963C6"
      />
      <path
        d="M85.6628 70.6121C85.6094 70.6924 85.1016 70.5318 84.9146 70.4248C83.0974 69.5153 81.4405 68.793 79.944 67.6426C79.2224 67.0808 78.9285 66.2515 79.1423 65.5025C79.356 64.5929 80.0776 63.7903 81.0396 63.6031C81.8146 63.4425 82.5094 63.7101 83.0172 64.3254C83.6586 65.1012 84.0861 65.984 84.4603 66.9203C84.8611 67.9369 85.1818 68.9802 85.5559 69.9968C85.5827 70.1306 85.7163 70.5319 85.6628 70.6121Z"
        fill="#91E7F2"
      />
      <path
        d="M101.323 69.1675C101.269 69.2478 100.762 69.0873 100.575 68.9803C98.7574 68.0707 97.1005 67.3484 95.604 66.198C94.8825 65.6363 94.5885 64.8069 94.8023 64.0579C95.0161 63.1483 95.7376 62.3457 96.6997 62.1585C97.4746 61.998 98.1695 62.2655 98.6772 62.8808C99.3186 63.6566 99.7462 64.5394 100.12 65.4757C100.521 66.4923 100.842 67.5356 101.216 68.5522C101.269 68.6592 101.376 69.0873 101.323 69.1675Z"
        fill="#8963C6"
      />
      <path
        d="M68.1319 55.7112C68.0785 55.7915 67.5707 55.6309 67.3837 55.5239C65.5665 54.6144 63.9096 53.8921 62.4131 52.7417C61.6915 52.1799 61.3976 51.3506 61.6114 50.6016C61.8252 49.692 62.5467 48.8894 63.5087 48.7022C64.2837 48.5417 64.9786 48.8092 65.4863 49.4245C66.1277 50.2003 66.5552 51.0831 66.9294 52.0194C67.3302 53.036 67.6509 54.0793 68.0251 55.0959C68.0785 55.2297 68.1854 55.6309 68.1319 55.7112Z"
        fill="#6A449D"
      />
      <path
        d="M95.6574 94.6623C95.604 94.7425 95.0962 94.6088 94.9092 94.5285C93.0118 93.7794 91.3015 93.1909 89.7248 92.1743C88.9498 91.666 88.6024 90.8635 88.736 90.0877C88.8696 89.1781 89.5377 88.2953 90.473 88.0277C91.2213 87.8137 91.9428 88.001 92.504 88.5895C93.1989 89.3118 93.7066 90.1679 94.1342 91.0507C94.6152 92.0406 95.0428 93.0304 95.4704 94.047C95.5505 94.1807 95.7109 94.582 95.6574 94.6623Z"
        fill="#6A449D"
      />
      <path
        d="M115.967 116.064C115.914 116.171 115.166 115.957 114.898 115.823C112.253 114.619 109.848 113.683 107.656 112.105C106.587 111.356 106.133 110.178 106.4 109.082C106.641 107.798 107.656 106.594 109.019 106.273C110.115 106.005 111.104 106.353 111.852 107.209C112.787 108.279 113.482 109.536 114.043 110.821C114.658 112.238 115.192 113.71 115.754 115.181C115.861 115.395 116.048 115.957 115.967 116.064Z"
        fill="#8963C6"
      />
      <path
        d="M80.3715 87.0377C80.3181 87.1447 79.5698 86.9842 79.3026 86.8772C76.5768 85.8606 74.0915 85.0848 71.8199 83.6937C70.6976 82.9982 70.1631 81.8746 70.3501 80.751C70.5105 79.4401 71.4191 78.1828 72.782 77.7548C73.8509 77.4337 74.8664 77.6745 75.6949 78.4771C76.7104 79.4936 77.4586 80.6707 78.1267 81.9281C78.8483 83.3192 79.4896 84.737 80.1577 86.1549C80.2112 86.3422 80.425 86.904 80.3715 87.0377Z"
        fill="#8963C6"
      />
      <path
        d="M105.011 118.338C104.984 118.418 104.45 118.338 104.262 118.284C102.312 117.722 100.548 117.294 98.891 116.412C98.0893 115.983 97.635 115.208 97.7152 114.432C97.7686 113.522 98.3298 112.586 99.2651 112.211C99.9867 111.917 100.708 112.051 101.323 112.586C102.071 113.228 102.659 114.031 103.194 114.887C103.755 115.823 104.262 116.786 104.797 117.722C104.85 117.856 105.037 118.231 105.011 118.338Z"
        fill="#B494F2"
      />
      <path
        d="M76.0958 99.1567C76.0691 99.237 75.5346 99.1567 75.3475 99.1032C73.3967 98.5414 71.6329 98.1134 69.9761 97.2306C69.1744 96.8026 68.7201 96.0268 68.8002 95.2509C68.8537 94.3414 69.4149 93.4051 70.3502 93.0305C71.0717 92.7363 71.7933 92.87 72.4079 93.4051C73.1562 94.0471 73.7441 94.8497 74.2786 95.7057C74.8398 96.642 75.3475 97.6051 75.882 98.5414C75.9355 98.702 76.1225 99.0765 76.0958 99.1567Z"
        fill="#6A449D"
      />
      <path
        d="M62.039 76.7652C62.0123 76.8454 61.4778 76.7652 61.2908 76.7117C59.3399 76.1499 57.5762 75.7218 55.9193 74.839C55.1176 74.411 54.6633 73.6352 54.7435 72.8593C54.7969 71.9498 55.3581 71.0135 56.2934 70.6389C57.015 70.3447 57.7365 70.4784 58.3512 71.0135C59.0994 71.6555 59.6874 72.4581 60.2218 73.3141C60.783 74.2505 61.2908 75.2135 61.8252 76.1499C61.8787 76.3104 62.0925 76.6849 62.039 76.7652Z"
        fill="#8963C6"
      />
      <path
        d="M102.766 128.477C102.713 128.611 101.964 128.504 101.67 128.423C98.8644 127.701 96.2989 127.193 93.8938 126.042C92.7179 125.481 92.0498 124.41 92.13 123.26C92.1567 121.949 92.9317 120.585 94.2412 120.023C95.2567 119.595 96.2989 119.729 97.2075 120.451C98.3299 121.334 99.2118 122.458 99.9868 123.635C100.842 124.946 101.617 126.283 102.445 127.621C102.526 127.808 102.819 128.343 102.766 128.477Z"
        fill="#91E7F2"
      />
      <path
        d="M62.6803 109.563C62.6269 109.697 61.8786 109.59 61.5847 109.51C58.7787 108.787 56.2132 108.279 53.8081 107.129C52.6322 106.567 51.9642 105.497 52.0443 104.347C52.071 103.036 52.846 101.671 54.1555 101.11C55.171 100.682 56.2132 100.815 57.1218 101.538C58.2442 102.42 59.1261 103.544 59.9011 104.721C60.7562 106.032 61.5312 107.37 62.3596 108.707C62.4398 108.921 62.7338 109.456 62.6803 109.563Z"
        fill="#91E7F2"
      />
      <path
        d="M94.0005 109.563C93.9471 109.697 93.1988 109.59 92.9049 109.51C90.0989 108.787 87.5334 108.279 85.1283 107.129C83.9524 106.567 83.2843 105.497 83.3645 104.347C83.3912 103.036 84.1662 101.671 85.4757 101.11C86.4912 100.682 87.5334 100.815 88.442 101.538C89.5644 102.42 90.4463 103.544 91.2213 104.721C92.0764 106.032 92.8514 107.37 93.6799 108.707C93.76 108.921 94.054 109.456 94.0005 109.563Z"
        fill="#91E7F2"
      />
      <path
        d="M55.4117 93.4048C55.3582 93.5385 54.61 93.4315 54.316 93.3513C51.51 92.629 48.9445 92.1207 46.5394 90.9703C45.3636 90.4086 44.6955 89.3385 44.7756 88.1881C44.8024 86.8773 45.5774 85.5129 46.8868 84.9511C47.9023 84.5231 48.9445 84.6569 49.8531 85.3792C50.9755 86.262 51.8574 87.3856 52.6324 88.5627C53.4876 89.8735 54.2625 91.2111 55.091 92.5487C55.1711 92.736 55.4651 93.271 55.4117 93.4048Z"
        fill="#B494F2"
      />
      <path
        d="M93.2256 134.255C93.1989 134.335 92.6912 134.362 92.4774 134.335C90.4464 134.121 88.6559 134.014 86.8387 133.426C85.9568 133.158 85.3956 132.463 85.3421 131.687C85.2352 130.777 85.6361 129.761 86.4913 129.252C87.1594 128.851 87.9076 128.851 88.5757 129.252C89.4309 129.761 90.1524 130.456 90.8205 131.179C91.542 132.008 92.2101 132.864 92.905 133.693C93.0119 133.8 93.2524 134.148 93.2256 134.255Z"
        fill="#B494F2"
      />
      <path
        d="M42.4506 107.717C42.4239 107.797 41.9161 107.824 41.7023 107.797C39.6713 107.583 37.8808 107.476 36.0636 106.888C35.1818 106.62 34.6206 105.925 34.5671 105.149C34.4602 104.239 34.8611 103.223 35.7162 102.714C36.3843 102.313 37.1326 102.313 37.8007 102.714C38.6558 103.223 39.3774 103.918 40.0455 104.64C40.767 105.47 41.4351 106.326 42.1299 107.155C42.2368 107.289 42.4773 107.61 42.4506 107.717Z"
        fill="#6A449D"
      />
      <path
        d="M31.7077 94.2877C31.6809 94.368 31.1732 94.3947 30.9594 94.368C28.9284 94.154 27.1379 94.047 25.3207 93.4584C24.4388 93.1909 23.8776 92.4953 23.8242 91.7195C23.7173 90.81 24.1182 89.7934 24.9733 89.2851C25.6414 88.8838 26.3897 88.8838 27.0578 89.2851C27.9129 89.7934 28.6344 90.4889 29.3025 91.2112C30.0241 92.0406 30.6922 92.8966 31.387 93.7259C31.4672 93.8597 31.7344 94.2075 31.7077 94.2877Z"
        fill="#B494F2"
      />
      <path
        d="M63.8029 122.029C63.7762 122.11 63.2684 122.136 63.0546 122.11C61.0236 121.896 59.2331 121.789 57.4159 121.2C56.534 120.933 55.9729 120.237 55.9194 119.461C55.8125 118.552 56.2134 117.535 57.0685 117.027C57.7366 116.626 58.4849 116.626 59.153 117.027C60.0081 117.535 60.7297 118.231 61.3978 118.953C62.1193 119.782 62.7874 120.638 63.4822 121.468C63.5891 121.601 63.8296 121.922 63.8029 122.029Z"
        fill="#B494F2"
      />
      <path
        d="M92.504 144.608C92.4773 144.742 91.729 144.795 91.4351 144.769C88.5222 144.555 85.9567 144.501 83.3645 143.752C82.1085 143.404 81.2534 142.468 81.1465 141.344C80.9327 140.034 81.4671 138.562 82.643 137.786C83.5783 137.171 84.6205 137.144 85.636 137.679C86.8921 138.375 87.961 139.311 88.923 140.328C89.992 141.451 91.0075 142.655 92.023 143.832C92.1299 144.02 92.504 144.501 92.504 144.608Z"
        fill="#8963C6"
      />
      <path
        d="M48.7307 128.878C48.704 129.012 47.9557 129.065 47.6618 129.038C44.7489 128.824 42.1834 128.771 39.5912 128.022C38.3352 127.674 37.48 126.738 37.3732 125.614C37.1594 124.303 37.6938 122.832 38.8697 122.056C39.805 121.441 40.8472 121.414 41.8627 121.949C43.1187 122.645 44.1877 123.581 45.1497 124.598C46.2187 125.721 47.2342 126.925 48.2497 128.102C48.3833 128.289 48.7574 128.744 48.7307 128.878Z"
        fill="#91E7F2"
      />
      <path
        d="M86.3309 150.574C86.3309 150.654 85.7964 150.761 85.6093 150.788C83.5783 150.922 81.7879 151.136 79.9172 150.868C79.0086 150.735 78.3405 150.173 78.1534 149.397C77.8862 148.514 78.1 147.444 78.8483 146.802C79.4362 146.294 80.1577 146.16 80.906 146.454C81.8413 146.802 82.6697 147.364 83.4447 147.979C84.2999 148.648 85.1016 149.397 85.93 150.093C86.0102 150.2 86.3309 150.494 86.3309 150.574Z"
        fill="#91E7F2"
      />
      <path
        d="M89.7514 161.569C89.7514 161.703 89.0032 161.863 88.7092 161.89C85.8231 162.184 83.2576 162.559 80.5852 162.318C79.2758 162.184 78.287 161.409 77.9663 160.312C77.5387 159.054 77.806 157.529 78.8482 156.566C79.6499 155.791 80.6921 155.577 81.7878 155.951C83.1507 156.406 84.3533 157.155 85.4757 157.984C86.7317 158.921 87.9342 159.91 89.1368 160.9C89.2704 161.034 89.7514 161.435 89.7514 161.569Z"
        fill="#B494F2"
      />
      <path
        d="M57.79 153.463C57.79 153.597 57.0418 153.758 56.7478 153.784C53.8616 154.079 51.2962 154.453 48.6238 154.212C47.3143 154.079 46.3256 153.303 46.0049 152.206C45.5773 150.949 45.8445 149.424 46.8868 148.461C47.6885 147.685 48.7307 147.471 49.8264 147.845C51.1893 148.3 52.3918 149.049 53.5142 149.879C54.7702 150.815 55.9728 151.805 57.1754 152.795C57.3357 152.928 57.79 153.33 57.79 153.463Z"
        fill="#6A449D"
      />
      <path
        d="M81.4404 168.658C81.4404 168.739 80.9594 168.953 80.7456 168.979C78.768 169.461 77.031 169.969 75.1603 170.049C74.2517 170.076 73.4767 169.648 73.1561 168.926C72.7285 168.096 72.7819 167 73.3966 166.251C73.8776 165.635 74.5724 165.394 75.3741 165.528C76.3629 165.716 77.2715 166.117 78.1267 166.598C79.0887 167.133 79.9973 167.695 80.9327 168.257C81.0663 168.31 81.4404 168.551 81.4404 168.658Z"
        fill="#91E7F2"
      />
      <path
        d="M14.1769 133.426C14.1769 133.506 13.6424 133.586 13.4286 133.586C11.3976 133.56 9.58043 133.613 7.7365 133.212C6.85462 133.025 6.21325 132.383 6.07963 131.607C5.89257 130.697 6.18653 129.654 6.98824 129.065C7.60288 128.61 8.35115 128.53 9.07269 128.878C9.98129 129.306 10.7563 129.921 11.4778 130.59C12.2795 131.339 13.0278 132.142 13.7761 132.918C13.9097 133.025 14.2036 133.346 14.1769 133.426Z"
        fill="#6A449D"
      />
      <path
        d="M12.5201 152.607C12.5201 152.687 12.0391 152.901 11.8253 152.928C9.84774 153.41 8.11071 153.918 6.24005 153.998C5.33145 154.025 4.55646 153.597 4.23578 152.875C3.8082 152.045 3.86164 150.949 4.47629 150.2C4.95731 149.584 5.65213 149.343 6.45384 149.477C7.44262 149.664 8.35122 150.066 9.20638 150.547C10.1684 151.082 11.077 151.644 12.0124 152.206C12.146 152.286 12.5201 152.527 12.5201 152.607Z"
        fill="#B494F2"
      />
      <path
        d="M50.0936 164.512C50.0936 164.592 49.6126 164.806 49.3988 164.833C47.4212 165.314 45.6842 165.823 43.8135 165.903C42.9049 165.93 42.1299 165.502 41.8093 164.779C41.3817 163.95 41.4351 162.853 42.0498 162.104C42.5308 161.489 43.2256 161.248 44.0273 161.382C45.0161 161.569 45.9247 161.97 46.7799 162.452C47.7419 162.987 48.6505 163.549 49.5858 164.11C49.7195 164.191 50.0669 164.431 50.0936 164.512Z"
        fill="#91E7F2"
      />
      <path
        d="M30.8792 162.238C30.8792 162.318 30.3982 162.532 30.1844 162.559C28.2069 163.041 26.4698 163.549 24.5992 163.629C23.6906 163.656 22.9156 163.228 22.5949 162.506C22.1673 161.676 22.2208 160.579 22.8354 159.83C23.3164 159.215 24.0113 158.974 24.813 159.108C25.8017 159.295 26.7104 159.697 27.5655 160.178C28.5276 160.713 29.4362 161.275 30.3715 161.837C30.5051 161.917 30.8525 162.158 30.8792 162.238Z"
        fill="#6A449D"
      />
      <path
        d="M84.2998 178.69C84.3265 178.824 83.6317 179.118 83.3378 179.199C80.5318 180.001 78.0732 180.804 75.4008 181.018C74.0914 181.125 72.9957 180.509 72.4879 179.52C71.8466 178.369 71.8466 176.791 72.7017 175.667C73.3698 174.784 74.3319 174.383 75.481 174.544C76.8973 174.758 78.2068 175.293 79.4895 175.908C80.8792 176.604 82.2421 177.379 83.605 178.128C83.7653 178.235 84.2731 178.556 84.2998 178.69Z"
        fill="#8963C6"
      />
      <path
        d="M187.239 80.1357C187.159 80.1624 186.865 79.7076 186.785 79.5204C185.957 77.6477 185.155 76.0426 184.754 74.1967C184.567 73.3139 184.861 72.4846 185.529 72.0298C186.277 71.468 187.346 71.3342 188.201 71.789C188.896 72.1636 189.244 72.8056 189.244 73.6082C189.244 74.598 189.003 75.5611 188.682 76.5241C188.335 77.5675 187.934 78.5841 187.533 79.6006C187.507 79.7344 187.32 80.1089 187.239 80.1357Z"
        fill="#6A449D"
      />
      <path
        d="M98.3832 187.572C98.4099 187.706 97.6884 188.107 97.3677 188.214C94.3746 189.364 91.7557 190.488 88.8161 190.996C87.3998 191.237 86.117 190.675 85.4757 189.605C84.6739 188.401 84.5136 186.689 85.342 185.352C85.9834 184.308 87.0256 183.773 88.2816 183.854C89.8583 183.961 91.3549 184.415 92.7979 184.977C94.4014 185.619 95.9513 186.342 97.528 187.037C97.7418 187.144 98.3297 187.438 98.3832 187.572Z"
        fill="#B494F2"
      />
      <path
        d="M99.5057 195.062C99.5325 195.169 99.0514 195.49 98.8644 195.598C96.8601 196.614 95.123 197.604 93.1188 198.166C92.1567 198.433 91.2214 198.139 90.6869 197.443C90.0455 196.668 89.805 195.49 90.2861 194.501C90.6602 193.725 91.3283 193.27 92.2369 193.243C93.3326 193.19 94.4015 193.404 95.4704 193.698C96.6196 194.019 97.7687 194.42 98.9178 194.768C99.0514 194.795 99.479 194.955 99.5057 195.062Z"
        fill="#91E7F2"
      />
      <path
        d="M53.3271 200.226C53.3806 200.306 52.9263 200.681 52.7125 200.788C50.7884 201.938 49.1315 203.061 47.1539 203.757C46.1919 204.105 45.2566 203.864 44.6686 203.222C43.9738 202.5 43.6531 201.323 44.054 200.306C44.3747 199.503 45.0161 199.022 45.8979 198.915C46.9936 198.781 48.0893 198.915 49.1582 199.129C50.3341 199.37 51.5099 199.664 52.659 199.958C52.8461 200.012 53.2737 200.145 53.3271 200.226Z"
        fill="#6A449D"
      />
      <path
        d="M9.47361 191.879C9.50034 191.986 9.01931 192.307 8.83225 192.414C6.82797 193.431 5.09093 194.421 3.08666 194.982C2.1246 195.25 1.18928 194.956 0.654802 194.26C0.0134343 193.484 -0.227079 192.307 0.253947 191.317C0.628079 190.542 1.29617 190.087 2.20478 190.06C3.30045 190.006 4.39612 190.22 5.43834 190.515C6.58746 190.836 7.73658 191.237 8.88569 191.585C8.99259 191.638 9.44689 191.799 9.47361 191.879Z"
        fill="#91E7F2"
      />
      <path
        d="M25.9086 188.294C25.9353 188.401 25.4275 188.669 25.2138 188.749C23.1293 189.552 21.2854 190.354 19.2277 190.702C18.2389 190.862 17.3303 190.488 16.876 189.739C16.3148 188.909 16.2079 187.706 16.7691 186.769C17.2234 186.047 17.9449 185.672 18.8268 185.726C19.9225 185.806 20.9647 186.101 21.9802 186.502C23.1026 186.93 24.1983 187.438 25.2939 187.92C25.4543 188 25.8818 188.214 25.9086 188.294Z"
        fill="#B494F2"
      />
      <path
        d="M18.9872 203.088C19.0139 203.195 18.5329 203.516 18.3458 203.623C16.3415 204.64 14.6045 205.63 12.6002 206.192C11.6382 206.459 10.7028 206.165 10.1684 205.469C9.52698 204.693 9.28647 203.516 9.7675 202.527C10.1416 201.751 10.8097 201.296 11.7183 201.269C12.814 201.216 13.8829 201.43 14.9519 201.724C16.101 202.045 17.2501 202.446 18.3992 202.794C18.5061 202.821 18.9604 202.981 18.9872 203.088Z"
        fill="#B494F2"
      />
      <path
        d="M68.8269 195.062C68.8536 195.169 68.3726 195.49 68.1855 195.598C66.1812 196.614 64.4442 197.604 62.4399 198.166C61.4779 198.433 60.5425 198.139 60.0081 197.443C59.3667 196.668 59.1262 195.49 59.6072 194.501C59.9813 193.725 60.6494 193.27 61.558 193.243C62.6537 193.19 63.7227 193.404 64.7916 193.698C65.9407 194.019 67.0898 194.42 68.2389 194.768C68.3458 194.795 68.8001 194.955 68.8269 195.062Z"
        fill="#91E7F2"
      />
      <path
        d="M34.9947 197.577C35.0215 197.684 34.5404 198.005 34.3534 198.112C31.9482 199.316 30.425 200.199 28.6078 200.681C27.6457 200.948 26.7104 200.654 26.1759 199.958C25.5346 199.182 25.294 198.005 25.7751 197.016C26.1492 196.24 26.8173 195.785 27.7259 195.758C28.8216 195.705 29.8905 195.919 30.9595 196.213C32.1086 196.534 33.2577 196.935 34.4068 197.283C34.5137 197.31 34.968 197.47 34.9947 197.577Z"
        fill="#8963C6"
      />
      <path
        d="M99.7462 203.035C99.7996 203.169 99.1582 203.677 98.8643 203.864C96.1117 205.496 93.7333 207.101 90.9273 208.064C89.5644 208.546 88.2015 208.225 87.3998 207.288C86.3843 206.245 85.93 204.586 86.5179 203.115C86.9722 201.965 87.9076 201.269 89.1636 201.135C90.7403 200.975 92.2903 201.135 93.8135 201.456C95.4971 201.804 97.154 202.259 98.8375 202.66C99.0513 202.714 99.6927 202.901 99.7462 203.035Z"
        fill="#8963C6"
      />
      <path
        d="M68.8268 207.556C68.8803 207.69 68.2389 208.198 67.945 208.385C65.1924 210.017 62.814 211.622 60.008 212.585C58.6451 213.067 57.2822 212.746 56.4805 211.81C55.465 210.766 55.0107 209.108 55.5986 207.636C56.0529 206.486 56.9883 205.79 58.2443 205.657C59.821 205.496 61.3709 205.657 62.8942 205.978C64.5778 206.326 66.2346 206.78 67.9182 207.182C68.132 207.235 68.7734 207.422 68.8268 207.556Z"
        fill="#91E7F2"
      />
      <path
        d="M102.178 210.204C102.231 210.285 101.804 210.713 101.617 210.846C99.8264 212.184 98.2764 213.468 96.4057 214.351C95.4971 214.779 94.5351 214.672 93.8937 214.083C93.1187 213.441 92.6911 212.318 92.9851 211.248C93.2256 210.418 93.8135 209.857 94.6687 209.669C95.7376 209.428 96.8333 209.455 97.929 209.535C99.1316 209.643 100.307 209.83 101.51 209.99C101.67 210.044 102.125 210.124 102.178 210.204Z"
        fill="#6A449D"
      />
      <path
        d="M29.1956 225.56C29.2491 225.64 28.8215 226.068 28.6344 226.202C26.8439 227.54 25.294 228.824 23.4233 229.707C22.5147 230.135 21.5527 230.028 20.9113 229.439C20.1363 228.797 19.7087 227.673 20.0027 226.603C20.2432 225.774 20.8311 225.212 21.6863 225.025C22.7552 224.784 23.8509 224.811 24.9466 224.891C26.1491 224.998 27.325 225.185 28.5275 225.346C28.6879 225.4 29.1422 225.48 29.1956 225.56Z"
        fill="#B494F2"
      />
      <path
        d="M103.782 218.016C103.862 218.15 103.3 218.765 103.06 218.979C100.628 221.066 98.5704 223.045 95.9782 224.517C94.7222 225.212 93.3326 225.159 92.3438 224.356C91.1679 223.5 90.4464 221.949 90.7671 220.397C91.0076 219.193 91.8093 218.337 93.0119 217.989C94.5351 217.534 96.0851 217.454 97.6351 217.508C99.3454 217.561 101.056 217.695 102.793 217.829C103.033 217.829 103.701 217.909 103.782 218.016Z"
        fill="#8963C6"
      />
      <path
        d="M25.0801 246.507C25.1603 246.614 24.6793 247.122 24.4655 247.31C22.4345 249.075 20.6707 250.734 18.5061 251.964C17.4639 252.553 16.288 252.5 15.4596 251.831C14.4708 251.108 13.8562 249.798 14.1234 248.513C14.3372 247.497 15.0053 246.775 16.0208 246.48C17.3035 246.106 18.613 246.026 19.9225 246.052C21.3655 246.079 22.8086 246.213 24.2517 246.32C24.4655 246.347 25.0267 246.4 25.0801 246.507Z"
        fill="#8963C6"
      />
      <path
        d="M107.416 224.65C107.496 224.731 107.149 225.212 106.988 225.373C105.465 227.005 104.156 228.529 102.472 229.733C101.644 230.322 100.681 230.375 99.9332 229.894C99.0513 229.385 98.4367 228.342 98.5436 227.272C98.6237 226.416 99.1048 225.774 99.9332 225.426C100.949 224.998 102.044 224.838 103.113 224.731C104.316 224.624 105.518 224.624 106.721 224.57C106.881 224.57 107.336 224.57 107.416 224.65Z"
        fill="#91E7F2"
      />
      <path
        d="M110.355 232.087C110.462 232.194 110.008 232.89 109.821 233.158C107.79 235.645 106.106 237.946 103.808 239.846C102.686 240.755 101.296 240.916 100.227 240.327C98.9177 239.685 97.929 238.267 97.9824 236.716C98.0091 235.485 98.6505 234.522 99.7996 233.933C101.216 233.238 102.739 232.863 104.289 232.649C106 232.409 107.71 232.248 109.42 232.061C109.58 232.007 110.249 231.98 110.355 232.087Z"
        fill="#6A449D"
      />
      <path
        d="M49.2384 263.708C49.3453 263.815 48.891 264.511 48.7039 264.778C46.6729 267.266 44.9893 269.567 42.6911 271.466C41.5687 272.376 40.1791 272.536 39.1101 271.948C37.8007 271.306 36.8119 269.888 36.8653 268.336C36.8921 267.106 37.5334 266.143 38.6826 265.554C40.0989 264.859 41.6222 264.484 43.1721 264.27C44.8825 264.029 46.5928 263.869 48.3031 263.682C48.4902 263.628 49.1315 263.601 49.2384 263.708Z"
        fill="#91E7F2"
      />
      <path
        d="M115.085 237.973C115.166 238.026 114.925 238.562 114.792 238.749C113.562 240.621 112.547 242.36 111.104 243.832C110.409 244.554 109.447 244.768 108.645 244.42C107.683 244.072 106.908 243.163 106.828 242.066C106.774 241.21 107.122 240.488 107.87 240.006C108.805 239.418 109.821 239.043 110.89 238.776C112.066 238.481 113.242 238.241 114.417 238C114.524 238 115.005 237.919 115.085 237.973Z"
        fill="#8963C6"
      />
      <path
        d="M52.7659 282.114C52.8461 282.167 52.6055 282.702 52.4719 282.89C51.2426 284.762 50.2271 286.501 48.7841 287.973C48.0892 288.695 47.1272 288.909 46.3255 288.561C45.3634 288.213 44.5884 287.304 44.5083 286.207C44.4548 285.351 44.8022 284.629 45.5505 284.147C46.4858 283.558 47.5013 283.184 48.5703 282.916C49.7461 282.622 50.922 282.381 52.0978 282.141C52.2314 282.141 52.6857 282.06 52.7659 282.114Z"
        fill="#8963C6"
      />
      <path
        d="M119.254 244.768C119.388 244.848 119.067 245.624 118.907 245.918C117.33 248.727 116.074 251.295 114.124 253.543C113.188 254.639 111.852 255.041 110.676 254.639C109.287 254.238 108.057 253.008 107.843 251.483C107.656 250.252 108.111 249.182 109.153 248.433C110.436 247.497 111.852 246.881 113.349 246.373C114.979 245.838 116.636 245.383 118.292 244.902C118.479 244.848 119.148 244.688 119.254 244.768Z"
        fill="#6A449D"
      />
      <path
        d="M124.947 249.771C125.027 249.824 124.866 250.386 124.786 250.6C123.904 252.66 123.21 254.532 122.034 256.245C121.473 257.074 120.564 257.448 119.709 257.261C118.72 257.074 117.758 256.325 117.491 255.281C117.277 254.452 117.517 253.676 118.159 253.061C118.961 252.312 119.923 251.777 120.911 251.322C122.007 250.814 123.129 250.386 124.252 249.931C124.412 249.878 124.84 249.717 124.947 249.771Z"
        fill="#B494F2"
      />
      <path
        d="M105.064 269.674C105.144 269.728 104.984 270.289 104.904 270.503C104.022 272.563 103.327 274.436 102.151 276.148C101.59 276.977 100.681 277.352 99.8263 277.165C98.8376 276.977 97.8755 276.228 97.6083 275.185C97.3945 274.356 97.635 273.58 98.2764 272.965C99.0781 272.216 100.04 271.681 101.029 271.226C102.125 270.717 103.247 270.289 104.369 269.835C104.53 269.781 104.984 269.621 105.064 269.674Z"
        fill="#6A449D"
      />
      <path
        d="M130.238 255.736C130.371 255.79 130.211 256.619 130.077 256.913C129.035 259.936 128.207 262.692 126.71 265.26C125.989 266.491 124.733 267.133 123.503 266.945C122.06 266.785 120.644 265.795 120.163 264.324C119.789 263.147 120.029 262.023 120.911 261.113C122.007 259.963 123.316 259.107 124.679 258.358C126.203 257.529 127.752 256.806 129.302 256.057C129.49 255.95 130.104 255.683 130.238 255.736Z"
        fill="#91E7F2"
      />
      <path
        d="M136.705 259.669C136.812 259.696 136.732 260.284 136.678 260.498C136.171 262.692 135.796 264.645 134.941 266.544C134.514 267.454 133.712 267.989 132.83 267.962C131.815 267.962 130.746 267.373 130.318 266.384C129.971 265.608 130.051 264.778 130.612 264.083C131.28 263.2 132.135 262.505 133.017 261.889C134.006 261.22 135.048 260.578 136.064 259.936C136.197 259.856 136.598 259.642 136.705 259.669Z"
        fill="#8963C6"
      />
      <path
        d="M119.388 283.665C119.495 283.692 119.415 284.281 119.361 284.495C118.854 286.688 118.479 288.641 117.624 290.541C117.197 291.45 116.395 291.985 115.513 291.958C114.498 291.958 113.429 291.37 113.001 290.38C112.654 289.604 112.734 288.775 113.295 288.079C113.963 287.197 114.818 286.501 115.7 285.886C116.689 285.217 117.731 284.575 118.747 283.933C118.88 283.853 119.281 283.639 119.388 283.665Z"
        fill="#6A449D"
      />
      <path
        d="M113.937 296.613C114.043 296.64 113.963 297.229 113.91 297.443C113.402 299.636 113.028 301.589 112.173 303.489C111.745 304.398 110.943 304.933 110.062 304.907C109.046 304.907 107.977 304.318 107.55 303.328C107.202 302.552 107.282 301.723 107.844 301.027C108.512 300.145 109.367 299.449 110.249 298.834C111.237 298.165 112.28 297.523 113.295 296.881C113.455 296.801 113.856 296.56 113.937 296.613Z"
        fill="#8963C6"
      />
      <path
        d="M142.932 264.618C143.065 264.645 143.038 265.501 142.985 265.822C142.477 268.979 142.157 271.841 141.114 274.623C140.607 275.961 139.484 276.817 138.255 276.844C136.812 276.951 135.235 276.228 134.514 274.837C133.926 273.74 133.979 272.59 134.701 271.547C135.583 270.236 136.705 269.166 137.934 268.176C139.27 267.106 140.687 266.116 142.076 265.099C142.237 264.939 142.798 264.591 142.932 264.618Z"
        fill="#6A449D"
      />
      <path
        d="M128.768 291.959C128.902 291.985 128.875 292.841 128.822 293.162C128.314 296.319 127.993 299.182 126.951 301.964C126.443 303.302 125.321 304.158 124.091 304.184C122.648 304.291 121.072 303.569 120.35 302.178C119.762 301.081 119.816 299.931 120.537 298.887C121.419 297.577 122.541 296.506 123.771 295.517C125.107 294.447 126.523 293.457 127.913 292.44C128.073 292.306 128.634 291.932 128.768 291.959Z"
        fill="#91E7F2"
      />
      <path
        d="M149.987 267.373C150.094 267.4 150.12 267.962 150.12 268.203C149.987 270.45 149.987 272.456 149.452 274.463C149.212 275.426 148.49 276.094 147.608 276.228C146.619 276.415 145.47 276.014 144.856 275.105C144.375 274.382 144.321 273.58 144.749 272.804C145.257 271.814 145.978 270.985 146.753 270.236C147.608 269.38 148.517 268.604 149.399 267.801C149.532 267.641 149.88 267.347 149.987 267.373Z"
        fill="#91E7F2"
      />
      <path
        d="M136.892 299.342C136.999 299.369 137.026 299.931 137.026 300.172C136.892 302.419 136.892 304.425 136.358 306.432C136.117 307.395 135.396 308.063 134.514 308.197C133.525 308.384 132.376 307.983 131.761 307.074C131.28 306.351 131.227 305.549 131.654 304.773C132.162 303.783 132.883 302.954 133.658 302.205C134.514 301.349 135.422 300.573 136.304 299.77C136.411 299.637 136.785 299.342 136.892 299.342Z"
        fill="#8963C6"
      />
      <path
        d="M140.259 314.751C140.366 314.778 140.393 315.34 140.393 315.581C140.259 317.828 140.259 319.834 139.725 321.84C139.484 322.804 138.763 323.472 137.881 323.606C136.892 323.793 135.743 323.392 135.128 322.483C134.647 321.76 134.594 320.958 135.021 320.182C135.529 319.192 136.251 318.363 137.026 317.614C137.881 316.758 138.789 315.982 139.671 315.179C139.805 315.045 140.152 314.751 140.259 314.751Z"
        fill="#6A449D"
      />
      <path
        d="M123.878 346.212C123.985 346.239 124.011 346.8 124.011 347.041C123.878 349.288 123.878 351.295 123.343 353.301C123.103 354.264 122.381 354.933 121.499 355.067C120.51 355.254 119.361 354.853 118.747 353.943C118.266 353.221 118.212 352.418 118.64 351.643C119.148 350.653 119.869 349.823 120.644 349.074C121.499 348.218 122.408 347.443 123.29 346.64C123.423 346.479 123.771 346.185 123.878 346.212Z"
        fill="#6A449D"
      />
      <path
        d="M157.015 271.146C157.149 271.146 157.282 271.975 157.282 272.323C157.336 275.533 157.496 278.395 156.962 281.311C156.694 282.729 155.732 283.746 154.53 283.987C153.113 284.334 151.456 283.906 150.494 282.676C149.719 281.713 149.586 280.562 150.094 279.385C150.735 277.941 151.67 276.683 152.686 275.506C153.835 274.222 155.037 272.991 156.24 271.761C156.374 271.6 156.855 271.146 157.015 271.146Z"
        fill="#B494F2"
      />
      <path
        d="M164.417 272.644C164.524 272.644 164.658 273.205 164.685 273.446C164.952 275.667 165.273 277.646 165.112 279.733C165.032 280.749 164.444 281.525 163.616 281.766C162.654 282.114 161.478 281.927 160.703 281.151C160.088 280.535 159.928 279.733 160.195 278.904C160.542 277.86 161.077 276.897 161.718 276.014C162.413 275.025 163.161 274.088 163.91 273.152C164.017 272.991 164.31 272.644 164.417 272.644Z"
        fill="#91E7F2"
      />
      <path
        d="M142.691 338.293C142.798 338.293 142.932 338.855 142.958 339.096C143.226 341.316 143.546 343.296 143.386 345.382C143.306 346.399 142.718 347.175 141.889 347.415C140.927 347.763 139.752 347.576 138.977 346.8C138.362 346.185 138.202 345.382 138.469 344.553C138.816 343.51 139.351 342.547 139.992 341.664C140.687 340.674 141.435 339.738 142.183 338.801C142.29 338.641 142.584 338.293 142.691 338.293Z"
        fill="#91E7F2"
      />
      <path
        d="M163.322 304.612C163.429 304.612 163.562 305.174 163.589 305.415C163.856 307.635 164.177 309.615 164.017 311.702C163.936 312.718 163.349 313.494 162.52 313.735C161.558 314.083 160.382 313.895 159.607 313.119C158.993 312.504 158.832 311.702 159.099 310.872C159.447 309.829 159.981 308.866 160.623 307.983C161.318 306.993 162.066 306.057 162.814 305.121C162.921 304.96 163.215 304.612 163.322 304.612Z"
        fill="#6A449D"
      />
      <path
        d="M150.815 302.178C150.922 302.178 151.056 302.74 151.082 302.98C151.35 305.201 151.67 307.18 151.51 309.267C151.43 310.284 150.842 311.059 150.013 311.3C149.051 311.648 147.875 311.461 147.1 310.685C146.486 310.07 146.325 309.267 146.593 308.438C146.94 307.394 147.475 306.431 148.116 305.548C148.811 304.559 149.559 303.622 150.307 302.686C150.414 302.552 150.735 302.178 150.815 302.178Z"
        fill="#91E7F2"
      />
      <path
        d="M171.98 275.158C172.114 275.158 172.381 275.934 172.434 276.255C173.022 279.412 173.691 282.194 173.664 285.163C173.637 286.608 172.889 287.785 171.713 288.24C170.377 288.828 168.666 288.695 167.517 287.651C166.609 286.822 166.261 285.725 166.555 284.495C166.929 282.97 167.624 281.552 168.453 280.241C169.361 278.77 170.35 277.352 171.285 275.934C171.446 275.693 171.847 275.158 171.98 275.158Z"
        fill="#B494F2"
      />
      <path
        d="M174.439 309.401C174.572 309.401 174.84 310.177 174.893 310.498C175.481 313.654 176.149 316.437 176.122 319.406C176.096 320.851 175.347 322.028 174.171 322.483C172.835 323.071 171.125 322.937 169.976 321.894C169.067 321.065 168.72 319.968 169.014 318.737C169.388 317.212 170.083 315.795 170.911 314.484C171.82 313.012 172.809 311.595 173.744 310.177C173.904 309.963 174.305 309.428 174.439 309.401Z"
        fill="#91E7F2"
      />
      <path
        d="M178.848 332.969C178.982 332.969 179.249 333.745 179.303 334.066C179.89 337.223 180.559 340.005 180.532 342.975C180.505 344.419 179.757 345.596 178.581 346.051C177.245 346.64 175.534 346.506 174.385 345.463C173.477 344.633 173.129 343.537 173.423 342.306C173.797 340.781 174.492 339.363 175.321 338.052C176.229 336.581 177.218 335.163 178.153 333.745C178.287 333.505 178.688 332.969 178.848 332.969Z"
        fill="#8963C6"
      />
      <path
        d="M179.543 275.319C179.65 275.292 179.89 275.827 179.944 276.041C180.585 278.181 181.253 280.081 181.467 282.141C181.574 283.157 181.12 284.013 180.345 284.415C179.463 284.923 178.26 284.95 177.378 284.308C176.684 283.799 176.363 283.05 176.47 282.167C176.603 281.071 177.004 280.054 177.458 279.064C177.966 277.967 178.554 276.924 179.115 275.854C179.196 275.747 179.436 275.346 179.543 275.319Z"
        fill="#6A449D"
      />
      <path
        d="M184.567 312.103C184.674 312.076 184.915 312.611 184.968 312.825C185.609 314.965 186.277 316.865 186.491 318.925C186.598 319.941 186.144 320.797 185.369 321.198C184.487 321.707 183.284 321.733 182.402 321.091C181.708 320.583 181.387 319.834 181.494 318.951C181.628 317.854 182.028 316.838 182.483 315.848C182.99 314.751 183.578 313.708 184.14 312.638C184.246 312.504 184.46 312.103 184.567 312.103Z"
        fill="#6A449D"
      />
      <path
        d="M175.641 357.394C175.748 357.367 175.989 357.902 176.042 358.116C176.684 360.256 177.352 362.156 177.565 364.216C177.672 365.232 177.218 366.088 176.443 366.49C175.561 366.998 174.359 367.025 173.477 366.383C172.782 365.874 172.461 365.125 172.568 364.243C172.702 363.146 173.103 362.129 173.557 361.139C174.065 360.042 174.653 358.999 175.214 357.929C175.294 357.822 175.534 357.421 175.641 357.394Z"
        fill="#B494F2"
      />
      <path
        d="M87.186 196.454C87.2395 196.587 86.5981 196.989 86.3309 197.123C83.7119 198.38 81.4404 199.637 78.8215 200.306C77.5655 200.627 76.3629 200.226 75.6948 199.316C74.8664 198.3 74.5992 196.748 75.2405 195.491C75.7483 194.501 76.6302 193.939 77.7793 193.912C79.1956 193.885 80.5853 194.153 81.9482 194.581C83.4447 195.036 84.9145 195.571 86.3843 196.052C86.5714 196.106 87.1326 196.32 87.186 196.454Z"
        fill="#6A449D"
      />
      <path
        d="M50.0401 189.338C50.0936 189.471 49.4522 189.873 49.185 190.007C46.5661 191.264 44.2945 192.521 41.6756 193.19C40.4196 193.511 39.217 193.11 38.549 192.2C37.7205 191.184 37.4533 189.632 38.0947 188.375C38.6024 187.385 39.4843 186.823 40.6334 186.796C42.0498 186.77 43.4394 187.037 44.8023 187.465C46.2988 187.92 47.7686 188.455 49.2384 188.936C49.4255 189.017 50.0134 189.231 50.0401 189.338Z"
        fill="#91E7F2"
      />
      <path
        d="M81.6542 205.897C81.7077 205.977 81.3068 206.325 81.1197 206.432C79.4361 207.556 77.9663 208.652 76.2293 209.348C75.3741 209.696 74.519 209.535 73.9578 208.974C73.2897 208.358 72.9423 207.315 73.263 206.379C73.5035 205.63 74.0647 205.175 74.8664 205.041C75.8552 204.88 76.8439 204.934 77.8327 205.095C78.9017 205.255 79.9706 205.496 81.0396 205.683C81.1999 205.71 81.6008 205.817 81.6542 205.897Z"
        fill="#B494F2"
      />
      <path
        d="M31.3069 211.462C31.3604 211.542 30.9595 211.89 30.7724 211.997C29.0889 213.12 27.6191 214.217 25.882 214.913C25.0269 215.26 24.1717 215.1 23.6105 214.538C22.9424 213.896 22.595 212.879 22.9157 211.943C23.1562 211.194 23.7174 210.739 24.5191 210.605C25.5079 210.445 26.4967 210.498 27.4854 210.659C28.5544 210.819 29.6233 211.06 30.6923 211.248C30.8526 211.274 31.2802 211.381 31.3069 211.462Z"
        fill="#91E7F2"
      />
      <path
        d="M47.2609 210.017C47.3143 210.097 46.9135 210.445 46.7264 210.552C45.0428 211.676 43.573 212.772 41.836 213.468C40.9808 213.816 40.1257 213.655 39.5645 213.093C38.8964 212.478 38.549 211.435 38.8697 210.498C39.1102 209.749 39.6714 209.295 40.4731 209.161C41.4619 209 42.4506 209.054 43.4394 209.214C44.5084 209.375 45.5773 209.616 46.6463 209.803C46.8066 209.83 47.2342 209.91 47.2609 210.017Z"
        fill="#6A449D"
      />
      <path
        d="M66.3416 218.711C66.395 218.792 65.9942 219.139 65.8071 219.246C64.1235 220.37 62.6537 221.467 60.9167 222.162C60.0615 222.51 59.2064 222.349 58.6452 221.788C57.9771 221.172 57.6297 220.129 57.9504 219.193C58.1909 218.444 58.7521 217.989 59.5538 217.855C60.5425 217.695 61.5313 217.748 62.5201 217.909C63.589 218.069 64.658 218.31 65.7269 218.497C65.8873 218.524 66.2881 218.631 66.3416 218.711Z"
        fill="#6A449D"
      />
      <path
        d="M8.99271 219.995C9.04616 220.076 8.6453 220.423 8.45824 220.53C6.77465 221.654 5.30484 222.751 3.5678 223.446C2.71265 223.794 1.85749 223.634 1.29629 223.072C0.628201 222.43 0.280793 221.413 0.601477 220.477C0.84199 219.728 1.40319 219.273 2.2049 219.139C3.19367 218.979 4.18245 219.032 5.17122 219.193C6.24017 219.353 7.30912 219.594 8.37807 219.781C8.53841 219.808 8.93926 219.915 8.99271 219.995Z"
        fill="#6A449D"
      />
      <path
        d="M86.7852 214.511C86.8386 214.618 86.3042 215.153 86.0636 215.314C83.712 217.026 81.681 218.631 79.2224 219.755C78.0465 220.29 76.7905 220.103 75.9621 219.327C74.9733 218.471 74.4389 216.999 74.8397 215.635C75.1604 214.565 75.9354 213.869 77.0578 213.629C78.4474 213.361 79.8905 213.388 81.2801 213.548C82.8301 213.736 84.3533 214.003 85.9033 214.244C86.1171 214.271 86.705 214.404 86.7852 214.511Z"
        fill="#B494F2"
      />
      <path
        d="M50.334 221.6C50.3875 221.707 49.853 222.243 49.6125 222.403C47.2608 224.115 45.2298 225.72 42.7712 226.844C41.5954 227.379 40.3394 227.192 39.5109 226.416C38.5222 225.56 37.9877 224.088 38.3885 222.724C38.7092 221.654 39.4842 220.958 40.6066 220.718C41.9962 220.45 43.4393 220.477 44.8289 220.637C46.3789 220.825 47.9022 221.092 49.4521 221.333C49.6927 221.36 50.2806 221.493 50.334 221.6Z"
        fill="#B494F2"
      />
      <path
        d="M73.3967 228.743C73.4502 228.85 72.9157 229.385 72.6752 229.546C70.3235 231.258 68.2925 232.863 65.834 233.987C64.6581 234.522 63.4021 234.334 62.5737 233.559C61.5849 232.703 61.0504 231.231 61.4513 229.867C61.772 228.797 62.547 228.101 63.6693 227.86C65.059 227.593 66.502 227.62 67.8917 227.78C69.4416 227.967 70.9649 228.235 72.5149 228.476C72.7554 228.529 73.3433 228.636 73.3967 228.743Z"
        fill="#6A449D"
      />
      <path
        d="M87.9076 223.981C87.9611 224.062 87.6404 224.463 87.48 224.624C86.0102 226.041 84.7542 227.352 83.1775 228.369C82.4025 228.85 81.5207 228.85 80.8793 228.396C80.1043 227.887 79.5965 226.924 79.7569 225.961C79.8638 225.185 80.3448 224.624 81.1198 224.356C82.0551 224.035 83.0439 223.928 84.0327 223.901C85.1283 223.874 86.224 223.901 87.293 223.928C87.4266 223.874 87.8542 223.901 87.9076 223.981Z"
        fill="#91E7F2"
      />
      <path
        d="M36.1171 235.646C36.1705 235.726 35.8498 236.127 35.6895 236.288C34.2197 237.705 32.9637 239.016 31.387 240.033C30.612 240.514 29.7301 240.514 29.0888 240.06C28.3138 239.551 27.806 238.588 27.9664 237.625C28.0733 236.849 28.5543 236.288 29.3293 236.02C30.2646 235.699 31.2534 235.592 32.2421 235.565C33.3378 235.539 34.4335 235.565 35.5024 235.592C35.636 235.565 36.0636 235.592 36.1171 235.646Z"
        fill="#91E7F2"
      />
      <path
        d="M52.2047 234.067C52.2582 234.147 51.9375 234.549 51.7772 234.709C50.3074 236.127 49.0513 237.438 47.4746 238.455C46.6997 238.936 45.8178 238.936 45.1764 238.481C44.4014 237.973 43.8937 237.01 44.054 236.047C44.1609 235.271 44.6419 234.709 45.4169 234.442C46.3523 234.121 47.341 234.014 48.3298 233.987C49.4255 233.96 50.5212 233.987 51.5901 234.014C51.7237 233.987 52.1513 233.987 52.2047 234.067Z"
        fill="#B494F2"
      />
      <path
        d="M93.8135 230.536C93.867 230.616 93.5463 231.017 93.3859 231.178C91.9161 232.596 90.6601 233.907 89.0834 234.923C88.3084 235.405 87.4265 235.405 86.7852 234.95C86.0102 234.442 85.5024 233.479 85.6628 232.516C85.7697 231.74 86.2507 231.178 87.0257 230.91C87.961 230.589 88.9498 230.482 89.9386 230.456C91.0342 230.429 92.1299 230.456 93.1989 230.482C93.3325 230.429 93.7601 230.456 93.8135 230.536Z"
        fill="#8963C6"
      />
      <path
        d="M67.8114 243.698C67.8649 243.778 67.5442 244.179 67.3838 244.34C65.914 245.758 64.658 247.069 63.0813 248.085C62.3063 248.567 61.4245 248.567 60.7831 248.112C60.0081 247.604 59.5004 246.64 59.6607 245.677C59.7676 244.902 60.2486 244.34 61.0236 244.072C61.9589 243.751 62.9477 243.644 63.9365 243.618C65.0322 243.591 66.1278 243.618 67.1968 243.644C67.3304 243.618 67.758 243.618 67.8114 243.698Z"
        fill="#8963C6"
      />
      <path
        d="M82.9903 236.341C83.0705 236.448 82.6162 237.037 82.4024 237.251C80.3714 239.337 78.6611 241.29 76.443 242.815C75.3741 243.564 74.0914 243.591 73.156 242.976C72.0336 242.307 71.2319 240.942 71.419 239.525C71.5526 238.428 72.194 237.572 73.2629 237.17C74.5991 236.662 75.9887 236.448 77.4051 236.368C78.9551 236.288 80.5318 236.288 82.0817 236.234C82.2955 236.207 82.9102 236.234 82.9903 236.341Z"
        fill="#8963C6"
      />
      <path
        d="M50.254 247.764C50.3342 247.871 49.8799 248.46 49.6661 248.674C47.6351 250.76 45.9248 252.713 43.7067 254.238C42.6378 254.987 41.355 255.014 40.4197 254.399C39.2973 253.73 38.4956 252.366 38.6827 250.948C38.8163 249.851 39.4576 248.995 40.5266 248.594C41.8628 248.085 43.2524 247.871 44.6688 247.791C46.2187 247.711 47.7954 247.711 49.3454 247.657C49.5592 247.657 50.1738 247.684 50.254 247.764Z"
        fill="#6A449D"
      />
      <path
        d="M94.1876 239.498C94.2678 239.551 94.0005 240.033 93.8669 240.193C92.6644 241.825 91.6756 243.35 90.286 244.607C89.6179 245.223 88.736 245.383 88.0144 245.035C87.1593 244.688 86.4912 243.805 86.4645 242.815C86.4378 242.039 86.8119 241.397 87.5067 240.996C88.3886 240.514 89.3239 240.22 90.3127 240.033C91.3816 239.819 92.4506 239.658 93.5463 239.498C93.7066 239.471 94.1074 239.444 94.1876 239.498Z"
        fill="#B494F2"
      />
      <path
        d="M82.4025 248.406C82.4826 248.46 82.2154 248.941 82.0818 249.102C80.8792 250.734 79.8904 252.258 78.5008 253.516C77.8327 254.131 76.9508 254.292 76.2293 253.944C75.3741 253.596 74.706 252.713 74.6793 251.723C74.6526 250.948 75.0267 250.306 75.7215 249.904C76.6034 249.423 77.5388 249.128 78.5275 248.941C79.5965 248.727 80.6654 248.567 81.7611 248.406C81.8947 248.379 82.3223 248.353 82.4025 248.406Z"
        fill="#6A449D"
      />
      <path
        d="M67.4105 258.305C67.4907 258.358 67.2235 258.84 67.0898 259C65.8873 260.632 64.8985 262.157 63.5089 263.414C62.8408 264.029 61.9589 264.19 61.2374 263.842C60.3822 263.494 59.7141 262.612 59.6874 261.622C59.6607 260.846 60.0348 260.204 60.7296 259.803C61.6115 259.321 62.5468 259.027 63.5356 258.84C64.6045 258.626 65.6735 258.465 66.7692 258.305C66.9295 258.305 67.3304 258.251 67.4105 258.305Z"
        fill="#B494F2"
      />
      <path
        d="M27.8595 273.125C27.9397 273.179 27.6724 273.66 27.5388 273.821C26.3362 275.453 25.3475 276.977 23.9578 278.235C23.2897 278.85 22.4079 279.011 21.6863 278.663C20.8312 278.315 20.1631 277.432 20.1363 276.442C20.1096 275.667 20.4838 275.024 21.1786 274.623C22.0605 274.142 22.9958 273.847 23.9846 273.66C25.0535 273.446 26.1225 273.286 27.2181 273.125C27.3785 273.125 27.806 273.072 27.8595 273.125Z"
        fill="#6A449D"
      />
      <path
        d="M102.766 245.169C102.873 245.25 102.526 245.919 102.365 246.186C100.735 248.594 99.3722 250.814 97.4481 252.687C96.5127 253.596 95.2834 253.864 94.2412 253.409C93.0119 252.928 91.9964 251.75 91.9162 250.333C91.8628 249.209 92.3438 248.299 93.3326 247.684C94.5619 246.935 95.8981 246.48 97.2877 246.159C98.811 245.785 100.334 245.517 101.857 245.223C102.071 245.169 102.686 245.089 102.766 245.169Z"
        fill="#91E7F2"
      />
      <path
        d="M86.9455 257.02C87.0524 257.101 86.705 257.769 86.5446 258.037C84.9145 260.445 83.5516 262.665 81.6275 264.538C80.6922 265.447 79.4629 265.715 78.4207 265.26C77.1914 264.778 76.1759 263.601 76.0957 262.184C76.0422 261.06 76.5233 260.15 77.512 259.535C78.7413 258.786 80.0775 258.331 81.4671 258.01C82.9904 257.636 84.5137 257.368 86.0369 257.074C86.224 257.02 86.8386 256.913 86.9455 257.02Z"
        fill="#8963C6"
      />
      <path
        d="M84.7274 272.483C84.8343 272.563 84.5938 273.259 84.4602 273.526C83.2042 276.148 82.1887 278.529 80.5585 280.669C79.7835 281.712 78.581 282.14 77.4853 281.846C76.2026 281.552 75.0267 280.535 74.7328 279.144C74.519 278.047 74.8664 277.057 75.7483 276.335C76.8439 275.426 78.1267 274.784 79.4361 274.248C80.8792 273.66 82.3757 273.178 83.8188 272.643C84.0326 272.59 84.6205 272.403 84.7274 272.483Z"
        fill="#B494F2"
      />
      <path
        d="M104.984 254.987C105.064 255.041 104.904 255.549 104.797 255.736C103.888 257.555 103.167 259.214 102.018 260.712C101.456 261.434 100.628 261.729 99.8798 261.515C98.9712 261.301 98.1695 260.578 97.9824 259.615C97.8221 258.839 98.0893 258.144 98.7039 257.636C99.4789 256.994 100.361 256.565 101.296 256.191C102.312 255.79 103.354 255.469 104.369 255.094C104.503 255.067 104.904 254.934 104.984 254.987Z"
        fill="#B494F2"
      />
      <path
        d="M95.4437 263.681C95.5239 263.735 95.3636 264.243 95.2567 264.431C94.3481 266.25 93.6265 267.908 92.4774 269.406C91.9162 270.129 91.0878 270.423 90.3395 270.209C89.4309 269.995 88.6292 269.273 88.4421 268.31C88.2818 267.534 88.549 266.838 89.1637 266.33C89.9387 265.688 90.8205 265.26 91.7559 264.885C92.7714 264.484 93.8136 264.163 94.8291 263.788C94.9627 263.762 95.3636 263.628 95.4437 263.681Z"
        fill="#91E7F2"
      />
      <path
        d="M70.5372 288.935C70.6174 288.989 70.4571 289.497 70.3502 289.685C69.4416 291.504 68.72 293.162 67.5709 294.66C67.0097 295.383 66.1813 295.677 65.433 295.463C64.5244 295.249 63.7227 294.527 63.5356 293.564C63.3753 292.788 63.6425 292.092 64.2572 291.584C65.0322 290.942 65.914 290.514 66.8494 290.139C67.8649 289.738 68.9071 289.417 69.9226 289.042C70.0562 288.989 70.4571 288.882 70.5372 288.935Z"
        fill="#91E7F2"
      />
      <path
        d="M69.0941 273.232C69.1743 273.286 69.0139 273.794 68.907 273.981C67.9984 275.8 67.2769 277.459 66.1278 278.957C65.5666 279.679 64.7382 279.974 63.9899 279.76C63.0813 279.546 62.2796 278.823 62.0925 277.86C61.9322 277.084 62.1994 276.389 62.8141 275.881C63.589 275.238 64.4709 274.81 65.4063 274.436C66.4218 274.035 67.464 273.714 68.4795 273.339C68.5864 273.312 69.0139 273.205 69.0941 273.232Z"
        fill="#6A449D"
      />
      <path
        d="M55.6521 306.458C55.7323 306.512 55.5719 307.02 55.465 307.207C54.5564 309.027 53.8349 310.685 52.6858 312.183C52.1246 312.906 51.2961 313.2 50.5479 312.986C49.6393 312.772 48.8376 312.05 48.6505 311.086C48.4902 310.311 48.7574 309.615 49.372 309.107C50.147 308.465 51.0289 308.037 51.9642 307.662C52.9797 307.261 54.0219 306.94 55.0374 306.565C55.1711 306.539 55.5719 306.432 55.6521 306.458Z"
        fill="#B494F2"
      />
      <path
        d="M94.5617 278.904C94.6419 278.957 94.5083 279.465 94.4281 279.653C93.6799 281.552 93.0919 283.264 92.0764 284.843C91.5687 285.618 90.767 285.966 89.992 285.832C89.0834 285.699 88.2015 285.03 87.9343 284.094C87.7205 283.344 87.9075 282.622 88.4955 282.06C89.217 281.365 90.0722 280.857 90.954 280.429C91.9428 279.947 92.9316 279.519 93.9471 279.091C94.0807 279.011 94.4816 278.877 94.5617 278.904Z"
        fill="#B494F2"
      />
      <path
        d="M115.941 258.572C116.048 258.625 115.834 259.375 115.7 259.642C114.498 262.29 113.562 264.698 111.986 266.892C111.237 267.962 110.062 268.417 108.966 268.149C107.683 267.908 106.481 266.892 106.16 265.527C105.893 264.431 106.24 263.441 107.095 262.692C108.164 261.755 109.42 261.06 110.703 260.498C112.119 259.883 113.589 259.348 115.059 258.786C115.273 258.706 115.834 258.518 115.941 258.572Z"
        fill="#8963C6"
      />
      <path
        d="M86.9455 294.233C87.0523 294.286 86.892 295.035 86.7851 295.303C85.7696 298.031 84.9946 300.519 83.605 302.793C82.9102 303.917 81.7878 304.452 80.6654 304.265C79.3559 304.104 78.0999 303.194 77.6723 301.83C77.3517 300.76 77.5922 299.743 78.3939 298.914C79.4094 297.898 80.5852 297.149 81.8412 296.48C83.2309 295.757 84.6472 295.115 86.0636 294.447C86.2506 294.393 86.8118 294.152 86.9455 294.233Z"
        fill="#8963C6"
      />
      <path
        d="M118.212 269.567C118.292 269.594 118.212 270.129 118.159 270.316C117.598 272.269 117.17 274.035 116.288 275.693C115.861 276.496 115.086 276.951 114.311 276.87C113.402 276.817 112.467 276.255 112.092 275.319C111.799 274.596 111.932 273.874 112.467 273.259C113.108 272.51 113.91 271.921 114.765 271.386C115.7 270.824 116.662 270.316 117.598 269.781C117.731 269.728 118.105 269.54 118.212 269.567Z"
        fill="#B494F2"
      />
      <path
        d="M99.0513 298.513C99.1315 298.54 99.0513 299.075 98.9979 299.262C98.4367 301.215 98.0091 302.98 97.1272 304.639C96.6996 305.442 95.9247 305.896 95.1497 305.816C94.2411 305.763 93.3057 305.201 92.9316 304.265C92.6376 303.542 92.7713 302.82 93.3057 302.205C93.9471 301.456 94.7488 300.867 95.604 300.332C96.5393 299.77 97.5013 299.262 98.4367 298.727C98.597 298.673 98.9711 298.486 99.0513 298.513Z"
        fill="#91E7F2"
      />
      <path
        d="M76.6836 312.558C76.7638 312.585 76.6836 313.12 76.6302 313.307C76.069 315.26 75.6414 317.025 74.7595 318.684C74.332 319.486 73.557 319.941 72.782 319.861C71.8734 319.807 70.9381 319.246 70.5639 318.309C70.27 317.587 70.4036 316.865 70.9381 316.249C71.5794 315.5 72.3811 314.912 73.2363 314.377C74.1716 313.815 75.1337 313.307 76.069 312.772C76.2293 312.718 76.6035 312.531 76.6836 312.558Z"
        fill="#6A449D"
      />
      <path
        d="M128.34 271.788C128.474 271.841 128.367 272.59 128.287 272.884C127.565 275.693 127.058 278.261 125.909 280.669C125.347 281.846 124.278 282.515 123.129 282.435C121.82 282.408 120.457 281.632 119.896 280.321C119.468 279.305 119.602 278.262 120.323 277.352C121.205 276.228 122.328 275.346 123.503 274.57C124.813 273.714 126.149 272.938 127.485 272.109C127.672 272.028 128.207 271.761 128.34 271.788Z"
        fill="#B494F2"
      />
      <path
        d="M109.447 311.942C109.581 311.996 109.474 312.745 109.393 313.039C108.672 315.848 108.164 318.416 107.015 320.824C106.454 322.001 105.385 322.67 104.236 322.59C102.926 322.563 101.563 321.787 101.002 320.476C100.575 319.46 100.708 318.416 101.43 317.507C102.312 316.383 103.434 315.5 104.61 314.724C105.919 313.868 107.256 313.093 108.592 312.263C108.806 312.183 109.34 311.889 109.447 311.942Z"
        fill="#91E7F2"
      />
      <path
        d="M109.447 280.562C109.581 280.616 109.474 281.365 109.393 281.659C108.672 284.468 108.164 287.036 107.015 289.444C106.454 290.621 105.385 291.29 104.236 291.209C102.926 291.183 101.563 290.407 101.002 289.096C100.575 288.079 100.708 287.036 101.43 286.127C102.312 285.003 103.434 284.12 104.61 283.344C105.919 282.488 107.256 281.712 108.592 280.883C108.806 280.803 109.34 280.535 109.447 280.562Z"
        fill="#91E7F2"
      />
      <path
        d="M93.3057 319.192C93.4393 319.246 93.3324 319.995 93.2523 320.289C92.5307 323.098 92.023 325.666 90.8739 328.074C90.3127 329.251 89.2437 329.92 88.0946 329.84C86.7851 329.813 85.4222 329.037 84.861 327.726C84.4335 326.71 84.5671 325.666 85.2886 324.757C86.1705 323.633 87.2929 322.75 88.4687 321.975C89.7782 321.118 91.1144 320.343 92.4506 319.513C92.6376 319.433 93.1721 319.166 93.3057 319.192Z"
        fill="#6A449D"
      />
      <path
        d="M134.113 281.338C134.193 281.365 134.22 281.873 134.193 282.087C133.979 284.12 133.872 285.913 133.284 287.732C133.017 288.615 132.322 289.176 131.547 289.23C130.639 289.337 129.623 288.936 129.115 288.08C128.715 287.411 128.715 286.662 129.115 285.993C129.623 285.137 130.318 284.415 131.04 283.746C131.868 283.024 132.723 282.355 133.552 281.659C133.658 281.579 134.006 281.338 134.113 281.338Z"
        fill="#6A449D"
      />
      <path
        d="M107.603 332.167C107.683 332.194 107.71 332.702 107.683 332.916C107.469 334.949 107.362 336.742 106.774 338.561C106.507 339.444 105.812 340.005 105.037 340.059C104.129 340.166 103.113 339.765 102.606 338.908C102.205 338.24 102.205 337.491 102.606 336.822C103.113 335.966 103.808 335.243 104.53 334.575C105.358 333.852 106.213 333.184 107.042 332.488C107.175 332.408 107.496 332.167 107.603 332.167Z"
        fill="#8963C6"
      />
      <path
        d="M94.1876 342.948C94.2678 342.975 94.2945 343.483 94.2678 343.697C94.054 345.73 93.9471 347.523 93.3592 349.342C93.0919 350.225 92.3971 350.786 91.6221 350.84C90.7135 350.947 89.698 350.546 89.1903 349.69C88.7894 349.021 88.7894 348.272 89.1903 347.603C89.698 346.747 90.3929 346.025 91.1144 345.356C91.9428 344.633 92.798 343.965 93.6264 343.269C93.76 343.162 94.1074 342.921 94.1876 342.948Z"
        fill="#B494F2"
      />
      <path
        d="M121.9 310.792C121.98 310.819 122.007 311.327 121.98 311.541C121.767 313.574 121.66 315.367 121.072 317.186C120.804 318.069 120.11 318.63 119.335 318.684C118.426 318.791 117.411 318.39 116.903 317.534C116.502 316.865 116.502 316.116 116.903 315.447C117.411 314.591 118.105 313.868 118.827 313.2C119.655 312.477 120.511 311.809 121.339 311.113C121.473 311.033 121.793 310.792 121.9 310.792Z"
        fill="#B494F2"
      />
      <path
        d="M144.455 282.087C144.589 282.114 144.642 282.863 144.615 283.157C144.401 286.073 144.348 288.641 143.6 291.236C143.252 292.494 142.317 293.35 141.195 293.457C139.885 293.671 138.415 293.136 137.64 291.959C137.026 291.022 136.999 289.979 137.533 288.962C138.228 287.705 139.164 286.635 140.179 285.672C141.302 284.602 142.504 283.585 143.68 282.569C143.867 282.462 144.348 282.06 144.455 282.087Z"
        fill="#8963C6"
      />
      <path
        d="M128.741 325.88C128.875 325.907 128.928 326.656 128.902 326.95C128.688 329.866 128.634 332.435 127.886 335.03C127.539 336.287 126.603 337.143 125.481 337.25C124.172 337.464 122.702 336.929 121.927 335.752C121.312 334.816 121.285 333.772 121.82 332.756C122.515 331.498 123.45 330.428 124.465 329.465C125.588 328.395 126.79 327.378 127.966 326.362C128.153 326.255 128.608 325.88 128.741 325.88Z"
        fill="#8963C6"
      />
      <path
        d="M150.414 288.267C150.494 288.267 150.601 288.802 150.628 288.989C150.762 291.022 150.975 292.814 150.708 294.687C150.575 295.597 150.013 296.265 149.238 296.453C148.357 296.72 147.288 296.506 146.646 295.757C146.138 295.169 146.005 294.446 146.299 293.697C146.646 292.761 147.207 291.932 147.822 291.156C148.49 290.3 149.238 289.497 149.933 288.668C150.04 288.588 150.334 288.267 150.414 288.267Z"
        fill="#91E7F2"
      />
      <path
        d="M161.398 284.843C161.531 284.843 161.692 285.592 161.718 285.886C162.012 288.775 162.386 291.343 162.146 294.018C162.012 295.329 161.237 296.319 160.142 296.64C158.886 297.068 157.362 296.801 156.4 295.757C155.625 294.955 155.412 293.911 155.786 292.815C156.24 291.45 156.988 290.246 157.817 289.123C158.752 287.865 159.741 286.662 160.73 285.458C160.863 285.297 161.264 284.843 161.398 284.843Z"
        fill="#6A449D"
      />
      <path
        d="M153.3 316.838C153.434 316.838 153.594 317.587 153.621 317.881C153.915 320.77 154.289 323.339 154.049 326.014C153.915 327.325 153.14 328.315 152.044 328.636C150.788 329.064 149.265 328.796 148.303 327.753C147.528 326.95 147.314 325.907 147.688 324.81C148.143 323.446 148.891 322.242 149.719 321.118C150.655 319.861 151.643 318.657 152.632 317.453C152.766 317.293 153.167 316.838 153.3 316.838Z"
        fill="#B494F2"
      />
      <path
        d="M168.479 293.163C168.56 293.163 168.773 293.644 168.8 293.858C169.281 295.838 169.789 297.577 169.869 299.449C169.896 300.359 169.468 301.135 168.747 301.456C167.918 301.884 166.822 301.83 166.074 301.215C165.46 300.733 165.219 300.038 165.353 299.235C165.54 298.245 165.941 297.336 166.422 296.48C166.956 295.517 167.517 294.607 168.078 293.671C168.132 293.51 168.372 293.163 168.479 293.163Z"
        fill="#8963C6"
      />
      <path
        d="M133.284 360.47C133.365 360.47 133.445 361.006 133.445 361.22C133.418 363.253 133.471 365.072 133.071 366.918C132.884 367.8 132.242 368.442 131.467 368.576C130.559 368.764 129.516 368.469 128.928 367.667C128.474 367.051 128.394 366.302 128.741 365.58C129.169 364.67 129.784 363.895 130.452 363.172C131.2 362.37 132.002 361.621 132.777 360.872C132.884 360.765 133.204 360.47 133.284 360.47Z"
        fill="#8963C6"
      />
      <path
        d="M152.445 362.156C152.525 362.156 152.739 362.638 152.766 362.852C153.247 364.831 153.755 366.57 153.835 368.443C153.862 369.352 153.434 370.128 152.713 370.449C151.884 370.877 150.788 370.824 150.04 370.208C149.426 369.727 149.185 369.031 149.319 368.229C149.506 367.239 149.907 366.329 150.388 365.473C150.922 364.51 151.483 363.601 152.044 362.664C152.125 362.504 152.365 362.156 152.445 362.156Z"
        fill="#6A449D"
      />
      <path
        d="M164.337 324.542C164.417 324.542 164.631 325.024 164.658 325.238C165.139 327.218 165.647 328.957 165.727 330.829C165.754 331.739 165.326 332.515 164.604 332.836C163.776 333.264 162.68 333.21 161.932 332.595C161.317 332.113 161.077 331.418 161.211 330.615C161.398 329.625 161.799 328.716 162.28 327.86C162.814 326.897 163.375 325.987 163.936 325.051C164.017 324.917 164.257 324.542 164.337 324.542Z"
        fill="#8963C6"
      />
      <path
        d="M162.066 343.777C162.146 343.777 162.36 344.259 162.386 344.473C162.867 346.452 163.375 348.191 163.455 350.064C163.482 350.973 163.055 351.749 162.333 352.07C161.505 352.498 160.409 352.445 159.661 351.829C159.046 351.348 158.805 350.652 158.939 349.85C159.126 348.86 159.527 347.95 160.008 347.094C160.543 346.131 161.104 345.222 161.665 344.285C161.745 344.125 161.986 343.777 162.066 343.777Z"
        fill="#6A449D"
      />
      <path
        d="M178.501 290.3C178.634 290.273 178.928 290.969 179.008 291.263C179.81 294.072 180.612 296.533 180.826 299.208C180.933 300.519 180.318 301.616 179.329 302.124C178.18 302.766 176.603 302.766 175.481 301.91C174.599 301.241 174.198 300.278 174.359 299.128C174.572 297.71 175.107 296.399 175.721 295.115C176.416 293.724 177.191 292.36 177.94 290.995C178.046 290.835 178.367 290.327 178.501 290.3Z"
        fill="#91E7F2"
      />
      <path
        d="M80.0508 187.251C80.0776 187.331 79.6233 187.626 79.4362 187.706C77.5655 188.535 75.9621 189.338 74.1182 189.739C73.2363 189.926 72.4079 189.632 71.9536 188.963C71.3924 188.214 71.2588 187.144 71.7131 186.288C72.0872 185.592 72.7286 185.245 73.5303 185.245C74.519 185.245 75.4811 185.485 76.4431 185.806C77.4854 186.154 78.5009 186.555 79.5164 186.957C79.65 186.984 80.0241 187.144 80.0508 187.251Z"
        fill="#8963C6"
      />
      <path
        d="M187.052 276.442C187.186 276.416 187.587 277.138 187.694 277.459C188.843 280.455 189.965 283.077 190.473 286.02C190.714 287.437 190.152 288.721 189.083 289.364C187.881 290.166 186.171 290.327 184.834 289.497C183.792 288.855 183.258 287.812 183.338 286.555C183.445 284.976 183.899 283.478 184.46 282.034C185.102 280.428 185.823 278.877 186.518 277.298C186.625 277.084 186.919 276.496 187.052 276.442Z"
        fill="#8963C6"
      />
      <path
        d="M194.535 275.319C194.642 275.292 194.963 275.773 195.069 275.961C196.085 277.967 197.074 279.706 197.635 281.712C197.902 282.676 197.608 283.612 196.913 284.147C196.138 284.789 194.963 285.03 193.974 284.548C193.199 284.174 192.745 283.505 192.718 282.595C192.664 281.498 192.878 280.402 193.172 279.358C193.493 278.208 193.894 277.058 194.241 275.907C194.268 275.774 194.428 275.345 194.535 275.319Z"
        fill="#B494F2"
      />
      <path
        d="M199.693 321.546C199.773 321.493 200.147 321.948 200.254 322.162C201.403 324.088 202.525 325.746 203.22 327.726C203.568 328.689 203.327 329.625 202.686 330.214C201.964 330.91 200.788 331.231 199.773 330.829C198.971 330.508 198.49 329.866 198.383 328.983C198.25 327.887 198.383 326.79 198.597 325.72C198.838 324.543 199.131 323.365 199.425 322.215C199.479 322.028 199.586 321.6 199.693 321.546Z"
        fill="#B494F2"
      />
      <path
        d="M191.355 365.446C191.462 365.42 191.783 365.901 191.889 366.088C192.905 368.095 193.894 369.834 194.455 371.84C194.722 372.803 194.428 373.74 193.733 374.275C192.958 374.917 191.783 375.157 190.794 374.676C190.019 374.301 189.564 373.633 189.538 372.723C189.484 371.626 189.698 370.556 189.992 369.486C190.313 368.336 190.714 367.185 191.061 366.035C191.114 365.928 191.275 365.473 191.355 365.446Z"
        fill="#91E7F2"
      />
      <path
        d="M187.774 348.994C187.881 348.967 188.148 349.475 188.228 349.689C189.03 351.776 189.832 353.622 190.179 355.682C190.339 356.672 189.965 357.581 189.217 358.036C188.389 358.598 187.186 358.705 186.251 358.143C185.529 357.688 185.155 356.966 185.208 356.083C185.289 354.986 185.583 353.943 185.983 352.927C186.411 351.803 186.919 350.706 187.4 349.609C187.48 349.449 187.694 349.021 187.774 348.994Z"
        fill="#8963C6"
      />
      <path
        d="M202.525 355.923C202.632 355.896 202.953 356.377 203.06 356.565C204.075 358.571 205.064 360.31 205.625 362.316C205.893 363.279 205.599 364.216 204.904 364.751C204.129 365.393 202.953 365.634 201.964 365.152C201.189 364.778 200.735 364.109 200.708 363.199C200.655 362.102 200.869 361.006 201.162 359.962C201.483 358.812 201.884 357.662 202.231 356.511C202.285 356.404 202.445 355.949 202.525 355.923Z"
        fill="#6A449D"
      />
      <path
        d="M194.535 306.03C194.642 306.003 194.963 306.485 195.069 306.672C196.085 308.679 197.074 310.417 197.635 312.424C197.902 313.387 197.608 314.323 196.913 314.858C196.138 315.5 194.963 315.741 193.974 315.26C193.199 314.885 192.745 314.216 192.718 313.307C192.664 312.21 192.878 311.113 193.172 310.07C193.493 308.919 193.894 307.769 194.241 306.619C194.268 306.512 194.428 306.057 194.535 306.03Z"
        fill="#8963C6"
      />
      <path
        d="M197.047 339.898C197.154 339.872 197.475 340.353 197.582 340.54C198.784 342.948 199.666 344.473 200.147 346.292C200.414 347.255 200.12 348.191 199.426 348.726C198.651 349.369 197.475 349.609 196.486 349.128C195.711 348.753 195.257 348.084 195.23 347.175C195.176 346.078 195.39 345.008 195.684 343.938C196.005 342.788 196.406 341.637 196.753 340.487C196.78 340.38 196.94 339.925 197.047 339.898Z"
        fill="#6A449D"
      />
      <path
        d="M202.499 275.078C202.632 275.024 203.14 275.666 203.327 275.961C204.957 278.716 206.561 281.097 207.523 283.906C208.004 285.27 207.683 286.635 206.748 287.437C205.706 288.454 204.049 288.909 202.579 288.32C201.43 287.865 200.735 286.929 200.601 285.672C200.441 284.093 200.601 282.542 200.922 281.017C201.269 279.331 201.724 277.673 202.125 275.987C202.178 275.773 202.365 275.131 202.499 275.078Z"
        fill="#6A449D"
      />
      <path
        d="M207.015 306.03C207.149 305.977 207.656 306.619 207.843 306.913C209.474 309.669 211.077 312.049 212.039 314.858C212.52 316.223 212.199 317.587 211.264 318.39C210.222 319.406 208.565 319.861 207.095 319.272C205.946 318.818 205.251 317.881 205.118 316.624C204.957 315.046 205.118 313.494 205.438 311.969C205.786 310.284 206.24 308.625 206.641 306.94C206.694 306.726 206.881 306.084 207.015 306.03Z"
        fill="#8963C6"
      />
      <path
        d="M209.661 272.643C209.741 272.59 210.168 273.018 210.302 273.205C211.638 274.997 212.921 276.549 213.803 278.422C214.23 279.331 214.123 280.294 213.536 280.936C212.894 281.712 211.772 282.14 210.703 281.846C209.874 281.605 209.313 281.017 209.126 280.161C208.886 279.091 208.912 277.994 208.993 276.897C209.099 275.693 209.287 274.516 209.447 273.312C209.474 273.152 209.554 272.697 209.661 272.643Z"
        fill="#B494F2"
      />
      <path
        d="M225 345.703C225.08 345.65 225.508 346.078 225.641 346.265C226.978 348.057 228.26 349.609 229.142 351.482C229.57 352.391 229.463 353.354 228.875 353.996C228.234 354.772 227.111 355.2 226.042 354.906C225.214 354.665 224.653 354.077 224.466 353.221C224.225 352.151 224.252 351.054 224.332 349.957C224.439 348.753 224.626 347.576 224.786 346.372C224.84 346.212 224.92 345.757 225 345.703Z"
        fill="#B494F2"
      />
      <path
        d="M217.464 271.038C217.598 270.958 218.212 271.52 218.426 271.761C220.51 274.195 222.488 276.255 223.958 278.85C224.653 280.107 224.599 281.498 223.797 282.488C222.942 283.665 221.392 284.388 219.842 284.067C218.64 283.826 217.785 283.023 217.437 281.819C216.983 280.294 216.903 278.743 216.956 277.191C217.01 275.479 217.143 273.767 217.277 272.028C217.277 271.787 217.33 271.119 217.464 271.038Z"
        fill="#8963C6"
      />
      <path
        d="M245.925 349.823C246.031 349.743 246.539 350.225 246.726 350.439C248.49 352.472 250.147 354.237 251.376 356.404C251.964 357.448 251.911 358.625 251.243 359.454C250.521 360.444 249.212 361.059 247.929 360.792C246.913 360.578 246.192 359.909 245.898 358.892C245.524 357.608 245.444 356.297 245.47 354.987C245.497 353.542 245.631 352.097 245.738 350.653C245.764 350.439 245.818 349.877 245.925 349.823Z"
        fill="#91E7F2"
      />
      <path
        d="M224.091 267.4C224.171 267.32 224.652 267.668 224.813 267.828C226.443 269.353 227.966 270.664 229.169 272.349C229.757 273.179 229.81 274.142 229.329 274.891C228.821 275.774 227.779 276.389 226.71 276.282C225.855 276.202 225.214 275.72 224.866 274.891C224.439 273.874 224.278 272.777 224.171 271.707C224.065 270.504 224.065 269.3 224.011 268.096C224.011 267.935 224.011 267.481 224.091 267.4Z"
        fill="#91E7F2"
      />
      <path
        d="M231.494 264.457C231.601 264.35 232.296 264.805 232.563 264.992C235.048 267.025 237.346 268.711 239.244 271.012C240.152 272.135 240.313 273.526 239.725 274.596C239.083 275.907 237.667 276.897 236.117 276.843C234.888 276.817 233.926 276.175 233.338 275.024C232.643 273.606 232.269 272.082 232.055 270.53C231.815 268.818 231.654 267.106 231.467 265.394C231.44 265.233 231.387 264.564 231.494 264.457Z"
        fill="#6A449D"
      />
      <path
        d="M263.108 325.64C263.215 325.533 263.91 325.987 264.177 326.175C266.662 328.208 268.96 329.893 270.858 332.194C271.766 333.317 271.927 334.709 271.339 335.779C270.697 337.089 269.281 338.079 267.731 338.026C266.502 337.999 265.54 337.357 264.952 336.207C264.257 334.789 263.883 333.264 263.669 331.712C263.429 330 263.268 328.288 263.081 326.576C263.028 326.389 263.001 325.747 263.108 325.64Z"
        fill="#8963C6"
      />
      <path
        d="M237.4 259.722C237.453 259.642 237.988 259.883 238.175 260.017C240.045 261.247 241.782 262.264 243.252 263.708C243.974 264.404 244.188 265.367 243.84 266.169C243.493 267.133 242.584 267.908 241.488 267.989C240.633 268.042 239.912 267.694 239.431 266.945C238.843 266.009 238.469 264.992 238.201 263.922C237.907 262.745 237.667 261.568 237.426 260.391C237.426 260.284 237.346 259.803 237.4 259.722Z"
        fill="#8963C6"
      />
      <path
        d="M281.494 322.108C281.547 322.028 282.082 322.269 282.269 322.402C284.14 323.633 285.877 324.65 287.346 326.094C288.068 326.79 288.282 327.753 287.934 328.555C287.587 329.518 286.678 330.294 285.583 330.374C284.727 330.428 284.006 330.08 283.525 329.331C282.937 328.395 282.563 327.378 282.296 326.308C282.002 325.131 281.761 323.954 281.521 322.777C281.521 322.643 281.441 322.188 281.494 322.108Z"
        fill="#8963C6"
      />
      <path
        d="M244.188 255.549C244.268 255.415 245.043 255.736 245.337 255.897C248.143 257.475 250.708 258.732 252.953 260.685C254.049 261.622 254.449 262.959 254.049 264.136C253.648 265.527 252.418 266.758 250.895 266.972C249.666 267.159 248.597 266.705 247.849 265.661C246.913 264.377 246.299 262.959 245.791 261.461C245.257 259.829 244.802 258.171 244.321 256.512C244.268 256.325 244.107 255.656 244.188 255.549Z"
        fill="#8963C6"
      />
      <path
        d="M249.185 249.851C249.238 249.77 249.799 249.931 250.013 250.011C252.071 250.894 253.942 251.59 255.652 252.767C256.48 253.328 256.855 254.238 256.667 255.094C256.48 256.084 255.732 257.047 254.69 257.314C253.861 257.529 253.086 257.288 252.472 256.646C251.724 255.843 251.189 254.88 250.735 253.89C250.227 252.793 249.799 251.67 249.345 250.546C249.292 250.386 249.131 249.958 249.185 249.851Z"
        fill="#91E7F2"
      />
      <path
        d="M269.067 269.754C269.121 269.674 269.682 269.835 269.896 269.915C271.954 270.798 273.824 271.493 275.535 272.67C276.363 273.232 276.737 274.142 276.55 274.998C276.363 275.988 275.615 276.951 274.572 277.218C273.744 277.432 272.969 277.191 272.354 276.549C271.606 275.747 271.072 274.784 270.617 273.794C270.11 272.697 269.682 271.573 269.228 270.45C269.174 270.289 269.014 269.835 269.067 269.754Z"
        fill="#6A449D"
      />
      <path
        d="M255.144 244.554C255.198 244.42 256.026 244.581 256.32 244.714C259.34 245.758 262.092 246.587 264.658 248.085C265.887 248.808 266.529 250.065 266.341 251.295C266.181 252.74 265.192 254.158 263.723 254.64C262.547 255.014 261.424 254.773 260.516 253.89C259.367 252.794 258.511 251.483 257.763 250.118C256.935 248.594 256.213 247.042 255.465 245.49C255.331 245.303 255.064 244.688 255.144 244.554Z"
        fill="#B494F2"
      />
      <path
        d="M259.073 238.08C259.099 237.973 259.687 238.053 259.901 238.107C262.092 238.615 264.043 238.989 265.941 239.845C266.849 240.274 267.384 241.076 267.357 241.959C267.357 242.975 266.769 244.046 265.78 244.474C265.005 244.821 264.177 244.741 263.482 244.179C262.6 243.511 261.905 242.654 261.291 241.772C260.623 240.782 259.981 239.738 259.34 238.722C259.26 238.588 259.019 238.187 259.073 238.08Z"
        fill="#8963C6"
      />
      <path
        d="M283.044 255.415C283.071 255.308 283.658 255.389 283.872 255.442C286.064 255.95 288.014 256.325 289.912 257.181C290.82 257.609 291.355 258.412 291.328 259.294C291.328 260.311 290.74 261.381 289.751 261.809C288.976 262.157 288.148 262.077 287.453 261.515C286.571 260.846 285.877 259.99 285.262 259.107C284.594 258.117 283.952 257.074 283.311 256.057C283.231 255.924 283.017 255.522 283.044 255.415Z"
        fill="#B494F2"
      />
      <path
        d="M295.978 260.873C296.005 260.766 296.593 260.846 296.807 260.899C298.998 261.408 300.949 261.782 302.846 262.638C303.755 263.066 304.289 263.869 304.262 264.752C304.262 265.768 303.675 266.838 302.686 267.266C301.911 267.614 301.082 267.534 300.388 266.972C299.506 266.303 298.811 265.447 298.196 264.564C297.528 263.575 296.887 262.531 296.245 261.515C296.165 261.354 295.925 260.953 295.978 260.873Z"
        fill="#91E7F2"
      />
      <path
        d="M264.017 231.847C264.043 231.713 264.898 231.74 265.219 231.793C268.372 232.302 271.232 232.623 274.011 233.666C275.347 234.174 276.202 235.298 276.229 236.528C276.336 237.973 275.615 239.551 274.225 240.274C273.129 240.862 271.98 240.809 270.938 240.086C269.629 239.204 268.56 238.08 267.571 236.849C266.502 235.512 265.513 234.094 264.498 232.703C264.337 232.542 263.963 231.981 264.017 231.847Z"
        fill="#91E7F2"
      />
      <path
        d="M291.328 246.025C291.355 245.892 292.21 245.918 292.531 245.972C295.684 246.48 298.544 246.801 301.323 247.844C302.659 248.353 303.514 249.476 303.541 250.707C303.648 252.152 302.926 253.73 301.537 254.452C300.441 255.041 299.292 254.987 298.25 254.265C296.94 253.382 295.871 252.259 294.882 251.028C293.813 249.69 292.825 248.273 291.809 246.881C291.649 246.721 291.301 246.159 291.328 246.025Z"
        fill="#6A449D"
      />
      <path
        d="M266.742 224.784C266.769 224.677 267.33 224.651 267.571 224.651C269.816 224.784 271.82 224.784 273.824 225.319C274.786 225.56 275.454 226.283 275.588 227.165C275.775 228.155 275.374 229.305 274.466 229.921C273.744 230.402 272.942 230.456 272.167 230.028C271.179 229.519 270.35 228.797 269.602 228.021C268.747 227.165 267.972 226.256 267.17 225.373C267.036 225.239 266.742 224.891 266.742 224.784Z"
        fill="#B494F2"
      />
      <path
        d="M298.704 237.893C298.731 237.786 299.292 237.759 299.532 237.759C301.777 237.893 303.781 237.893 305.786 238.428C306.748 238.669 307.416 239.391 307.549 240.274C307.736 241.264 307.335 242.414 306.427 243.029C305.705 243.511 304.904 243.564 304.129 243.136C303.14 242.628 302.311 241.906 301.563 241.13C300.708 240.274 299.933 239.364 299.131 238.481C298.998 238.374 298.677 238 298.704 237.893Z"
        fill="#6A449D"
      />
      <path
        d="M314.097 234.522C314.124 234.415 314.685 234.388 314.925 234.388C317.17 234.522 319.174 234.522 321.179 235.057C322.141 235.298 322.809 236.02 322.942 236.903C323.129 237.893 322.728 239.043 321.82 239.658C321.098 240.14 320.297 240.193 319.522 239.765C318.533 239.257 317.704 238.535 316.956 237.759C316.101 236.903 315.326 235.993 314.524 235.11C314.391 234.977 314.07 234.629 314.097 234.522Z"
        fill="#B494F2"
      />
      <path
        d="M345.524 250.921C345.55 250.814 346.112 250.787 346.352 250.787C348.597 250.921 350.601 250.921 352.605 251.456C353.567 251.697 354.236 252.419 354.369 253.302C354.556 254.292 354.155 255.442 353.247 256.057C352.525 256.539 351.724 256.592 350.949 256.164C349.96 255.656 349.131 254.934 348.383 254.158C347.528 253.302 346.753 252.392 345.951 251.509C345.791 251.376 345.497 251.028 345.524 250.921Z"
        fill="#6A449D"
      />
      <path
        d="M270.537 217.748C270.537 217.614 271.366 217.481 271.713 217.481C274.92 217.427 277.779 217.267 280.692 217.802C282.108 218.069 283.124 219.032 283.364 220.236C283.712 221.654 283.284 223.313 282.055 224.276C281.093 225.051 279.944 225.185 278.768 224.677C277.325 224.035 276.069 223.099 274.893 222.082C273.61 220.932 272.381 219.728 271.152 218.524C270.991 218.39 270.537 217.909 270.537 217.748Z"
        fill="#6A449D"
      />
      <path
        d="M272.034 210.338C272.034 210.231 272.595 210.097 272.835 210.07C275.053 209.803 277.031 209.482 279.115 209.642C280.131 209.723 280.906 210.311 281.146 211.141C281.494 212.104 281.307 213.281 280.532 214.057C279.917 214.672 279.115 214.832 278.287 214.565C277.245 214.217 276.283 213.682 275.401 213.04C274.412 212.344 273.477 211.595 272.541 210.846C272.381 210.739 272.034 210.445 272.034 210.338Z"
        fill="#91E7F2"
      />
      <path
        d="M337.614 232.087C337.614 231.98 338.175 231.847 338.415 231.82C340.633 231.552 342.611 231.231 344.695 231.392C345.711 231.472 346.486 232.061 346.726 232.89C347.074 233.853 346.887 235.03 346.112 235.806C345.497 236.421 344.695 236.582 343.867 236.314C342.825 235.967 341.863 235.431 340.981 234.789C339.992 234.094 339.057 233.345 338.121 232.596C337.961 232.489 337.614 232.194 337.614 232.087Z"
        fill="#8963C6"
      />
      <path
        d="M303.942 211.435C303.942 211.328 304.503 211.194 304.743 211.167C306.962 210.9 308.939 210.579 311.024 210.739C312.039 210.82 312.814 211.408 313.054 212.237C313.402 213.201 313.215 214.378 312.44 215.153C311.825 215.769 311.024 215.929 310.195 215.662C309.153 215.314 308.191 214.779 307.309 214.137C306.32 213.441 305.385 212.692 304.45 211.943C304.316 211.836 303.942 211.542 303.942 211.435Z"
        fill="#91E7F2"
      />
      <path
        d="M301.537 223.955C301.537 223.848 302.098 223.714 302.338 223.687C304.556 223.42 306.534 223.099 308.618 223.259C309.634 223.34 310.409 223.928 310.649 224.757C310.997 225.721 310.81 226.898 310.035 227.673C309.42 228.289 308.618 228.449 307.79 228.182C306.748 227.834 305.786 227.299 304.904 226.657C303.915 225.961 302.98 225.212 302.044 224.463C301.884 224.356 301.537 224.035 301.537 223.955Z"
        fill="#B494F2"
      />
      <path
        d="M274.546 202.767C274.546 202.633 275.321 202.366 275.641 202.312C278.795 201.724 281.574 201.055 284.54 201.082C285.983 201.109 287.159 201.858 287.614 203.035C288.202 204.372 288.068 206.084 287.026 207.235C286.197 208.144 285.102 208.492 283.872 208.198C282.349 207.823 280.933 207.128 279.623 206.298C278.153 205.389 276.737 204.399 275.321 203.463C275.08 203.302 274.546 202.901 274.546 202.767Z"
        fill="#6A449D"
      />
      <path
        d="M308.752 200.306C308.752 200.172 309.527 199.905 309.848 199.851C313.001 199.263 315.78 198.594 318.747 198.621C320.19 198.647 321.366 199.396 321.82 200.574C322.408 201.911 322.274 203.623 321.232 204.774C320.404 205.683 319.308 206.031 318.079 205.737C316.555 205.362 315.139 204.667 313.83 203.837C312.36 202.928 310.943 201.938 309.527 201.002C309.313 200.841 308.779 200.44 308.752 200.306Z"
        fill="#8963C6"
      />
      <path
        d="M332.269 195.892C332.269 195.758 333.044 195.491 333.365 195.437C336.518 194.849 339.297 194.18 342.264 194.207C343.707 194.233 344.882 194.982 345.337 196.159C345.925 197.497 345.791 199.209 344.749 200.36C343.92 201.269 342.825 201.617 341.595 201.323C340.072 200.948 338.656 200.253 337.346 199.423C335.877 198.514 334.46 197.524 333.044 196.588C332.83 196.454 332.296 196.052 332.269 195.892Z"
        fill="#6A449D"
      />
      <path
        d="M274.706 195.197C274.679 195.09 275.214 194.849 275.428 194.795C277.565 194.153 279.463 193.484 281.521 193.27C282.536 193.163 283.391 193.618 283.792 194.394C284.3 195.277 284.327 196.481 283.685 197.363C283.177 198.059 282.429 198.38 281.547 198.273C280.452 198.139 279.436 197.738 278.447 197.283C277.352 196.775 276.309 196.186 275.241 195.625C275.134 195.544 274.733 195.304 274.706 195.197Z"
        fill="#91E7F2"
      />
      <path
        d="M311.424 190.167C311.398 190.06 311.932 189.819 312.146 189.765C314.284 189.123 316.181 188.455 318.239 188.241C319.254 188.134 320.109 188.588 320.51 189.364C321.018 190.247 321.045 191.451 320.403 192.334C319.896 193.029 319.147 193.35 318.266 193.243C317.17 193.11 316.154 192.708 315.166 192.253C314.07 191.745 313.028 191.157 311.959 190.595C311.852 190.488 311.451 190.274 311.424 190.167Z"
        fill="#6A449D"
      />
      <path
        d="M356.694 199.102C356.667 198.995 357.202 198.754 357.416 198.701C359.553 198.059 361.451 197.39 363.509 197.176C364.524 197.069 365.379 197.524 365.78 198.299C366.288 199.182 366.315 200.386 365.673 201.269C365.165 201.964 364.417 202.285 363.535 202.178C362.44 202.045 361.424 201.643 360.435 201.189C359.34 200.68 358.297 200.092 357.229 199.53C357.122 199.45 356.721 199.209 356.694 199.102Z"
        fill="#8963C6"
      />
      <path
        d="M195.898 287.652C196.032 287.598 196.432 288.24 196.566 288.508C197.822 291.129 199.078 293.403 199.746 296.025C200.067 297.282 199.666 298.486 198.757 299.155C197.742 299.984 196.192 300.252 194.936 299.61C193.947 299.101 193.386 298.219 193.359 297.068C193.333 295.65 193.6 294.259 194.027 292.895C194.482 291.397 195.016 289.925 195.497 288.454C195.551 288.267 195.791 287.705 195.898 287.652Z"
        fill="#91E7F2"
      />
      <path
        d="M188.816 324.837C188.95 324.783 189.351 325.425 189.484 325.693C190.74 328.315 191.996 330.589 192.664 333.21C192.985 334.468 192.584 335.671 191.676 336.34C190.66 337.17 189.11 337.437 187.854 336.795C186.865 336.287 186.304 335.404 186.277 334.254C186.251 332.836 186.518 331.445 186.946 330.08C187.4 328.582 187.934 327.111 188.415 325.639C188.469 325.452 188.709 324.864 188.816 324.837Z"
        fill="#91E7F2"
      />
      <path
        d="M205.331 293.189C205.412 293.136 205.759 293.537 205.866 293.724C206.988 295.41 208.084 296.881 208.779 298.62C209.126 299.476 208.966 300.332 208.405 300.894C207.763 301.563 206.748 301.91 205.812 301.589C205.064 301.349 204.61 300.787 204.476 299.984C204.316 298.994 204.369 298.005 204.53 297.015C204.69 295.945 204.931 294.875 205.118 293.805C205.171 293.644 205.251 293.243 205.331 293.189Z"
        fill="#B494F2"
      />
      <path
        d="M210.917 343.59C210.997 343.537 211.344 343.938 211.451 344.125C212.574 345.81 213.669 347.282 214.364 349.021C214.711 349.877 214.551 350.733 213.99 351.295C213.375 351.963 212.333 352.311 211.398 351.99C210.649 351.749 210.195 351.188 210.062 350.385C209.901 349.395 209.955 348.405 210.115 347.416C210.275 346.346 210.516 345.275 210.703 344.205C210.73 344.045 210.81 343.617 210.917 343.59Z"
        fill="#91E7F2"
      />
      <path
        d="M209.447 327.619C209.527 327.566 209.874 327.967 209.981 328.154C211.104 329.84 212.199 331.311 212.894 333.05C213.242 333.906 213.081 334.762 212.52 335.324C211.879 335.993 210.863 336.34 209.928 336.019C209.18 335.778 208.725 335.217 208.592 334.414C208.431 333.424 208.485 332.434 208.645 331.445C208.805 330.375 209.046 329.305 209.233 328.234C209.287 328.074 209.367 327.646 209.447 327.619Z"
        fill="#6A449D"
      />
      <path
        d="M218.132 308.518C218.212 308.465 218.56 308.866 218.666 309.053C219.789 310.738 220.885 312.21 221.579 313.949C221.927 314.805 221.766 315.661 221.205 316.223C220.564 316.891 219.548 317.239 218.613 316.918C217.865 316.677 217.41 316.116 217.277 315.313C217.117 314.323 217.17 313.333 217.33 312.344C217.491 311.273 217.731 310.203 217.918 309.133C217.972 308.973 218.052 308.572 218.132 308.518Z"
        fill="#6A449D"
      />
      <path
        d="M219.441 365.928C219.522 365.874 219.869 366.276 219.976 366.463C221.098 368.148 222.194 369.62 222.889 371.359C223.236 372.215 223.076 373.071 222.515 373.633C221.9 374.301 220.858 374.649 219.922 374.328C219.174 374.087 218.72 373.526 218.586 372.723C218.426 371.733 218.479 370.743 218.64 369.753C218.8 368.683 219.041 367.613 219.228 366.543C219.254 366.383 219.361 365.981 219.441 365.928Z"
        fill="#8963C6"
      />
      <path
        d="M213.963 288.053C214.07 287.999 214.605 288.534 214.765 288.775C216.475 291.129 218.079 293.162 219.201 295.624C219.735 296.801 219.548 298.058 218.773 298.887C217.918 299.877 216.448 300.412 215.086 300.011C214.017 299.69 213.322 298.914 213.081 297.791C212.814 296.399 212.841 294.955 213.001 293.564C213.188 292.012 213.455 290.487 213.696 288.936C213.723 288.722 213.83 288.133 213.963 288.053Z"
        fill="#91E7F2"
      />
      <path
        d="M221.045 324.543C221.152 324.489 221.686 325.024 221.847 325.265C223.557 327.619 225.16 329.652 226.283 332.113C226.817 333.291 226.63 334.548 225.855 335.377C225 336.367 223.53 336.902 222.167 336.501C221.098 336.18 220.404 335.404 220.163 334.28C219.896 332.889 219.923 331.445 220.083 330.054C220.27 328.502 220.537 326.977 220.778 325.425C220.804 325.185 220.938 324.596 221.045 324.543Z"
        fill="#8963C6"
      />
      <path
        d="M228.18 301.456C228.287 301.402 228.821 301.937 228.982 302.178C230.692 304.532 232.296 306.565 233.418 309.026C233.952 310.204 233.765 311.461 232.99 312.29C232.135 313.28 230.665 313.815 229.302 313.414C228.234 313.093 227.539 312.317 227.298 311.193C227.031 309.802 227.058 308.358 227.218 306.967C227.405 305.415 227.672 303.89 227.913 302.338C227.966 302.098 228.073 301.509 228.18 301.456Z"
        fill="#B494F2"
      />
      <path
        d="M223.423 286.929C223.503 286.876 223.904 287.197 224.065 287.357C225.481 288.829 226.79 290.086 227.806 291.664C228.287 292.44 228.287 293.323 227.833 293.965C227.325 294.741 226.363 295.249 225.401 295.089C224.626 294.982 224.065 294.5 223.797 293.724C223.477 292.788 223.37 291.798 223.343 290.808C223.316 289.711 223.343 288.615 223.37 287.545C223.316 287.411 223.343 286.983 223.423 286.929Z"
        fill="#6A449D"
      />
      <path
        d="M235.075 338.775C235.155 338.721 235.556 339.042 235.716 339.203C237.133 340.674 238.442 341.931 239.458 343.51C239.939 344.286 239.939 345.168 239.484 345.81C238.977 346.586 238.014 347.095 237.052 346.934C236.277 346.827 235.716 346.346 235.449 345.57C235.128 344.633 235.021 343.644 234.995 342.654C234.968 341.557 234.995 340.46 235.021 339.39C234.995 339.256 234.995 338.828 235.075 338.775Z"
        fill="#8963C6"
      />
      <path
        d="M233.498 322.67C233.578 322.616 233.979 322.937 234.139 323.098C235.556 324.569 236.865 325.827 237.881 327.405C238.362 328.181 238.362 329.063 237.907 329.706C237.4 330.481 236.438 330.99 235.476 330.829C234.701 330.722 234.139 330.241 233.872 329.465C233.552 328.528 233.445 327.539 233.418 326.549C233.391 325.452 233.418 324.355 233.445 323.285C233.391 323.151 233.418 322.723 233.498 322.67Z"
        fill="#91E7F2"
      />
      <path
        d="M229.944 281.017C230.024 280.963 230.425 281.284 230.585 281.445C232.002 282.916 233.311 284.174 234.327 285.752C234.808 286.528 234.808 287.411 234.353 288.053C233.846 288.829 232.884 289.337 231.921 289.176C231.146 289.069 230.585 288.588 230.318 287.812C229.997 286.876 229.89 285.886 229.864 284.896C229.837 283.799 229.864 282.702 229.89 281.632C229.864 281.498 229.89 281.07 229.944 281.017Z"
        fill="#B494F2"
      />
      <path
        d="M243.119 307.047C243.199 306.993 243.6 307.314 243.76 307.475C245.176 308.946 246.486 310.203 247.501 311.782C247.982 312.558 247.982 313.44 247.528 314.082C247.02 314.858 246.058 315.367 245.096 315.206C244.321 315.099 243.76 314.618 243.493 313.842C243.172 312.905 243.065 311.916 243.038 310.926C243.012 309.829 243.038 308.732 243.065 307.662C243.012 307.528 243.038 307.1 243.119 307.047Z"
        fill="#8963C6"
      />
      <path
        d="M235.743 291.852C235.85 291.771 236.438 292.226 236.652 292.44C238.736 294.473 240.687 296.186 242.21 298.406C242.958 299.476 242.985 300.76 242.37 301.696C241.702 302.82 240.339 303.623 238.923 303.435C237.827 303.302 236.972 302.66 236.571 301.589C236.064 300.252 235.85 298.861 235.77 297.443C235.69 295.891 235.69 294.313 235.636 292.761C235.636 292.547 235.663 291.932 235.743 291.852Z"
        fill="#B494F2"
      />
      <path
        d="M247.181 324.623C247.288 324.542 247.876 324.997 248.089 325.211C250.174 327.244 252.125 328.957 253.648 331.177C254.396 332.247 254.423 333.531 253.808 334.467C253.14 335.591 251.777 336.394 250.361 336.206C249.265 336.073 248.41 335.431 248.009 334.36C247.501 333.023 247.288 331.632 247.207 330.214C247.127 328.662 247.127 327.084 247.074 325.532C247.074 325.318 247.074 324.703 247.181 324.623Z"
        fill="#6A449D"
      />
      <path
        d="M238.923 280.642C238.976 280.562 239.457 280.83 239.618 280.963C241.248 282.167 242.771 283.157 244.027 284.548C244.642 285.217 244.802 286.1 244.455 286.822C244.107 287.678 243.225 288.347 242.237 288.374C241.462 288.401 240.82 288.026 240.419 287.33C239.938 286.448 239.644 285.511 239.457 284.521C239.244 283.451 239.083 282.381 238.923 281.284C238.896 281.124 238.843 280.723 238.923 280.642Z"
        fill="#91E7F2"
      />
      <path
        d="M247.822 292.44C247.875 292.36 248.356 292.627 248.517 292.761C250.147 293.965 251.67 294.955 252.926 296.346C253.541 297.015 253.701 297.897 253.354 298.62C253.006 299.476 252.125 300.145 251.136 300.171C250.361 300.198 249.719 299.824 249.319 299.128C248.837 298.245 248.544 297.309 248.356 296.319C248.143 295.249 247.982 294.179 247.822 293.082C247.795 292.948 247.742 292.52 247.822 292.44Z"
        fill="#6A449D"
      />
      <path
        d="M257.71 307.448C257.763 307.368 258.244 307.635 258.405 307.769C260.035 308.973 261.558 309.963 262.814 311.354C263.429 312.022 263.589 312.905 263.242 313.628C262.894 314.484 262.012 315.152 261.024 315.179C260.249 315.206 259.607 314.831 259.206 314.136C258.725 313.253 258.431 312.317 258.244 311.327C258.03 310.257 257.87 309.187 257.71 308.09C257.71 307.929 257.656 307.528 257.71 307.448Z"
        fill="#8963C6"
      />
      <path
        d="M272.515 347.041C272.568 346.961 273.049 347.228 273.21 347.362C274.84 348.566 276.363 349.556 277.619 350.947C278.234 351.616 278.394 352.499 278.047 353.221C277.699 354.077 276.817 354.746 275.828 354.772C275.053 354.799 274.412 354.425 274.011 353.729C273.53 352.846 273.236 351.91 273.049 350.92C272.835 349.85 272.675 348.78 272.515 347.683C272.515 347.523 272.461 347.095 272.515 347.041Z"
        fill="#B494F2"
      />
      <path
        d="M244.588 272.055C244.669 271.948 245.337 272.296 245.604 272.456C248.009 274.088 250.227 275.453 252.098 277.379C253.006 278.315 253.274 279.546 252.819 280.589C252.338 281.82 251.162 282.836 249.746 282.916C248.624 282.97 247.715 282.488 247.1 281.499C246.352 280.268 245.898 278.93 245.577 277.539C245.203 276.014 244.936 274.489 244.642 272.965C244.588 272.751 244.508 272.135 244.588 272.055Z"
        fill="#B494F2"
      />
      <path
        d="M256.4 287.892C256.48 287.785 257.149 288.133 257.416 288.293C259.821 289.925 262.039 291.29 263.91 293.216C264.818 294.152 265.085 295.383 264.631 296.426C264.15 297.657 262.974 298.673 261.558 298.754C260.436 298.807 259.527 298.325 258.912 297.336C258.164 296.105 257.71 294.767 257.389 293.376C257.015 291.851 256.748 290.327 256.454 288.802C256.427 288.614 256.32 287.999 256.4 287.892Z"
        fill="#8963C6"
      />
      <path
        d="M271.873 290.113C271.954 290.006 272.648 290.246 272.916 290.38C275.535 291.637 277.913 292.654 280.051 294.286C281.093 295.062 281.521 296.266 281.227 297.362C280.933 298.646 279.917 299.824 278.528 300.118C277.432 300.332 276.443 299.984 275.722 299.101C274.813 298.004 274.172 296.72 273.637 295.41C273.049 293.965 272.568 292.467 272.034 291.022C271.98 290.808 271.793 290.22 271.873 290.113Z"
        fill="#B494F2"
      />
      <path
        d="M254.396 269.835C254.449 269.754 254.957 269.915 255.144 270.022C256.962 270.931 258.618 271.654 260.115 272.804C260.836 273.366 261.13 274.195 260.917 274.944C260.703 275.854 259.981 276.656 259.019 276.844C258.244 277.004 257.549 276.737 257.042 276.121C256.4 275.346 255.973 274.463 255.599 273.526C255.198 272.51 254.877 271.467 254.503 270.45C254.476 270.316 254.343 269.915 254.396 269.835Z"
        fill="#91E7F2"
      />
      <path
        d="M263.081 279.385C263.135 279.305 263.642 279.465 263.829 279.572C265.647 280.482 267.304 281.204 268.8 282.355C269.522 282.916 269.816 283.746 269.602 284.495C269.388 285.404 268.666 286.207 267.704 286.394C266.929 286.555 266.235 286.287 265.727 285.672C265.086 284.896 264.658 284.013 264.284 283.077C263.883 282.06 263.562 281.017 263.188 280C263.161 279.867 263.028 279.465 263.081 279.385Z"
        fill="#6A449D"
      />
      <path
        d="M288.282 304.318C288.335 304.238 288.843 304.398 289.03 304.505C290.847 305.415 292.504 306.137 294 307.287C294.722 307.849 295.016 308.678 294.802 309.427C294.588 310.337 293.867 311.14 292.905 311.327C292.13 311.487 291.435 311.22 290.927 310.605C290.286 309.829 289.858 308.946 289.484 308.01C289.083 306.993 288.763 305.95 288.388 304.933C288.362 304.799 288.255 304.398 288.282 304.318Z"
        fill="#6A449D"
      />
      <path
        d="M272.622 305.763C272.675 305.682 273.183 305.843 273.37 305.95C275.187 306.859 276.844 307.582 278.34 308.732C279.062 309.294 279.356 310.123 279.142 310.872C278.928 311.782 278.207 312.584 277.245 312.772C276.47 312.932 275.775 312.665 275.267 312.049C274.626 311.274 274.198 310.391 273.824 309.454C273.423 308.438 273.103 307.395 272.728 306.378C272.702 306.271 272.568 305.843 272.622 305.763Z"
        fill="#91E7F2"
      />
      <path
        d="M278.287 280.268C278.341 280.188 278.848 280.321 279.035 280.402C280.933 281.151 282.643 281.739 284.22 282.756C284.995 283.264 285.342 284.067 285.208 284.843C285.075 285.752 284.407 286.635 283.471 286.902C282.723 287.116 282.002 286.929 281.44 286.341C280.746 285.618 280.238 284.762 279.81 283.879C279.329 282.89 278.902 281.9 278.474 280.883C278.394 280.749 278.234 280.348 278.287 280.268Z"
        fill="#B494F2"
      />
      <path
        d="M257.977 258.866C258.03 258.759 258.779 258.973 259.046 259.107C261.692 260.311 264.097 261.247 266.288 262.825C267.357 263.575 267.811 264.752 267.544 265.848C267.304 267.133 266.288 268.336 264.925 268.657C263.829 268.925 262.841 268.577 262.092 267.721C261.157 266.651 260.462 265.394 259.901 264.11C259.286 262.692 258.752 261.22 258.191 259.749C258.111 259.535 257.924 258.973 257.977 258.866Z"
        fill="#91E7F2"
      />
      <path
        d="M268.96 256.593C268.987 256.512 269.522 256.593 269.709 256.646C271.659 257.208 273.423 257.636 275.08 258.519C275.882 258.947 276.336 259.723 276.256 260.498C276.202 261.408 275.641 262.344 274.706 262.719C273.984 263.013 273.263 262.879 272.648 262.344C271.9 261.702 271.312 260.9 270.778 260.044C270.216 259.107 269.709 258.144 269.174 257.208C269.094 257.074 268.907 256.7 268.96 256.593Z"
        fill="#8963C6"
      />
      <path
        d="M271.178 246.453C271.232 246.32 271.98 246.427 272.274 246.507C275.08 247.229 277.646 247.737 280.051 248.888C281.226 249.45 281.895 250.52 281.814 251.67C281.788 252.981 281.013 254.345 279.703 254.907C278.688 255.335 277.646 255.201 276.737 254.479C275.615 253.596 274.733 252.473 273.958 251.295C273.103 249.985 272.328 248.647 271.499 247.309C271.419 247.122 271.125 246.587 271.178 246.453Z"
        fill="#6A449D"
      />
      <path
        d="M311.291 265.367C311.344 265.233 312.092 265.34 312.386 265.421C315.192 266.143 317.758 266.651 320.163 267.802C321.339 268.363 322.007 269.433 321.927 270.584C321.9 271.895 321.125 273.259 319.815 273.821C318.8 274.249 317.758 274.115 316.849 273.393C315.727 272.51 314.845 271.386 314.07 270.209C313.215 268.898 312.44 267.561 311.611 266.223C311.531 266.009 311.237 265.474 311.291 265.367Z"
        fill="#B494F2"
      />
      <path
        d="M279.944 265.367C279.997 265.233 280.746 265.34 281.04 265.421C283.846 266.143 286.411 266.651 288.816 267.802C289.992 268.363 290.66 269.433 290.58 270.584C290.553 271.895 289.778 273.259 288.469 273.821C287.453 274.249 286.411 274.115 285.502 273.393C284.38 272.51 283.498 271.386 282.723 270.209C281.868 268.898 281.093 267.561 280.265 266.223C280.184 266.009 279.891 265.474 279.944 265.367Z"
        fill="#91E7F2"
      />
      <path
        d="M280.719 240.675C280.746 240.595 281.253 240.568 281.467 240.595C283.498 240.809 285.289 240.916 287.106 241.504C287.988 241.772 288.549 242.467 288.602 243.243C288.709 244.153 288.308 245.169 287.453 245.677C286.785 246.079 286.037 246.079 285.369 245.677C284.514 245.169 283.792 244.474 283.124 243.751C282.402 242.922 281.734 242.066 281.04 241.237C280.959 241.13 280.719 240.782 280.719 240.675Z"
        fill="#B494F2"
      />
      <path
        d="M331.494 267.213C331.521 267.133 332.028 267.106 332.242 267.133C334.273 267.347 336.064 267.454 337.881 268.042C338.763 268.31 339.324 269.005 339.377 269.781C339.484 270.691 339.083 271.707 338.228 272.215C337.56 272.617 336.812 272.617 336.144 272.215C335.289 271.707 334.567 271.012 333.899 270.289C333.177 269.46 332.509 268.604 331.814 267.775C331.734 267.641 331.467 267.32 331.494 267.213Z"
        fill="#8963C6"
      />
      <path
        d="M310.142 252.901C310.168 252.82 310.676 252.794 310.89 252.82C312.921 253.034 314.711 253.141 316.529 253.73C317.41 253.997 317.972 254.693 318.025 255.469C318.132 256.378 317.731 257.395 316.876 257.903C316.208 258.304 315.46 258.304 314.792 257.903C313.936 257.395 313.215 256.699 312.547 255.977C311.825 255.148 311.157 254.292 310.462 253.462C310.382 253.329 310.142 253.008 310.142 252.901Z"
        fill="#8963C6"
      />
      <path
        d="M281.467 230.322C281.494 230.188 282.242 230.134 282.536 230.161C285.449 230.375 288.014 230.429 290.607 231.178C291.863 231.526 292.718 232.462 292.825 233.585C293.038 234.896 292.504 236.368 291.328 237.143C290.393 237.759 289.351 237.786 288.335 237.25C287.079 236.555 286.01 235.619 285.048 234.602C283.979 233.478 282.964 232.275 281.948 231.098C281.814 230.91 281.44 230.429 281.467 230.322Z"
        fill="#8963C6"
      />
      <path
        d="M325.214 246.052C325.241 245.918 325.989 245.865 326.283 245.891C329.196 246.105 331.761 246.159 334.353 246.908C335.609 247.256 336.464 248.192 336.571 249.316C336.785 250.627 336.251 252.098 335.075 252.874C334.14 253.489 333.097 253.516 332.082 252.981C330.826 252.285 329.757 251.349 328.795 250.332C327.726 249.209 326.71 248.005 325.695 246.828C325.588 246.641 325.187 246.186 325.214 246.052Z"
        fill="#91E7F2"
      />
      <path
        d="M287.64 224.356C287.64 224.276 288.175 224.169 288.362 224.142C290.393 224.008 292.183 223.794 294.054 224.062C294.963 224.196 295.631 224.757 295.818 225.533C296.085 226.416 295.871 227.486 295.123 228.128C294.535 228.637 293.814 228.77 293.065 228.476C292.13 228.128 291.301 227.566 290.527 226.951C289.671 226.282 288.87 225.533 288.041 224.838C287.934 224.731 287.64 224.436 287.64 224.356Z"
        fill="#91E7F2"
      />
      <path
        d="M284.22 213.361C284.22 213.227 284.968 213.067 285.262 213.04C288.148 212.746 290.714 212.371 293.386 212.612C294.695 212.746 295.684 213.521 296.005 214.618C296.432 215.876 296.165 217.401 295.123 218.364C294.321 219.139 293.279 219.353 292.183 218.979C290.82 218.524 289.618 217.775 288.496 216.946C287.239 216.009 286.037 215.02 284.834 214.03C284.674 213.896 284.22 213.495 284.22 213.361Z"
        fill="#B494F2"
      />
      <path
        d="M316.181 221.467C316.181 221.333 316.929 221.173 317.223 221.146C320.11 220.852 322.675 220.477 325.347 220.718C326.657 220.852 327.646 221.627 327.966 222.724C328.394 223.982 328.127 225.506 327.084 226.47C326.283 227.245 325.241 227.459 324.145 227.085C322.782 226.63 321.579 225.881 320.457 225.052C319.201 224.115 317.998 223.126 316.796 222.136C316.635 222.002 316.181 221.601 316.181 221.467Z"
        fill="#6A449D"
      />
      <path
        d="M292.504 206.272C292.504 206.192 292.985 205.978 293.199 205.951C295.176 205.469 296.913 204.961 298.784 204.881C299.692 204.854 300.467 205.282 300.788 206.004C301.216 206.834 301.162 207.931 300.548 208.68C300.067 209.295 299.372 209.536 298.57 209.402C297.581 209.215 296.673 208.813 295.818 208.332C294.856 207.797 293.947 207.235 293.012 206.673C292.878 206.62 292.531 206.379 292.504 206.272Z"
        fill="#8963C6"
      />
      <path
        d="M359.768 241.504C359.768 241.424 360.302 241.344 360.516 241.344C362.547 241.371 364.364 241.317 366.208 241.718C367.09 241.906 367.731 242.548 367.865 243.323C368.052 244.233 367.758 245.276 366.956 245.865C366.342 246.32 365.593 246.4 364.872 246.052C363.963 245.624 363.188 245.009 362.467 244.34C361.665 243.591 360.917 242.788 360.168 242.013C360.035 241.906 359.768 241.585 359.768 241.504Z"
        fill="#B494F2"
      />
      <path
        d="M361.424 222.323C361.424 222.243 361.905 222.029 362.119 222.002C364.097 221.521 365.834 221.012 367.704 220.932C368.613 220.905 369.388 221.333 369.709 222.056C370.136 222.885 370.083 223.982 369.468 224.731C368.987 225.346 368.292 225.587 367.491 225.453C366.502 225.266 365.593 224.865 364.738 224.383C363.776 223.848 362.867 223.286 361.932 222.724C361.798 222.644 361.451 222.403 361.424 222.323Z"
        fill="#6A449D"
      />
      <path
        d="M323.878 210.418C323.878 210.338 324.359 210.124 324.572 210.097C326.55 209.616 328.287 209.108 330.158 209.027C331.066 209.001 331.841 209.429 332.162 210.151C332.589 210.98 332.536 212.077 331.921 212.826C331.44 213.441 330.745 213.682 329.944 213.548C328.955 213.361 328.046 212.96 327.191 212.478C326.229 211.943 325.321 211.381 324.385 210.82C324.252 210.739 323.878 210.499 323.878 210.418Z"
        fill="#91E7F2"
      />
      <path
        d="M343.092 212.692C343.092 212.612 343.573 212.398 343.787 212.371C345.764 211.89 347.501 211.381 349.372 211.301C350.281 211.274 351.056 211.702 351.376 212.425C351.804 213.254 351.751 214.351 351.136 215.1C350.655 215.715 349.96 215.956 349.158 215.822C348.17 215.635 347.261 215.233 346.406 214.752C345.444 214.217 344.535 213.655 343.6 213.093C343.439 213.013 343.092 212.772 343.092 212.692Z"
        fill="#B494F2"
      />
      <path
        d="M289.671 196.24C289.645 196.106 290.339 195.812 290.633 195.732C293.439 194.929 295.898 194.126 298.57 193.912C299.88 193.805 300.975 194.421 301.483 195.411C302.125 196.561 302.125 198.139 301.269 199.263C300.601 200.146 299.639 200.547 298.49 200.386C297.074 200.172 295.764 199.637 294.482 199.022C293.092 198.326 291.729 197.551 290.366 196.802C290.206 196.695 289.671 196.374 289.671 196.24Z"
        fill="#91E7F2"
      />
      <path
        d="M186.705 294.794C186.785 294.768 187.079 295.222 187.159 295.41C187.988 297.282 188.789 298.887 189.19 300.733C189.377 301.616 189.083 302.445 188.415 302.9C187.667 303.462 186.598 303.596 185.743 303.141C185.048 302.766 184.701 302.124 184.701 301.322C184.701 300.332 184.941 299.369 185.262 298.406C185.609 297.363 186.01 296.346 186.411 295.329C186.464 295.196 186.625 294.821 186.705 294.794Z"
        fill="#B494F2"
      />
      <path
        d="M275.588 187.358C275.561 187.224 276.283 186.823 276.603 186.716C279.596 185.566 282.215 184.442 285.155 183.934C286.571 183.693 287.854 184.255 288.495 185.325C289.297 186.529 289.457 188.241 288.629 189.578C287.988 190.622 286.945 191.157 285.689 191.076C284.113 190.969 282.616 190.515 281.173 189.953C279.57 189.311 278.02 188.589 276.443 187.893C276.229 187.786 275.615 187.492 275.588 187.358Z"
        fill="#8963C6"
      />
      <path
        d="M274.439 179.867C274.412 179.76 274.893 179.439 275.08 179.332C277.084 178.316 278.821 177.326 280.826 176.764C281.788 176.497 282.723 176.791 283.257 177.486C283.899 178.262 284.139 179.439 283.658 180.429C283.284 181.205 282.616 181.66 281.707 181.686C280.612 181.74 279.516 181.526 278.474 181.232C277.325 180.911 276.176 180.509 275.027 180.162C274.92 180.135 274.465 179.974 274.439 179.867Z"
        fill="#B494F2"
      />
      <path
        d="M320.644 174.704C320.591 174.624 321.045 174.249 321.259 174.142C323.183 172.992 324.84 171.869 326.817 171.173C327.779 170.825 328.714 171.066 329.302 171.708C329.997 172.43 330.318 173.607 329.917 174.624C329.596 175.427 328.955 175.908 328.073 176.015C326.977 176.149 325.882 176.015 324.813 175.801C323.637 175.56 322.461 175.266 321.312 174.972C321.125 174.918 320.671 174.785 320.644 174.704Z"
        fill="#B494F2"
      />
      <path
        d="M364.471 183.051C364.444 182.944 364.925 182.623 365.112 182.516C367.116 181.499 368.853 180.509 370.858 179.948C371.82 179.68 372.755 179.974 373.29 180.67C373.931 181.446 374.172 182.623 373.69 183.613C373.316 184.389 372.648 184.843 371.74 184.87C370.644 184.924 369.548 184.71 368.506 184.415C367.357 184.094 366.208 183.693 365.059 183.345C364.952 183.292 364.524 183.131 364.471 183.051Z"
        fill="#91E7F2"
      />
      <path
        d="M348.036 186.636C348.009 186.529 348.517 186.261 348.731 186.181C350.815 185.378 352.659 184.576 354.717 184.228C355.706 184.067 356.614 184.442 357.069 185.191C357.63 186.02 357.737 187.224 357.175 188.16C356.721 188.883 356 189.257 355.118 189.204C354.022 189.123 352.98 188.829 351.964 188.428C350.842 188 349.746 187.492 348.651 187.01C348.49 186.93 348.063 186.716 348.036 186.636Z"
        fill="#91E7F2"
      />
      <path
        d="M354.984 171.842C354.957 171.735 355.438 171.414 355.625 171.307C357.63 170.29 359.367 169.3 361.371 168.739C362.333 168.471 363.268 168.765 363.803 169.461C364.444 170.237 364.685 171.414 364.204 172.404C363.829 173.18 363.161 173.634 362.253 173.661C361.157 173.715 360.088 173.501 359.019 173.206C357.87 172.885 356.721 172.484 355.572 172.136C355.438 172.109 355.011 171.949 354.984 171.842Z"
        fill="#6A449D"
      />
      <path
        d="M305.144 179.867C305.118 179.76 305.599 179.439 305.786 179.332C307.79 178.316 309.527 177.326 311.531 176.764C312.493 176.497 313.429 176.791 313.963 177.486C314.605 178.262 314.845 179.439 314.364 180.429C313.99 181.205 313.322 181.66 312.413 181.686C311.318 181.74 310.222 181.526 309.18 181.232C308.031 180.911 306.881 180.509 305.732 180.162C305.599 180.135 305.171 179.974 305.144 179.867Z"
        fill="#91E7F2"
      />
      <path
        d="M338.976 177.353C338.95 177.246 339.431 176.925 339.618 176.818C342.023 175.614 343.546 174.731 345.363 174.25C346.325 173.982 347.261 174.276 347.795 174.972C348.436 175.748 348.677 176.925 348.196 177.915C347.822 178.691 347.154 179.145 346.245 179.172C345.149 179.226 344.081 179.012 343.012 178.717C341.862 178.396 340.713 177.995 339.564 177.647C339.431 177.62 339.003 177.46 338.976 177.353Z"
        fill="#8963C6"
      />
      <path
        d="M274.225 171.895C274.172 171.762 274.813 171.253 275.107 171.066C277.86 169.434 280.238 167.829 283.044 166.866C284.407 166.385 285.77 166.706 286.571 167.642C287.587 168.685 288.041 170.344 287.453 171.815C286.999 172.966 286.064 173.661 284.808 173.795C283.231 173.955 281.681 173.795 280.158 173.474C278.474 173.126 276.817 172.671 275.134 172.27C274.92 172.216 274.279 172.029 274.225 171.895Z"
        fill="#91E7F2"
      />
      <path
        d="M305.144 167.374C305.091 167.24 305.732 166.732 306.026 166.545C308.779 164.913 311.157 163.308 313.963 162.345C315.326 161.863 316.689 162.184 317.491 163.121C318.506 164.164 318.961 165.823 318.373 167.294C317.918 168.444 316.983 169.14 315.727 169.274C314.15 169.434 312.6 169.274 311.077 168.953C309.393 168.605 307.737 168.15 306.053 167.749C305.839 167.695 305.198 167.508 305.144 167.374Z"
        fill="#8963C6"
      />
      <path
        d="M271.793 164.726C271.74 164.646 272.167 164.217 272.354 164.084C274.145 162.746 275.695 161.462 277.566 160.579C278.474 160.151 279.436 160.258 280.078 160.847C280.853 161.489 281.28 162.612 280.986 163.682C280.746 164.512 280.158 165.074 279.303 165.261C278.234 165.502 277.138 165.475 276.042 165.395C274.84 165.288 273.664 165.1 272.461 164.94C272.301 164.886 271.847 164.806 271.793 164.726Z"
        fill="#6A449D"
      />
      <path
        d="M344.749 149.37C344.695 149.29 345.123 148.862 345.31 148.728C347.1 147.39 348.65 146.106 350.521 145.223C351.429 144.795 352.392 144.902 353.033 145.491C353.808 146.133 354.235 147.257 353.942 148.327C353.701 149.156 353.113 149.718 352.258 149.905C351.189 150.146 350.093 150.119 348.998 150.039C347.795 149.932 346.619 149.745 345.417 149.584C345.283 149.531 344.802 149.45 344.749 149.37Z"
        fill="#6A449D"
      />
      <path
        d="M270.19 156.914C270.11 156.78 270.671 156.165 270.911 155.951C273.343 153.864 275.401 151.885 277.993 150.413C279.249 149.718 280.639 149.771 281.628 150.574C282.803 151.43 283.525 152.982 283.204 154.533C282.964 155.737 282.162 156.593 280.959 156.941C279.436 157.396 277.886 157.476 276.336 157.422C274.626 157.369 272.916 157.235 271.179 157.101C270.938 157.101 270.27 157.021 270.19 156.914Z"
        fill="#91E7F2"
      />
      <path
        d="M348.864 128.423C348.784 128.316 349.265 127.808 349.479 127.621C351.51 125.855 353.274 124.196 355.438 122.966C356.48 122.377 357.656 122.431 358.485 123.1C359.473 123.822 360.088 125.133 359.821 126.417C359.607 127.433 358.939 128.156 357.923 128.45C356.641 128.825 355.331 128.905 354.022 128.878C352.579 128.851 351.136 128.718 349.693 128.611C349.506 128.584 348.944 128.53 348.864 128.423Z"
        fill="#8963C6"
      />
      <path
        d="M266.555 150.28C266.475 150.2 266.823 149.718 266.983 149.558C268.506 147.926 269.816 146.401 271.499 145.197C272.328 144.608 273.29 144.555 274.038 145.036C274.92 145.545 275.534 146.588 275.427 147.658C275.347 148.514 274.866 149.156 274.038 149.504C273.022 149.932 271.927 150.093 270.858 150.2C269.655 150.307 268.453 150.307 267.25 150.36C267.09 150.36 266.609 150.36 266.555 150.28Z"
        fill="#6A449D"
      />
      <path
        d="M263.616 142.843C263.509 142.736 263.963 142.04 264.15 141.772C266.181 139.285 267.865 136.984 270.163 135.084C271.285 134.175 272.675 134.014 273.744 134.603C275.053 135.245 276.042 136.663 275.989 138.214C275.962 139.445 275.321 140.408 274.172 140.997C272.755 141.692 271.232 142.067 269.682 142.281C267.972 142.522 266.261 142.682 264.551 142.869C264.391 142.923 263.723 142.95 263.616 142.843Z"
        fill="#8963C6"
      />
      <path
        d="M324.706 111.222C324.599 111.115 325.053 110.419 325.24 110.152C327.271 107.664 328.955 105.363 331.253 103.464C332.376 102.554 333.765 102.394 334.834 102.982C336.144 103.624 337.133 105.042 337.079 106.594C337.052 107.824 336.411 108.787 335.262 109.376C333.846 110.071 332.322 110.446 330.772 110.66C329.062 110.901 327.352 111.061 325.641 111.248C325.481 111.302 324.813 111.329 324.706 111.222Z"
        fill="#91E7F2"
      />
      <path
        d="M258.886 136.957C258.805 136.904 259.046 136.369 259.18 136.181C260.409 134.309 261.424 132.57 262.867 131.099C263.562 130.376 264.524 130.162 265.326 130.51C266.288 130.858 267.063 131.767 267.143 132.864C267.197 133.72 266.849 134.443 266.101 134.924C265.166 135.513 264.15 135.887 263.081 136.155C261.905 136.449 260.73 136.69 259.554 136.931C259.42 136.931 258.966 137.011 258.886 136.957Z"
        fill="#6A449D"
      />
      <path
        d="M321.205 92.8164C321.125 92.7629 321.366 92.2278 321.499 92.0406C322.728 90.1679 323.744 88.4291 325.187 86.9577C325.882 86.2354 326.844 86.0214 327.646 86.3691C328.608 86.7169 329.383 87.6265 329.463 88.7233C329.516 89.5794 329.169 90.3017 328.421 90.7832C327.485 91.3718 326.47 91.7463 325.401 92.0138C324.225 92.3081 323.049 92.5489 321.873 92.7896C321.74 92.7896 321.285 92.8699 321.205 92.8164Z"
        fill="#B494F2"
      />
      <path
        d="M254.69 130.162C254.556 130.082 254.877 129.306 255.037 129.012C256.614 126.203 257.87 123.635 259.821 121.387C260.756 120.291 262.092 119.889 263.268 120.291C264.658 120.692 265.887 121.923 266.101 123.447C266.288 124.678 265.834 125.748 264.791 126.497C263.509 127.433 262.092 128.049 260.596 128.557C258.966 129.092 257.309 129.547 255.652 130.028C255.465 130.082 254.823 130.242 254.69 130.162Z"
        fill="#6A449D"
      />
      <path
        d="M249.025 125.159C248.944 125.106 249.105 124.544 249.185 124.33C250.067 122.27 250.762 120.398 251.937 118.685C252.499 117.856 253.407 117.482 254.262 117.669C255.251 117.856 256.213 118.605 256.48 119.649C256.694 120.478 256.454 121.254 255.812 121.869C255.011 122.618 254.049 123.153 253.06 123.608C251.964 124.116 250.842 124.544 249.719 124.999C249.559 125.052 249.105 125.213 249.025 125.159Z"
        fill="#91E7F2"
      />
      <path
        d="M268.88 105.256C268.8 105.202 268.96 104.641 269.041 104.427C269.922 102.367 270.617 100.494 271.793 98.7819C272.354 97.9526 273.263 97.5781 274.118 97.7653C275.107 97.9526 276.069 98.7017 276.336 99.745C276.55 100.574 276.309 101.35 275.668 101.965C274.866 102.714 273.904 103.25 272.916 103.704C271.82 104.213 270.697 104.641 269.575 105.095C269.415 105.149 268.987 105.309 268.88 105.256Z"
        fill="#91E7F2"
      />
      <path
        d="M243.733 119.194C243.6 119.14 243.76 118.311 243.894 118.017C244.936 114.994 245.764 112.238 247.261 109.67C247.982 108.439 249.238 107.797 250.468 107.985C251.911 108.145 253.327 109.135 253.808 110.606C254.182 111.783 253.942 112.907 253.06 113.817C251.964 114.967 250.655 115.823 249.292 116.572C247.769 117.401 246.219 118.124 244.669 118.873C244.482 118.98 243.867 119.247 243.733 119.194Z"
        fill="#8963C6"
      />
      <path
        d="M237.266 115.261C237.159 115.234 237.239 114.646 237.293 114.432C237.801 112.238 238.175 110.285 239.03 108.386C239.458 107.476 240.259 106.941 241.141 106.968C242.157 106.968 243.226 107.557 243.653 108.546C244.001 109.322 243.92 110.152 243.359 110.847C242.691 111.73 241.836 112.425 240.954 113.041C239.965 113.71 238.923 114.352 237.908 114.994C237.774 115.074 237.373 115.288 237.266 115.261Z"
        fill="#6A449D"
      />
      <path
        d="M254.583 91.2648C254.476 91.238 254.556 90.6495 254.61 90.4355C255.118 88.2418 255.492 86.2889 256.347 84.3895C256.774 83.4799 257.576 82.9449 258.458 82.9717C259.474 82.9717 260.542 83.5602 260.97 84.55C261.317 85.3258 261.237 86.1552 260.676 86.8507C260.008 87.7335 259.153 88.4291 258.271 89.0444C257.282 89.7132 256.24 90.3552 255.224 90.9973C255.091 91.0775 254.663 91.2915 254.583 91.2648Z"
        fill="#8963C6"
      />
      <path
        d="M260.008 78.3166C259.901 78.2898 259.981 77.7013 260.035 77.4873C260.542 75.2936 260.917 73.3407 261.772 71.4413C262.199 70.5317 263.001 69.9967 263.883 70.0234C264.898 70.0234 265.967 70.612 266.395 71.6018C266.742 72.3776 266.662 73.2069 266.101 73.9025C265.433 74.7853 264.578 75.4808 263.696 76.0961C262.707 76.7649 261.665 77.407 260.649 78.049C260.516 78.1293 260.115 78.3701 260.008 78.3166Z"
        fill="#6A449D"
      />
      <path
        d="M231.013 110.312C230.879 110.286 230.906 109.429 230.959 109.108C231.467 105.952 231.788 103.089 232.83 100.307C233.338 98.9694 234.46 98.1133 235.689 98.0866C237.133 97.9796 238.709 98.7019 239.431 100.093C240.019 101.19 239.965 102.34 239.244 103.383C238.362 104.694 237.239 105.764 236.01 106.754C234.674 107.824 233.258 108.814 231.868 109.831C231.708 109.991 231.147 110.339 231.013 110.312Z"
        fill="#B494F2"
      />
      <path
        d="M245.176 82.9714C245.043 82.9447 245.069 82.0886 245.123 81.7676C245.631 78.6109 245.951 75.7484 246.994 72.9662C247.501 71.6286 248.624 70.7725 249.853 70.7458C251.296 70.6387 252.873 71.361 253.594 72.7522C254.182 73.849 254.129 74.9993 253.407 76.0427C252.525 77.3535 251.403 78.4236 250.174 79.4134C248.837 80.4835 247.421 81.4733 246.031 82.4899C245.898 82.6237 245.337 82.9982 245.176 82.9714Z"
        fill="#91E7F2"
      />
      <path
        d="M223.985 107.557C223.878 107.53 223.851 106.968 223.851 106.727C223.985 104.48 223.985 102.474 224.519 100.467C224.76 99.5043 225.481 98.8355 226.363 98.7018C227.352 98.5145 228.501 98.9158 229.115 99.8253C229.597 100.548 229.65 101.35 229.222 102.126C228.715 103.116 227.993 103.945 227.218 104.694C226.363 105.55 225.454 106.326 224.572 107.129C224.439 107.289 224.065 107.583 223.985 107.557Z"
        fill="#8963C6"
      />
      <path
        d="M237.079 75.5879C236.972 75.5612 236.945 74.9994 236.945 74.7586C237.079 72.5114 237.079 70.505 237.614 68.4986C237.854 67.5356 238.576 66.8668 239.457 66.733C240.446 66.5457 241.595 66.947 242.21 67.8566C242.691 68.5789 242.744 69.3815 242.317 70.1573C241.809 71.1471 241.088 71.9764 240.313 72.7255C239.457 73.5815 238.549 74.3573 237.667 75.1599C237.533 75.2937 237.186 75.5879 237.079 75.5879Z"
        fill="#6A449D"
      />
      <path
        d="M233.712 60.1787C233.605 60.152 233.578 59.5902 233.578 59.3494C233.712 57.1023 233.712 55.0959 234.246 53.0895C234.487 52.1264 235.208 51.4576 236.09 51.3238C237.079 51.1366 238.228 51.5378 238.843 52.4474C239.324 53.1697 239.377 53.9723 238.95 54.7481C238.442 55.7379 237.72 56.5672 236.945 57.3163C236.09 58.1723 235.182 58.9481 234.3 59.7507C234.166 59.8845 233.792 60.1787 233.712 60.1787Z"
        fill="#B494F2"
      />
      <path
        d="M250.067 28.7186C249.96 28.6918 249.933 28.13 249.933 27.8892C250.067 25.6421 250.067 23.6357 250.601 21.6293C250.842 20.6662 251.563 19.9974 252.445 19.8636C253.434 19.6764 254.583 20.0776 255.198 20.9872C255.679 21.7095 255.732 22.5121 255.305 23.2879C254.797 24.2777 254.075 25.107 253.3 25.8561C252.445 26.7121 251.537 27.488 250.655 28.2905C250.548 28.451 250.174 28.7453 250.067 28.7186Z"
        fill="#B494F2"
      />
      <path
        d="M216.956 103.785C216.823 103.785 216.689 102.955 216.689 102.608C216.636 99.3974 216.475 96.5349 217.01 93.619C217.277 92.2011 218.239 91.1845 219.442 90.9438C220.858 90.596 222.515 91.024 223.477 92.2546C224.252 93.2177 224.385 94.368 223.878 95.5451C223.236 96.9897 222.301 98.2471 221.285 99.4242C220.136 100.708 218.934 101.939 217.731 103.169C217.598 103.33 217.117 103.785 216.956 103.785Z"
        fill="#91E7F2"
      />
      <path
        d="M209.554 102.287C209.447 102.287 209.313 101.725 209.286 101.484C209.019 99.2636 208.699 97.2839 208.859 95.1972C208.939 94.1807 209.527 93.4049 210.355 93.1641C211.317 92.8163 212.493 93.0036 213.268 93.7794C213.883 94.3947 214.043 95.1972 213.776 96.0266C213.429 97.0699 212.894 98.033 212.253 98.9158C211.558 99.9056 210.81 100.842 210.061 101.778C209.955 101.939 209.634 102.287 209.554 102.287Z"
        fill="#B494F2"
      />
      <path
        d="M231.28 36.6369C231.173 36.6369 231.04 36.0751 231.013 35.8343C230.746 33.6139 230.425 31.6342 230.585 29.5476C230.665 28.531 231.253 27.7552 232.082 27.5144C233.044 27.1667 234.22 27.3539 234.995 28.1297C235.609 28.745 235.77 29.5476 235.502 30.3769C235.155 31.4202 234.62 32.3833 233.979 33.2661C233.284 34.2559 232.536 35.1923 231.788 36.1286C231.681 36.2891 231.387 36.6369 231.28 36.6369Z"
        fill="#6A449D"
      />
      <path
        d="M210.623 70.318C210.516 70.318 210.382 69.7562 210.355 69.5155C210.088 67.295 209.768 65.3154 209.928 63.2287C210.008 62.2122 210.596 61.4363 211.424 61.1956C212.386 60.8478 213.562 61.0351 214.337 61.8109C214.952 62.4262 215.112 63.2287 214.845 64.058C214.498 65.1014 213.963 66.0644 213.322 66.9473C212.627 67.9371 211.879 68.8734 211.13 69.8097C211.024 69.9702 210.73 70.318 210.623 70.318Z"
        fill="#91E7F2"
      />
      <path
        d="M223.129 72.7524C223.022 72.7524 222.889 72.1906 222.862 71.9498C222.595 69.7294 222.274 67.7497 222.434 65.6631C222.515 64.6465 223.103 63.8707 223.931 63.6299C224.893 63.2821 226.069 63.4694 226.844 64.2452C227.459 64.8605 227.619 65.6631 227.352 66.4924C227.004 67.5357 226.47 68.4988 225.828 69.3816C225.134 70.3714 224.385 71.3078 223.637 72.2441C223.53 72.3778 223.236 72.7524 223.129 72.7524Z"
        fill="#8963C6"
      />
      <path
        d="M201.991 99.7719C201.857 99.7719 201.59 98.9961 201.537 98.6751C200.949 95.5184 200.281 92.7362 200.307 89.7667C200.334 88.3221 201.082 87.145 202.258 86.6902C203.594 86.1017 205.305 86.2354 206.454 87.2787C207.362 88.1081 207.71 89.2049 207.416 90.4355C207.042 91.9603 206.347 93.3782 205.518 94.6891C204.61 96.1604 203.621 97.5783 202.686 98.9961C202.525 99.2369 202.125 99.7719 201.991 99.7719Z"
        fill="#8963C6"
      />
      <path
        d="M199.506 65.5293C199.372 65.5293 199.105 64.7535 199.051 64.4324C198.463 61.2757 197.795 58.4935 197.822 55.524C197.849 54.0794 198.597 52.9023 199.773 52.4475C201.109 51.859 202.819 51.9927 203.968 53.0361C204.877 53.8654 205.224 54.9622 204.93 56.1928C204.556 57.7177 203.861 59.1355 203.033 60.4464C202.124 61.9177 201.136 63.3356 200.2 64.7535C200.04 64.9675 199.666 65.5025 199.506 65.5293Z"
        fill="#B494F2"
      />
      <path
        d="M196.272 42.0676C196.138 42.0944 195.791 41.3186 195.711 41.0243C194.829 37.9478 193.894 35.2459 193.653 32.2764C193.52 30.8318 194.188 29.6012 195.31 29.0394C196.566 28.3171 198.303 28.3171 199.559 29.2266C200.548 29.949 201.002 31.019 200.815 32.2764C200.575 33.828 200.013 35.2994 199.345 36.6905C198.597 38.2421 197.742 39.7402 196.913 41.2383C196.753 41.4523 196.406 42.0409 196.272 42.0676Z"
        fill="#91E7F2"
      />
      <path
        d="M194.428 99.6113C194.321 99.6381 194.081 99.103 194.027 98.889C193.386 96.7489 192.718 94.8495 192.504 92.7895C192.397 91.773 192.851 90.9169 193.626 90.5156C194.508 90.0073 195.711 89.9806 196.593 90.6226C197.288 91.1309 197.608 91.88 197.501 92.7628C197.368 93.8596 196.967 94.8762 196.513 95.866C196.005 96.9629 195.417 98.0062 194.856 99.0763C194.749 99.1833 194.535 99.5846 194.428 99.6113Z"
        fill="#6A449D"
      />
      <path
        d="M189.377 62.8274C189.27 62.8541 189.03 62.3191 188.976 62.1051C188.335 59.9649 187.667 58.0655 187.453 56.0056C187.346 54.989 187.801 54.133 188.576 53.7317C189.458 53.2234 190.66 53.1966 191.542 53.8387C192.237 54.347 192.557 55.096 192.451 55.9788C192.317 57.0757 191.916 58.0923 191.462 59.0821C190.954 60.1789 190.366 61.2222 189.805 62.2923C189.725 62.4261 189.484 62.8274 189.377 62.8274Z"
        fill="#8963C6"
      />
      <path
        d="M198.303 17.5359C198.196 17.5626 197.956 17.0276 197.902 16.8136C197.261 14.6734 196.593 12.774 196.379 10.7141C196.272 9.69751 196.726 8.84145 197.501 8.44017C198.383 7.93188 199.586 7.90513 200.468 8.54717C201.162 9.05546 201.483 9.80452 201.376 10.6873C201.243 11.7842 200.842 12.8007 200.387 13.7906C199.88 14.8874 199.292 15.9307 198.731 17.0008C198.65 17.1078 198.41 17.5091 198.303 17.5359Z"
        fill="#6A449D"
      />
      <path
        d="M286.785 178.476C286.732 178.342 287.373 177.941 287.64 177.807C290.259 176.55 292.531 175.293 295.15 174.624C296.406 174.303 297.608 174.704 298.276 175.614C299.105 176.63 299.372 178.182 298.731 179.439C298.223 180.429 297.341 180.991 296.192 181.018C294.776 181.044 293.386 180.777 292.023 180.349C290.526 179.894 289.057 179.359 287.587 178.877C287.4 178.824 286.839 178.61 286.785 178.476Z"
        fill="#6A449D"
      />
      <path
        d="M323.904 185.592C323.851 185.459 324.492 185.057 324.759 184.924C327.378 183.666 329.65 182.409 332.269 181.74C333.525 181.419 334.727 181.82 335.395 182.73C336.224 183.746 336.491 185.298 335.85 186.555C335.342 187.545 334.46 188.107 333.311 188.134C331.895 188.161 330.505 187.893 329.142 187.465C327.646 187.01 326.176 186.475 324.706 185.994C324.519 185.913 323.958 185.699 323.904 185.592Z"
        fill="#B494F2"
      />
      <path
        d="M292.317 169.033C292.263 168.953 292.664 168.605 292.851 168.498C294.535 167.374 296.005 166.277 297.742 165.582C298.597 165.234 299.452 165.395 300.013 165.956C300.681 166.599 301.029 167.615 300.708 168.551C300.468 169.3 299.906 169.755 299.105 169.889C298.116 170.05 297.127 169.996 296.138 169.836C295.069 169.675 294 169.434 292.931 169.247C292.771 169.22 292.344 169.113 292.317 169.033Z"
        fill="#B494F2"
      />
      <path
        d="M342.638 163.468C342.584 163.388 342.985 163.04 343.172 162.933C344.856 161.81 346.325 160.713 348.062 160.017C348.918 159.67 349.773 159.83 350.334 160.392C351.002 161.007 351.349 162.051 351.029 162.987C350.788 163.736 350.227 164.191 349.425 164.325C348.437 164.485 347.448 164.432 346.459 164.271C345.39 164.111 344.321 163.87 343.252 163.683C343.092 163.656 342.691 163.549 342.638 163.468Z"
        fill="#B494F2"
      />
      <path
        d="M326.683 164.913C326.63 164.833 327.031 164.485 327.218 164.378C328.902 163.254 330.371 162.158 332.108 161.462C332.963 161.114 333.819 161.275 334.38 161.837C335.048 162.479 335.395 163.495 335.075 164.432C334.834 165.181 334.273 165.635 333.471 165.769C332.482 165.93 331.494 165.876 330.505 165.716C329.436 165.555 328.367 165.314 327.298 165.127C327.138 165.1 326.737 165.02 326.683 164.913Z"
        fill="#8963C6"
      />
      <path
        d="M307.63 156.219C307.576 156.138 307.977 155.791 308.164 155.684C309.848 154.56 311.318 153.463 313.055 152.768C313.91 152.42 314.765 152.58 315.326 153.142C315.994 153.758 316.342 154.801 316.021 155.737C315.78 156.486 315.219 156.941 314.417 157.075C313.429 157.235 312.44 157.182 311.451 157.021C310.382 156.861 309.313 156.62 308.244 156.433C308.084 156.406 307.683 156.299 307.63 156.219Z"
        fill="#91E7F2"
      />
      <path
        d="M364.978 154.935C364.925 154.854 365.326 154.507 365.513 154.4C367.197 153.276 368.666 152.179 370.403 151.484C371.259 151.136 372.114 151.296 372.675 151.858C373.343 152.473 373.69 153.517 373.37 154.453C373.129 155.202 372.568 155.657 371.766 155.791C370.778 155.951 369.789 155.898 368.8 155.737C367.731 155.577 366.662 155.336 365.593 155.149C365.433 155.122 365.032 155.015 364.978 154.935Z"
        fill="#B494F2"
      />
      <path
        d="M287.186 160.419C287.133 160.312 287.667 159.777 287.908 159.616C290.259 157.904 292.29 156.299 294.749 155.176C295.925 154.64 297.181 154.828 298.009 155.604C298.998 156.46 299.532 157.931 299.131 159.295C298.811 160.365 298.036 161.061 296.913 161.302C295.524 161.569 294.081 161.543 292.691 161.382C291.141 161.195 289.618 160.927 288.068 160.686C287.827 160.66 287.239 160.526 287.186 160.419Z"
        fill="#8963C6"
      />
      <path
        d="M323.61 153.329C323.557 153.222 324.091 152.687 324.332 152.527C326.684 150.815 328.715 149.209 331.173 148.086C332.349 147.551 333.605 147.738 334.433 148.514C335.422 149.37 335.957 150.841 335.556 152.206C335.235 153.276 334.46 153.971 333.338 154.212C331.948 154.48 330.505 154.453 329.115 154.292C327.566 154.105 326.042 153.838 324.492 153.597C324.279 153.57 323.691 153.436 323.61 153.329Z"
        fill="#6A449D"
      />
      <path
        d="M300.548 146.187C300.494 146.08 301.029 145.545 301.269 145.384C303.621 143.672 305.652 142.067 308.111 140.943C309.286 140.408 310.542 140.596 311.371 141.371C312.36 142.227 312.894 143.699 312.493 145.063C312.173 146.133 311.398 146.829 310.275 147.07C308.886 147.337 307.443 147.31 306.053 147.15C304.503 146.963 302.98 146.695 301.43 146.454C301.216 146.401 300.628 146.294 300.548 146.187Z"
        fill="#91E7F2"
      />
      <path
        d="M286.037 150.948C285.983 150.868 286.304 150.467 286.464 150.306C287.934 148.889 289.19 147.578 290.767 146.561C291.542 146.08 292.424 146.08 293.065 146.534C293.84 147.043 294.348 148.006 294.188 148.969C294.081 149.745 293.6 150.306 292.825 150.574C291.889 150.895 290.901 151.002 289.912 151.029C288.816 151.055 287.72 151.029 286.651 151.002C286.518 151.055 286.09 151.029 286.037 150.948Z"
        fill="#6A449D"
      />
      <path
        d="M337.827 139.284C337.774 139.204 338.094 138.803 338.255 138.642C339.725 137.224 340.981 135.914 342.557 134.897C343.332 134.415 344.214 134.416 344.856 134.87C345.631 135.379 346.138 136.342 345.978 137.305C345.871 138.081 345.39 138.642 344.615 138.91C343.68 139.231 342.691 139.338 341.702 139.365C340.606 139.391 339.511 139.365 338.442 139.338C338.308 139.365 337.881 139.338 337.827 139.284Z"
        fill="#91E7F2"
      />
      <path
        d="M321.74 140.863C321.686 140.783 322.007 140.381 322.167 140.221C323.637 138.803 324.893 137.492 326.47 136.476C327.245 135.994 328.127 135.994 328.768 136.449C329.543 136.957 330.051 137.92 329.89 138.883C329.783 139.659 329.302 140.221 328.527 140.488C327.592 140.809 326.603 140.917 325.615 140.943C324.519 140.97 323.423 140.943 322.354 140.916C322.221 140.943 321.793 140.943 321.74 140.863Z"
        fill="#B494F2"
      />
      <path
        d="M280.158 144.394C280.104 144.314 280.425 143.913 280.585 143.752C282.055 142.334 283.311 141.023 284.888 140.007C285.663 139.525 286.544 139.525 287.186 139.98C287.961 140.488 288.469 141.452 288.308 142.415C288.201 143.19 287.72 143.752 286.945 144.02C286.01 144.341 285.021 144.448 284.032 144.474C282.937 144.501 281.841 144.474 280.772 144.448C280.639 144.501 280.211 144.474 280.158 144.394Z"
        fill="#B494F2"
      />
      <path
        d="M306.16 131.232C306.106 131.152 306.427 130.751 306.587 130.59C308.057 129.172 309.313 127.862 310.89 126.845C311.665 126.364 312.547 126.363 313.188 126.818C313.963 127.327 314.471 128.29 314.31 129.253C314.204 130.029 313.723 130.59 312.948 130.858C312.012 131.179 311.023 131.286 310.035 131.313C308.939 131.339 307.843 131.313 306.774 131.286C306.641 131.313 306.213 131.313 306.16 131.232Z"
        fill="#6A449D"
      />
      <path
        d="M290.981 138.589C290.901 138.482 291.355 137.893 291.569 137.679C293.6 135.593 295.31 133.64 297.528 132.115C298.597 131.366 299.88 131.339 300.815 131.954C301.938 132.623 302.739 133.987 302.552 135.405C302.419 136.502 301.777 137.358 300.708 137.76C299.372 138.268 297.982 138.482 296.566 138.562C295.016 138.642 293.439 138.642 291.889 138.696C291.649 138.723 291.061 138.696 290.981 138.589Z"
        fill="#8963C6"
      />
      <path
        d="M323.691 127.166C323.61 127.059 324.065 126.47 324.278 126.256C326.309 124.169 328.02 122.217 330.238 120.692C331.307 119.943 332.59 119.916 333.525 120.531C334.647 121.2 335.449 122.564 335.262 123.982C335.128 125.079 334.487 125.935 333.418 126.336C332.082 126.845 330.692 127.059 329.276 127.139C327.726 127.219 326.149 127.219 324.599 127.273C324.385 127.273 323.797 127.246 323.691 127.166Z"
        fill="#6A449D"
      />
      <path
        d="M279.757 135.432C279.677 135.379 279.944 134.897 280.077 134.737C281.28 133.105 282.269 131.58 283.658 130.323C284.327 129.708 285.208 129.547 285.93 129.895C286.785 130.243 287.453 131.125 287.48 132.115C287.507 132.891 287.133 133.533 286.438 133.934C285.556 134.416 284.621 134.71 283.632 134.897C282.563 135.111 281.494 135.272 280.398 135.432C280.265 135.459 279.837 135.486 279.757 135.432Z"
        fill="#91E7F2"
      />
      <path
        d="M291.569 126.524C291.489 126.47 291.756 125.989 291.889 125.828C293.092 124.197 294.081 122.672 295.47 121.414C296.138 120.799 297.02 120.639 297.742 120.986C298.597 121.334 299.265 122.217 299.292 123.207C299.319 123.983 298.944 124.625 298.25 125.026C297.368 125.507 296.432 125.802 295.444 125.989C294.375 126.203 293.306 126.363 292.21 126.524C292.05 126.551 291.649 126.578 291.569 126.524Z"
        fill="#91E7F2"
      />
      <path
        d="M306.561 116.626C306.48 116.572 306.748 116.091 306.881 115.93C308.084 114.298 309.073 112.774 310.462 111.516C311.13 110.901 312.012 110.74 312.734 111.088C313.589 111.436 314.257 112.319 314.284 113.309C314.311 114.084 313.936 114.726 313.242 115.128C312.36 115.609 311.424 115.903 310.436 116.091C309.367 116.305 308.298 116.465 307.202 116.626C307.042 116.626 306.614 116.679 306.561 116.626Z"
        fill="#8963C6"
      />
      <path
        d="M346.085 101.805C346.005 101.752 346.272 101.27 346.406 101.109C347.608 99.4776 348.597 97.9527 349.987 96.6954C350.655 96.0801 351.537 95.9196 352.258 96.2673C353.113 96.6151 353.781 97.4979 353.808 98.4878C353.835 99.2636 353.461 99.9056 352.766 100.307C351.884 100.788 350.949 101.083 349.96 101.27C348.891 101.484 347.822 101.645 346.726 101.805C346.593 101.805 346.165 101.859 346.085 101.805Z"
        fill="#B494F2"
      />
      <path
        d="M271.179 129.761C271.072 129.681 271.419 129.012 271.579 128.744C273.21 126.337 274.572 124.116 276.497 122.244C277.432 121.334 278.661 121.067 279.703 121.521C280.933 122.003 281.948 123.18 282.028 124.598C282.082 125.721 281.601 126.631 280.612 127.246C279.383 127.995 278.047 128.45 276.657 128.771C275.134 129.146 273.61 129.413 272.087 129.707C271.873 129.761 271.285 129.841 271.179 129.761Z"
        fill="#8963C6"
      />
      <path
        d="M287.026 117.91C286.919 117.83 287.266 117.161 287.427 116.893C289.057 114.486 290.42 112.265 292.344 110.393C293.279 109.483 294.508 109.215 295.551 109.67C296.78 110.152 297.795 111.329 297.875 112.747C297.929 113.87 297.448 114.78 296.459 115.395C295.23 116.144 293.894 116.599 292.504 116.92C290.981 117.295 289.458 117.562 287.934 117.856C287.721 117.91 287.133 118.017 287.026 117.91Z"
        fill="#B494F2"
      />
      <path
        d="M289.244 102.447C289.137 102.367 289.377 101.671 289.511 101.404C290.767 98.782 291.782 96.401 293.413 94.2609C294.188 93.2175 295.39 92.7895 296.486 93.0838C297.768 93.378 298.944 94.3946 299.238 95.7857C299.452 96.8826 299.105 97.8724 298.223 98.5947C297.127 99.5043 295.844 100.146 294.535 100.681C293.092 101.27 291.595 101.751 290.152 102.286C289.938 102.34 289.351 102.527 289.244 102.447Z"
        fill="#91E7F2"
      />
      <path
        d="M268.96 119.943C268.88 119.889 269.041 119.381 269.147 119.194C270.056 117.375 270.778 115.716 271.927 114.218C272.488 113.496 273.316 113.201 274.065 113.415C274.973 113.629 275.775 114.352 275.962 115.315C276.122 116.09 275.855 116.786 275.24 117.294C274.465 117.936 273.584 118.364 272.648 118.739C271.633 119.14 270.591 119.461 269.575 119.836C269.441 119.863 269.041 119.996 268.96 119.943Z"
        fill="#91E7F2"
      />
      <path
        d="M278.501 111.248C278.421 111.195 278.581 110.687 278.688 110.499C279.596 108.68 280.318 107.022 281.467 105.523C282.028 104.801 282.857 104.507 283.605 104.721C284.514 104.935 285.315 105.657 285.502 106.62C285.663 107.396 285.395 108.092 284.781 108.6C284.006 109.242 283.124 109.67 282.189 110.045C281.173 110.446 280.131 110.767 279.115 111.141C279.009 111.168 278.581 111.302 278.501 111.248Z"
        fill="#B494F2"
      />
      <path
        d="M303.434 85.9945C303.354 85.941 303.514 85.4327 303.621 85.2455C304.53 83.4263 305.251 81.7677 306.4 80.2696C306.961 79.5473 307.79 79.253 308.538 79.467C309.447 79.681 310.248 80.4034 310.436 81.3664C310.596 82.1422 310.329 82.8378 309.714 83.3461C308.939 83.9881 308.057 84.4162 307.122 84.7907C306.106 85.192 305.064 85.513 304.049 85.8875C303.915 85.941 303.487 86.048 303.434 85.9945Z"
        fill="#8963C6"
      />
      <path
        d="M304.877 101.698C304.797 101.645 304.957 101.136 305.064 100.949C305.973 99.13 306.694 97.4713 307.843 95.9732C308.405 95.2509 309.233 94.9566 309.981 95.1706C310.89 95.3847 311.692 96.107 311.879 97.07C312.039 97.8459 311.772 98.5414 311.157 99.0497C310.382 99.6917 309.5 100.12 308.565 100.494C307.549 100.896 306.507 101.217 305.492 101.591C305.358 101.618 304.957 101.725 304.877 101.698Z"
        fill="#6A449D"
      />
      <path
        d="M318.319 68.4721C318.239 68.4186 318.399 67.9103 318.506 67.723C319.415 65.9039 320.136 64.2452 321.285 62.7471C321.847 62.0248 322.675 61.7306 323.423 61.9446C324.332 62.1586 325.134 62.8809 325.321 63.844C325.481 64.6198 325.214 65.3153 324.599 65.8236C323.824 66.4657 322.942 66.8937 322.007 67.2682C320.991 67.6695 319.949 67.9905 318.934 68.3651C318.8 68.3918 318.399 68.4988 318.319 68.4721Z"
        fill="#6A449D"
      />
      <path
        d="M279.409 96.0266C279.329 95.9731 279.463 95.4648 279.543 95.2776C280.291 93.3782 280.879 91.666 281.895 90.0877C282.402 89.3118 283.204 88.9641 283.979 89.0978C284.888 89.2316 285.77 89.9004 286.037 90.8367C286.251 91.5858 286.064 92.3081 285.476 92.8699C284.754 93.5654 283.899 94.0737 283.017 94.5018C282.028 94.9833 281.04 95.4113 280.024 95.8394C279.864 95.9196 279.49 96.0534 279.409 96.0266Z"
        fill="#6A449D"
      />
      <path
        d="M258.004 116.358C257.897 116.305 258.111 115.555 258.244 115.288C259.447 112.64 260.382 110.232 261.959 108.038C262.707 106.968 263.883 106.513 264.979 106.781C266.261 107.022 267.464 108.038 267.785 109.403C268.052 110.499 267.704 111.489 266.849 112.238C265.78 113.175 264.524 113.87 263.242 114.432C261.825 115.047 260.355 115.582 258.886 116.144C258.699 116.224 258.111 116.412 258.004 116.358Z"
        fill="#B494F2"
      />
      <path
        d="M287.026 80.6976C286.919 80.6441 287.079 79.8951 287.186 79.6275C288.202 76.8988 288.977 74.4109 290.366 72.137C291.061 71.0134 292.183 70.4784 293.306 70.6656C294.615 70.8261 295.871 71.7357 296.299 73.1C296.62 74.1701 296.379 75.1867 295.577 76.016C294.562 77.0326 293.386 77.7817 292.13 78.4505C290.74 79.1728 289.324 79.8148 287.908 80.4836C287.694 80.5371 287.133 80.7779 287.026 80.6976Z"
        fill="#6A449D"
      />
      <path
        d="M255.759 105.363C255.679 105.336 255.759 104.801 255.812 104.614C256.374 102.661 256.801 100.895 257.683 99.2367C258.111 98.4342 258.886 97.9794 259.661 98.0596C260.569 98.1132 261.504 98.6749 261.879 99.6113C262.173 100.334 262.039 101.056 261.504 101.671C260.863 102.42 260.061 103.009 259.206 103.544C258.271 104.106 257.309 104.614 256.374 105.149C256.213 105.202 255.839 105.39 255.759 105.363Z"
        fill="#6A449D"
      />
      <path
        d="M274.92 76.4174C274.84 76.3907 274.92 75.8556 274.973 75.6684C275.534 73.7155 275.962 71.9498 276.844 70.2912C277.271 69.4886 278.046 69.0338 278.821 69.1141C279.73 69.1676 280.665 69.7294 281.039 70.6657C281.333 71.388 281.2 72.1103 280.665 72.7256C280.024 73.4747 279.222 74.0632 278.367 74.5983C277.432 75.1601 276.47 75.6684 275.534 76.2034C275.374 76.2569 275 76.4442 274.92 76.4174Z"
        fill="#B494F2"
      />
      <path
        d="M297.261 62.3725C297.181 62.3457 297.261 61.8107 297.314 61.6234C297.876 59.6705 298.303 57.9049 299.185 56.2463C299.613 55.4437 300.388 54.9889 301.163 55.0692C302.071 55.1227 303.007 55.6845 303.381 56.6208C303.675 57.3431 303.541 58.0654 303.007 58.6807C302.365 59.4297 301.563 60.0183 300.708 60.5533C299.773 61.1151 298.811 61.6234 297.876 62.1585C297.742 62.212 297.368 62.3992 297.261 62.3725Z"
        fill="#91E7F2"
      />
      <path
        d="M245.631 103.143C245.497 103.089 245.604 102.34 245.684 102.046C246.406 99.2368 246.913 96.6686 248.063 94.2609C248.624 93.0838 249.693 92.415 250.842 92.4953C252.151 92.522 253.514 93.2978 254.075 94.6087C254.503 95.6253 254.369 96.6686 253.648 97.5781C252.766 98.7017 251.643 99.5846 250.468 100.36C249.158 101.216 247.822 101.992 246.486 102.822C246.299 102.902 245.764 103.169 245.631 103.143Z"
        fill="#91E7F2"
      />
      <path
        d="M264.498 62.9878C264.364 62.9343 264.471 62.1852 264.551 61.891C265.273 59.082 265.78 56.5138 266.929 54.1061C267.491 52.929 268.56 52.2602 269.709 52.3405C271.018 52.3672 272.381 53.143 272.942 54.4539C273.37 55.4705 273.236 56.5138 272.515 57.4234C271.633 58.5469 270.51 59.4298 269.335 60.2056C268.025 61.0616 266.689 61.8375 265.353 62.6668C265.166 62.747 264.631 63.0413 264.498 62.9878Z"
        fill="#91E7F2"
      />
      <path
        d="M264.498 94.3682C264.364 94.3147 264.471 93.5656 264.551 93.2713C265.273 90.4624 265.78 87.8942 266.929 85.4865C267.491 84.3094 268.56 83.6406 269.709 83.7208C271.018 83.7476 272.381 84.5234 272.942 85.8343C273.37 86.8508 273.236 87.8942 272.515 88.8037C271.633 89.9273 270.51 90.8101 269.335 91.5859C268.025 92.442 266.689 93.2178 265.353 94.0471C265.166 94.1274 264.631 94.3949 264.498 94.3682Z"
        fill="#B494F2"
      />
      <path
        d="M280.665 55.738C280.532 55.6845 280.639 54.9355 280.719 54.6412C281.44 51.8322 281.948 49.264 283.097 46.8563C283.658 45.6793 284.727 45.0105 285.877 45.0907C287.186 45.1175 288.549 45.8933 289.11 47.2041C289.538 48.2207 289.404 49.264 288.683 50.1736C287.801 51.2972 286.678 52.18 285.502 52.9558C284.193 53.8119 282.857 54.5877 281.521 55.417C281.307 55.4973 280.772 55.7648 280.665 55.738Z"
        fill="#8963C6"
      />
      <path
        d="M239.858 93.5921C239.778 93.5654 239.751 93.0571 239.778 92.8431C239.992 90.8099 240.099 89.0176 240.687 87.1984C240.954 86.3156 241.649 85.7538 242.424 85.7003C243.332 85.5933 244.348 85.9946 244.856 86.8506C245.256 87.5194 245.256 88.2685 244.856 88.9373C244.348 89.7934 243.653 90.5157 242.931 91.1845C242.103 91.9068 241.248 92.5756 240.419 93.2711C240.286 93.3514 239.938 93.5921 239.858 93.5921Z"
        fill="#6A449D"
      />
      <path
        d="M266.368 42.7633C266.288 42.7365 266.261 42.2283 266.288 42.0142C266.502 39.9811 266.609 38.1887 267.197 36.3696C267.464 35.4867 268.159 34.925 268.934 34.8715C269.842 34.7644 270.858 35.1657 271.366 36.0218C271.766 36.6906 271.766 37.4396 271.366 38.1084C270.858 38.9645 270.163 39.6868 269.441 40.3556C268.613 41.0779 267.758 41.7467 266.929 42.4423C266.796 42.5225 266.448 42.7633 266.368 42.7633Z"
        fill="#6A449D"
      />
      <path
        d="M279.783 31.982C279.703 31.9553 279.677 31.447 279.703 31.233C279.917 29.1998 280.024 27.4075 280.612 25.5883C280.879 24.7055 281.574 24.1437 282.349 24.0902C283.258 23.9832 284.273 24.3845 284.781 25.2405C285.182 25.9093 285.182 26.6584 284.781 27.3272C284.273 28.1833 283.578 28.9056 282.857 29.5744C282.028 30.2967 281.173 30.9655 280.345 31.661C280.211 31.768 279.864 32.0088 279.783 31.982Z"
        fill="#8963C6"
      />
      <path
        d="M252.071 64.1381C251.991 64.1113 251.964 63.603 251.991 63.389C252.205 61.3558 252.312 59.5635 252.9 57.7443C253.167 56.8615 253.862 56.2997 254.637 56.2462C255.545 56.1392 256.561 56.5405 257.068 57.3965C257.469 58.0653 257.469 58.8144 257.068 59.4832C256.561 60.3393 255.866 61.0616 255.144 61.7304C254.316 62.4527 253.461 63.1215 252.632 63.817C252.499 63.8973 252.151 64.1381 252.071 64.1381Z"
        fill="#8963C6"
      />
      <path
        d="M229.49 92.843C229.356 92.8163 229.302 92.0672 229.329 91.773C229.543 88.857 229.596 86.2888 230.345 83.6938C230.692 82.4365 231.627 81.5804 232.75 81.4734C234.059 81.2594 235.529 81.7944 236.304 82.9715C236.919 83.9079 236.945 84.9512 236.411 85.9678C235.716 87.2251 234.781 88.2952 233.765 89.2583C232.643 90.3284 231.44 91.3449 230.265 92.3615C230.104 92.4953 229.623 92.8698 229.49 92.843Z"
        fill="#8963C6"
      />
      <path
        d="M245.203 49.0498C245.07 49.0231 245.016 48.274 245.043 47.9798C245.257 45.0638 245.31 42.4956 246.058 39.9006C246.406 38.6433 247.341 37.7872 248.463 37.6802C249.773 37.4662 251.243 38.0012 252.018 39.1783C252.632 40.1146 252.659 41.158 252.125 42.1746C251.43 43.4319 250.494 44.502 249.479 45.4651C248.357 46.5351 247.154 47.5517 245.978 48.5683C245.818 48.6753 245.337 49.0498 245.203 49.0498Z"
        fill="#91E7F2"
      />
      <path
        d="M223.53 86.6634C223.45 86.6634 223.343 86.1284 223.316 85.9411C223.183 83.9079 222.969 82.1156 223.236 80.2429C223.37 79.3334 223.931 78.6646 224.706 78.4773C225.588 78.2098 226.657 78.4238 227.298 79.1728C227.806 79.7614 227.94 80.4837 227.646 81.2328C227.298 82.1691 226.737 82.9984 226.122 83.7742C225.454 84.6303 224.706 85.4328 224.011 86.2621C223.931 86.3424 223.637 86.6634 223.53 86.6634Z"
        fill="#B494F2"
      />
      <path
        d="M212.574 90.0874C212.44 90.0874 212.28 89.3383 212.253 89.0441C211.959 86.1549 211.585 83.5867 211.825 80.9115C211.959 79.6006 212.734 78.6108 213.83 78.2898C215.086 77.8617 216.609 78.1292 217.571 79.1726C218.346 79.9751 218.56 81.0185 218.186 82.1153C217.731 83.4796 216.983 84.6835 216.155 85.8071C215.219 87.0644 214.23 88.2683 213.242 89.4721C213.108 89.6326 212.707 90.0874 212.574 90.0874Z"
        fill="#6A449D"
      />
      <path
        d="M220.644 58.0923C220.51 58.0923 220.35 57.3432 220.323 57.049C220.029 54.1597 219.655 51.5915 219.896 48.9163C220.029 47.6055 220.804 46.6157 221.9 46.2946C223.156 45.8666 224.679 46.1341 225.641 47.1775C226.416 47.98 226.63 49.0233 226.256 50.1202C225.802 51.4845 225.053 52.6884 224.225 53.812C223.29 55.0693 222.301 56.2732 221.312 57.477C221.178 57.6375 220.778 58.0923 220.644 58.0923Z"
        fill="#8963C6"
      />
      <path
        d="M205.492 81.7676C205.411 81.7676 205.198 81.2861 205.171 81.0721C204.69 79.0924 204.182 77.3535 204.102 75.4809C204.075 74.5713 204.503 73.7955 205.224 73.4745C206.053 73.0464 207.148 73.0999 207.897 73.7152C208.511 74.1968 208.752 74.8923 208.618 75.6949C208.431 76.6847 208.03 77.5943 207.549 78.4504C207.015 79.4134 206.454 80.323 205.892 81.2593C205.812 81.4198 205.572 81.7676 205.492 81.7676Z"
        fill="#8963C6"
      />
      <path
        d="M240.66 14.4596C240.58 14.4596 240.5 13.9246 240.5 13.7106C240.526 11.6774 240.473 9.85826 240.874 8.01237C241.061 7.12955 241.702 6.4875 242.477 6.35374C243.386 6.16648 244.428 6.46075 245.016 7.26331C245.47 7.87861 245.55 8.62766 245.203 9.34997C244.776 10.2595 244.161 11.0353 243.493 11.7577C242.745 12.5602 241.943 13.3093 241.168 14.0583C241.061 14.1653 240.767 14.4596 240.66 14.4596Z"
        fill="#91E7F2"
      />
      <path
        d="M221.499 12.7742C221.419 12.7742 221.205 12.2927 221.178 12.0787C220.697 10.099 220.19 8.36011 220.109 6.48747C220.083 5.5779 220.51 4.80209 221.232 4.48107C222.06 4.05303 223.156 4.10654 223.904 4.72184C224.519 5.20337 224.759 5.89893 224.626 6.70149C224.439 7.69131 224.038 8.60088 223.557 9.45695C223.022 10.42 222.461 11.3296 221.9 12.2659C221.82 12.4264 221.606 12.7742 221.499 12.7742Z"
        fill="#B494F2"
      />
      <path
        d="M209.607 50.3877C209.527 50.3877 209.313 49.9062 209.286 49.6922C208.805 47.7125 208.298 45.9736 208.217 44.101C208.191 43.1914 208.618 42.4156 209.34 42.0946C210.168 41.6666 211.264 41.7201 212.012 42.3354C212.627 42.8169 212.867 43.5125 212.734 44.315C212.547 45.3048 212.146 46.2144 211.665 47.0705C211.13 48.0336 210.569 48.9431 210.008 49.8794C209.928 50.0132 209.714 50.3877 209.607 50.3877Z"
        fill="#6A449D"
      />
      <path
        d="M211.905 31.1529C211.825 31.1529 211.611 30.6713 211.585 30.4573C211.104 28.4777 210.596 26.7388 210.516 24.8661C210.489 23.9566 210.917 23.1808 211.638 22.8597C212.467 22.4317 213.562 22.4852 214.311 23.1005C214.925 23.582 215.166 24.2776 215.032 25.0801C214.845 26.07 214.444 26.9795 213.963 27.8356C213.429 28.7987 212.868 29.7083 212.306 30.6446C212.226 30.8051 211.986 31.1529 211.905 31.1529Z"
        fill="#8963C6"
      />
      <path
        d="M195.47 84.6302C195.337 84.657 195.043 83.9614 194.963 83.6671C194.161 80.8582 193.359 78.397 193.145 75.7218C193.038 74.4109 193.653 73.3141 194.642 72.8058C195.791 72.1638 197.368 72.1638 198.49 73.0198C199.372 73.6886 199.773 74.6517 199.612 75.802C199.399 77.2199 198.864 78.5307 198.25 79.8148C197.555 81.2059 196.78 82.5703 196.031 83.9347C195.898 84.0952 195.604 84.6035 195.47 84.6302Z"
        fill="#91E7F2"
      />
      <path
        d="M293.894 187.679C293.867 187.599 294.321 187.305 294.508 187.224C296.379 186.395 297.982 185.592 299.826 185.191C300.708 185.004 301.537 185.298 301.991 185.967C302.552 186.716 302.686 187.786 302.231 188.642C301.857 189.338 301.216 189.686 300.414 189.686C299.425 189.686 298.463 189.445 297.501 189.124C296.459 188.776 295.444 188.375 294.428 187.973C294.321 187.947 293.92 187.786 293.894 187.679Z"
        fill="#B494F2"
      />
    </svg>
  );
}

export default SvgAdultSmallMaskBg;
